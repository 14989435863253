import React from "react"
import { Button, Container } from "reactstrap"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import EcosuiteForm, { FormError } from "@common/form/EcosuiteForm"
import Logger from "@common/Logger"

import UserAdminService from "../UserAdminService"
import UserGroups from "../UserGroups"
import UserSchema from "./UserSchema"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
export default class CreateUser extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.state = {
      formData: {},
      error: null,
      newUser: null,
    }
    this.createUser = this.createUser.bind(this)
    this.cancel = this.cancel.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    UserAdminService.getUserSchema(this.props.projects).then((schema) => {
      this.setStateIfMounted({ schema: schema })
    })
  }

  createUser(form) {
    this.setState({ error: undefined, formData: form.formData, loading: true })
    UserAdminService.createUser(form)
      .then((response) => {
        const userType = this.props.userTypes[response.user.attributes?.userType]
        this.setStateIfMounted((prev) => {
          return { ...prev, loading: false, newUser: response.user, typeofNewUser: userType.name }
        })
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, loading: false })
      })
  }

  cancel() {
    this.props.selectUser()
  }

  renderContent() {
    if (this.state.loading) {
      return <Loading />
    } else if (this.state.schema) {
      return (
        <Container fluid={true} className="Admin-content">
          <div className="ecogy-form admin-content-area">
            {this.state.newUser ? (
              <>
                <Button
                  color="primary"
                  onClick={() => {
                    this.setState({ loading: true })
                    this.props.reloadUsers()
                  }}
                >
                  <Icon icon={"arrow_back"} />
                  {`\t${t("settings.labels.back_to_user_list")}`}
                </Button>
                <UserGroups user={this.state.newUser} groups={this.props.groups} typeofNewUser={this.state.typeofNewUser} />
              </>
            ) : (
              <EcosuiteForm
                schema={UserSchema.getSchema(this.state.schema, this.props.projects, this.props.portfolios, this.state.formData)}
                uiSchema={UserSchema.getUiSchema()}
                formData={this.state.formData}
                onSubmit={this.createUser}
                onChange={this.formDataChanged}
              >
                {this.renderMessages()}
                <div>
                  <Button color="primary" type="submit">
                    {t("buttons.submit")}
                  </Button>
                  <Button color="danger" onClick={this.cancel}>
                    {t("buttons.cancel")}
                  </Button>
                </div>
              </EcosuiteForm>
            )}
          </div>
        </Container>
      )
    } else {
      return <Loading />
    }
  }

  renderMessages() {
    return (
      <FormError
        error={this.state.error}
        toggle={() => {
          this.setStateIfMounted({ error: null })
        }}
      />
    )
  }
}
