import * as React from "react"
import { Control, Controller, FieldErrors, UseFormReset, UseFormSetValue } from "react-hook-form"
import CreatableSelect from "react-select/creatable"
import { Label } from "reactstrap"
import { PasswordField, TextField } from "./AmplifyTextFields"
import { Organization } from "../services/organization"
import LogoUpload from "./LogoUpload"
import { CheckboxField , SelectField } from "@aws-amplify/ui-react"
import "./dark.scss"
import i18n from "src/i18n"
import WithToolTip from "@common/display/ToolTip"

const { t } = i18n
const translate = (k: string) => t(k) || ""

interface Props {
  control: Control<Omit<Organization, "id">, any>
  errors: FieldErrors<Omit<Organization, "id">>
  reset: UseFormReset<any>
  setValue: UseFormSetValue<Omit<Organization, "id">>
  organization: Organization | undefined
}

export default function OrganizationFormFields(props: Props) {
  const { control, errors, organization, setValue } = props

  return (
    <>
      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            placeholder="Organization Name"
            label="Organization Name*"
            errorMessage="This field is required"
            inputStyles={{
              backgroundColor: "#FFFFFF",
            }}
            {...field}
            hasError={!!errors.name}
          />
        )}
      />

      <Controller
        name="slug"
        control={control}
        render={({ field }) => (
          <TextField
            placeholder="Domain"
            label="Unique identifier (Slug)*"
            {...field}
            errorMessage="This field is required"
            hasError={!!errors.slug}
          />
        )}
      />

      <Controller
        name="domain"
        control={control}
        render={({ field }) => <TextField placeholder="Domain" label="Domain" {...field} />}
      />

      

      <h3 style={{ marginTop: "16px" }}>SolarNetwork Credentials</h3>
      <Controller
        name="solarNetworkCredentials.host"
        control={control}
        render={({ field }) => <TextField placeholder="Host" label="Host" {...field} />}
      />
      <Controller
        name="solarNetworkCredentials.sshHost"
        control={control}
        render={({ field }) => <TextField placeholder="SSH Host" label="SSH Host" {...field} />}
      />
      <Controller
        name="solarNetworkCredentials.wssHost"
        control={control}
        render={({ field }) => <TextField placeholder="WSS Host" label="WSS Host" {...field} />}
      />
      <Controller
        name="solarNetworkCredentials.secret"
        control={control}
        render={({ field }) => <PasswordField placeholder="Secret" label="Secret" {...field} />}
      />
      <Controller
        name="solarNetworkCredentials.token"
        control={control}
        render={({ field }) => <TextField placeholder="Token" label="Token" {...field} />}
      />

      <h3 style={{ marginTop: "16px" }}>Settings</h3>


      <Label style={{ fontSize: "16px", marginBottom: "0px" }}>Project Milestone Email Recipients</Label>

      <Controller
        control={control}
        name="settings.projectMilestoneRecipients"
        render={({ field: { onChange, onBlur, value, name, ref } }) => {
          return (
            <CreatableSelect
            options={[]}
            onChange={onChange}
            isMulti={true}
            onBlur={onBlur}
            value={value}
            name={name}
            ref={ref}
            styles={{ control: (baseStyles) => ({ ...baseStyles, marginBottom: "8px" }) }}
            />
          )
        }}
      />
      <Label style={{ fontSize: "16px", marginBottom: "0px" }}>Organization Logo (~ 3:1 aspect ratio)</Label>
      <LogoUpload
        currentLogoUrl={organization?.settings?.logoUrl}
        onBlur={async (file) => {
          if (file) {
            const dataUrl: string = await new Promise((resolve) => {
              const reader = new FileReader()
              reader.onload = () => resolve(reader.result as string)
              reader.readAsDataURL(file)
            })
            setValue("settings.logoUrl", dataUrl, { shouldDirty: true })
          }
        }}
      />
    </>
  )
}

export function OrganizationDataSharingFeilds(props: Props){
  const { control } = props
  
  return (
    <>
      <Controller
        name="settings.isPublic"
        control={control}
        render={({ field: { onChange, value } }) => (
          <WithToolTip tooltip={"Allows basic company info to be shown on our public companies page"}> 
            <CheckboxField label="Public organization" checked={value} onChange={onChange} name="isPublic" value="yes" />
          </WithToolTip>
        )}
      />
      <Controller
        name="dataSharing.enableSharing"
        control={control}
        render={({ field }) => <> 
        <SelectField label="Data sharing" {...field}>
          <option value={"Do not share my data"}>Do not share my data</option>          
          <option value={"Share my data but it must be anonymized"}>Share my data but it must be anonymized</option>
          <option value={"Share my data fully"}>Share my data fully</option>
        </SelectField>
        </>}
      />
    </>
  )
}

export function OrganizationAdvancedFeilds(props: Props){
  const { control, errors,       } = props
  
  return (
    <>
      <h3 style={{ marginTop: "16px" }}>Google Integration</h3>
      <Controller
        name="googleIntegration.serviceRequestTemplateId"
        control={control}
        render={({ field }) => (
          <TextField placeholder="Service Request Template ID" label="Service Request Template ID" {...field} />
        )}
      />
      <Controller
        name="googleIntegration.projectMilestoneDatesId"
        control={control}
        render={({ field }) => (
          <TextField placeholder="Project Milestone Dates ID" label="Project Milestone Dates ID" {...field} />
        )}
      />
      <Controller
        name="googleIntegration.checklistTemplateId"
        control={control}
        render={({ field }) => (
          <TextField placeholder="Checklist Template ID" label="Checklist Template ID" {...field} />
        )}
      />
      <Controller
        name="googleIntegration.googleDrivePrivateKey"
        control={control}
        render={({ field }) => (
          <PasswordField placeholder="Google Drive Private Key" label="Google Drive Private Key" {...field} />
        )}
      />
      <Controller
        name="googleIntegration.googleDriveClientEmail"
        control={control}
        render={({ field }) => (
          <TextField placeholder="Google Drive Client Email" label="Google Drive Client Email" {...field} />
        )}
      />

      <h3 style={{ marginTop: "16px" }}>Softledger</h3>

      

      <Controller
        name="softLedger.clientId"
        control={control}
        render={({ field }) => (
          <PasswordField
          placeholder={translate("settings.orgPreferences.softledger_client_id")}
          label={translate("settings.orgPreferences.softledger_client_id")}
          {...field}
          />
        )}
      />

      <Controller
        name="softLedger.clientSecret"
        control={control}
        render={({ field }) => (
          <PasswordField
          placeholder={translate("settings.orgPreferences.softledger_client_secret")}
          label={translate("settings.orgPreferences.softledger_client_secret")}
          {...field}
          />
        )}
      />

      <Controller
        name="softLedger.tenantUUID"
        control={control}
        render={({ field }) => (
          <PasswordField
          placeholder={translate("settings.orgPreferences.softledger_tenant_uuid")}
          label={translate("settings.orgPreferences.softledger_tenant_uuid")}
          {...field}
          />
        )}
      />
      
      <h3 style={{ marginTop: "16px" }}>Other</h3>
        <Controller
          name="settings.salesforceUrl"
          control={control}
          rules={{
            pattern: /^(https?:\/\/)([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
          }}
          render={({ field }) => (
          <TextField
            placeholder="Salesforce URL"
            label="Salesforce URL"
            errorMessage="Please enter a valid url"
            hasError={!!errors.settings?.salesforceUrl}
            {...field}
          />
        )}
      />
      <Controller
        name="settings.qgisUrl"
        control={control}
        render={({ field }) => <TextField placeholder="QGIS URL" label="QGIS URL" {...field} />}
      />
      <Controller
        name="settings.slackXbobToken"
        control={control}
        render={({ field }) => <TextField placeholder="Slack Xbob Token" label="Slack Xbob Token" {...field} />}
      />
    </>
  )
}
