import React from "react"
import { Button, ButtonGroup, Label } from "reactstrap"
import EcosuiteComponent from "@common/EcosuiteComponent"
import i18n from "src/i18n"

const { t } = i18n
class AddressLookup extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.getGeocoder = this.getGeocoder.bind(this)
    this.clickHandler = this.clickHandler.bind(this)
    this.getLatLong = this.getLatLong.bind(this)
  }

  getLatLong(_address) {
    this.getGeocoder().geocode({ address: _address }, (results) => {
      if (results && results.length > 0) {
        const latLong = results[0].geometry.location
        const components = results[0].address_components
        const town = components.find((component) => component.types.includes("locality"))
        const state = components.find((component) => component.types.includes("administrative_area_level_1"))

        this.setStateIfMounted({ error: null })
        this.props.gotAddress({
          address: results[0].formatted_address,
          town: town ? town.long_name : null,
          state: state ? state.long_name : null,
          latitude: latLong.lat(),
          longitude: latLong.lng(),
        })
      } else {
        this.setStateIfMounted({ error: `${t("errors.Address not found")}` })
        this.props.gotAddress(null)
      }
    })
  }

  getGeocoder() {
    if (this.geocoder) {
      return this.geocoder
    }
    if (window.google && window.google.maps) {
      this.geocoder = new window.google.maps.Geocoder()
    }
    return this.geocoder
  }

  clickHandler() {
    this.getLatLong(this.props.getAddress())
  }

  renderContent() {
    const formData = this.props.formData

    return (
      <div>
        <ButtonGroup>
          <Button color="primary" onClick={this.clickHandler} disabled={this.props.disabled}>
            {t("buttons.look_up_address")}
          </Button>
          <Button
            color="primary"
            onClick={() => {
              window.open(`https://www.google.com/maps/@${formData?.lat},${formData?.long},15z`)
            }}
            disabled={this.props.disabled}
          >
            View Map
          </Button>
        </ButtonGroup>
        <Label>{this.state.error}</Label>
      </div>
    )
  }
}

export default AddressLookup
