import React, { Component } from "react"

import ProjectUtils from "@common/utils/ProjectUtils"
import Logger from "@common/Logger"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"

import EnergyUtils from "@dashboard/energy/EnergyUtils"

import { RecordDocuments } from "./documents/RecordDocuments"
import i18n from "src/i18n"

var formatCurrency = function (amount, currency) {
  if (!currency) {
    currency = "USD"
  }
  if (amount) {
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
  }
}

const { t } = i18n

class RecordInfo extends EcosuiteComponent {
  render() {
    return (
      <div className="side-panel-content">
        <h3>{t("headings.links")}</h3>
        {this.props.record.paymentType === "tariff" && this.props.record.terms && this.props.record.terms.provider === "openei" ? (
          <div>
            <a href="https://openei.org/apps/IURDB/" target="_blank" rel="noreferrer noopener">
              {t("links.open_tariff")}
            </a>
          </div>
        ) : null}
        <RecordDocuments {...this.props} />
        {this.props.project ? (
          <React.Fragment>
            <h3>{t("headings.payments")}</h3>
            <RecordPayments record={this.props.record} project={this.props.project} recordPayments={this.props.recordPayments} />
          </React.Fragment>
        ) : (
          <Loading />
        )}
      </div>
    )
  }
}

class RecordPayments extends Component {
  render() {
    if (this.props.recordPayments === "Loading") {
      return t("loadingMsg.loading_record_payment")
    }

    if (this.props.recordPayments && this.props.recordPayments.paymentDetails.length) {
      return (
        <div>
          {this.props.recordPayments.paymentDetails.map((paymentDetails, idx) => {
            return paymentDetails.payment.paymentType === "tariff" ? (
              <TariffDetails key={idx} record={this.props.record} payment={paymentDetails.payment} project={this.props.project} paymentDetails={paymentDetails} />
            ) : (
              <PaymentDetails key={idx} record={this.props.record} payment={paymentDetails.payment} project={this.props.project} paymentDetails={paymentDetails} />
            )
          })}
        </div>
      )
    } else {
      Logger.debug("No record payment details available for record: " + this.props.record.id)
      return t("messages.no_record_payment_details")
    }
  }
}

class PaymentDetails extends Component {
  render() {
    let boundary = ProjectUtils.projectMoment(this.props.project).startOf("month") //.add(1, "month")

    return (
      <div className="record-payment">
        <h4>{this.props.payment.subAccount}</h4>
        <span style={{ textTransform: "capitalize" }}>{this.props.payment.paymentType} Payments</span>
        <table className="payments">
          <thead>
            <tr>
              <th>{t("table_headings.date")}</th>
              <th>{t("table_headings.total")}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.paymentDetails.cashFlows
              ? this.props.paymentDetails.cashFlows.map((paymentDetail, idx) => {
                  return (
                    <tr key={idx} className={ProjectUtils.projectMoment(this.props.project, paymentDetail.date).isBefore(boundary) ? "past" : null}>
                      <td>{ProjectUtils.projectMoment(this.props.project, paymentDetail.date).format("MMM YYYY")}</td>
                      <td>{formatCurrency(paymentDetail.monthlyTotal)}</td>
                    </tr>
                  )
                })
              : null}
          </tbody>
        </table>
      </div>
    )
  }
}

class TariffDetails extends Component {
  render() {
    const expectedDates = this.props.paymentDetails.dates.filter((date) => ProjectUtils.projectMoment(this.props.project, date).isBefore(ProjectUtils.projectMoment(this.props.project)))
    return (
      <div className="record-payment">
        <h4>{this.props.payment.subAccount}</h4>
        <span>{t("labels.tariff")}</span>
        <div>
          <div>
            {t("labels.provider")}: {this.props.payment.provider}
          </div>
          <div>
            {t("labels.utility")}: {this.props.payment.utility}
          </div>

          {this.props.payment.provider === "openei" ? (
            <React.Fragment>
              <div>
                {t("labels.rate")}: {this.props.payment.rate}
              </div>
              <div>
                {t("labels.service")}: {this.props.payment.service}
              </div>
            </React.Fragment>
          ) : null}

          {this.props.payment.provider === "ecogyei" ? (
            <React.Fragment>
              <div>
                {t("labels.name")}: {this.props.payment.name}
              </div>
              <div>
                {t("labels.description")}: {this.props.payment.description}
              </div>
            </React.Fragment>
          ) : null}
        </div>
        <h5>{t("headings.rates")}</h5>
        {this.props.paymentDetails.rates.map((rate) => {
          return (
            <div key={rate.uri ? rate.uri : rate.name}>
              <div>
                {t("labels.start")}: {ProjectUtils.projectMoment(this.props.project, rate.start).format("LL")}
              </div>
              {rate.uri ? (
                <div>
                  URL:{" "}
                  <a href={rate.uri} target="_blank" rel="noopener noreferrer">
                    {rate.uri}
                  </a>
                </div>
              ) : null}
              <div>
                {t("labels.fixed_changes")}: {rate.fixedchargefirstmeter} {rate.fixedchargeunits}
              </div>
            </div>
          )
        })}
        <br></br>
        <h5>{t("headings.expected_payments")}</h5>
        <p>{t("notes.expected_payments")}</p>
        {this.props.paymentDetails.expected ? <TariffPaymentsTable dates={expectedDates} payments={this.props.paymentDetails.expected} /> : "Expected payments not available"}

        <h5>{t("headings.forecast_payments")}</h5>
        <p>{t("notes.forecast_payments")}</p>
        <TariffPaymentsTable dates={this.props.paymentDetails.dates} payments={this.props.paymentDetails.forecast} />
      </div>
    )
  }
}

class TariffPaymentsTable extends EcosuiteComponent {
  renderContent() {
    const payments = this.props.payments
    const boundary = ProjectUtils.projectMoment(this.props.project).startOf("month").add(1, "month")

    return (
      <table className="payments">
        <thead>
          <tr>
            <th>{t("table_headings.date")}</th>
            <th>kWh</th>
            <th>kVA</th>
            <th>kW</th>
            <th>{t("table_headings.metered")}</th>
            <th>{t("table_headings.demand")}</th>
            <th>{t("table_headings.fixed")}</th>
            <th>{t("table_headings.total")}</th>
          </tr>
        </thead>
        <tbody>
          {this.props.dates.map((date, idx) => {
            date = ProjectUtils.projectMoment(this.props.project, date)
            let total = payments.monthlyTotalCosts[idx] ? payments.monthlyTotalCosts[idx] : 0
            let fixed = payments.monthlyFixedCosts[idx] ? payments.monthlyFixedCosts[idx] : 0
            let metered = payments.monthlyConsumptionCosts[idx] ? payments.monthlyConsumptionCosts[idx] : 0
            let demandCharge = payments.monthlyDemandChargeCosts[idx] ? payments.monthlyDemandChargeCosts[idx] : 0
            let kiloWattHours = payments.kiloWattHours[idx]
            let maxApparentPower = payments.maxApparentPower[idx]
            let maxKiloWatts = payments.maxKiloWatts[idx]

            return (
              <tr key={idx} className={date.isBefore(boundary) ? "past" : null}>
                <td>{date.format("MMM YYYY")}</td>
                <td>{isNaN(kiloWattHours) ? "-" : EnergyUtils.formatNumber(kiloWattHours)}</td>
                <td>{isNaN(maxApparentPower) ? "-" : EnergyUtils.formatNumber(maxApparentPower)}</td>
                <td>{isNaN(maxKiloWatts) ? "-" : EnergyUtils.formatNumber(maxKiloWatts)}</td>
                <td>{isNaN(metered) ? "-" : formatCurrency(metered)}</td>
                <td>{isNaN(demandCharge) ? "-" : formatCurrency(demandCharge)}</td>
                <td>{isNaN(fixed) ? "-" : formatCurrency(fixed)}</td>
                <td>{isNaN(total) ? "-" : formatCurrency(total)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }
}

export default RecordInfo
