import React from "react"
import moment from "moment"
import { Button } from "reactstrap"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import Logger from "@common/Logger"

import UserAdminService from "../UserAdminService"

import "./UserList.css"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const pLimit = require("p-limit")

const { t } = i18n
const userTablePadding = "20px"
const limit = pLimit(100)

export default class UserList extends EcosuiteComponent {
  componentDidMount() {
    super.componentDidMount()

    // The parent component can either provide a list of users, or we can fetch them ourselves
    if (this.props.users) {
      this.applyUsers(this.props.users)
    } else {
      this.loadUsers()
    }

    // User types are only needed if we're rendering the extended view
    if (this.props.extended) {
      this.loadUserTypes()
    }
  }

  loadUsers() {
    this.setStateIfMounted({
      users: null,
    })

    UserAdminService.getUsers()
      .then(async (response) => {
        const users = response.users.sort((a, b) => {
          return a.displayName.localeCompare(b.displayName)
        })

        let promises = []
        users.forEach(async (user) => {
          promises.push(
            limit(() => {
              return UserAdminService.getLastLogin(user.id)
            }),
          )
        })
        let lastLogins = await Promise.all(promises)
        let userMap = {}
        let i = 0
        users.forEach((user) => (userMap[user.id] = i++))

        lastLogins = lastLogins.filter(function (el) {
          return el != null
        })
        lastLogins.forEach((login) => (users[userMap[login.Name]].lastLogin = login.Attributes))
        return this.applyUsers(users)
      })
      .then((id) => {
        if (id) {
          this.props.selectUser(id)
        }
      })
  }

  applyUsers(users) {
    const defaultUser = this.props.userId ? users.find((user) => user.id === this.props.userId) : null

    this.setState({
      users: users,
      selectedUser: defaultUser,
    })

    return defaultUser ? defaultUser.id : null
  }

  isRegistrationExpired(user) {
    return moment().isAfter(moment(user.updated).add(7, "days")) && user.status === "FORCE_CHANGE_PASSWORD"
  }

  loadUserTypes() {
    this.setState({ userTypes: {} })

    UserAdminService.getUserTypes()
      .then((response) => {
        this.applyUserTypes(response.userTypes)
      })
      .catch((error) => {
        Logger.error(error)
        this.setStateIfMounted({
          hasError: true,
          error: error,
        })
      })
  }

  applyUserTypes(types) {
    // Transform response into { userTypeId: userTypeName, }
    const userTypes = {}
    types.map((userType) => {
      userTypes[userType.id] = userType.name
    })

    this.setStateIfMounted({
      userTypes: userTypes,
    })
  }

  render() {
    if (this.state.users) {
      return (
        <div className="userlist-content">
          <table className="user-table">
            <thead>
              <tr>
                <th className="user-name">{t("table_headings.user")}</th>
                {this.props.extended && !this.state.selectedUser ? (
                  <React.Fragment>
                    <th>{t("table_headings.first_name")}</th>
                    <th>{t("table_headings.last_name")}</th>
                    <th>{t("table_headings.type")}</th>
                    <th>{t("table_headings.status")}</th>
                    <th>{t("table_headings.last_login")}</th>
                    <th>{t("table_headings.created")}</th>
                    <th>{t("table_headings.end_date")}</th>
                    <th>{t("table_headings.updated")}</th>
                    <th>{t("table_headings.timezone")}</th>
                    <th>{t("table_headings.restricted")}</th>
                  </React.Fragment>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {(this.props.searchTermResults ?? this.state.users).map((user) => {
                const isActive = this.state.selectedUser && this.state.selectedUser.id === user.id
                const expired = this.isRegistrationExpired(user)
                return (
                  <tr key={user.id}>
                    <td
                      title={`User: ${user.id}`}
                      className={isActive ? "active" : ""}
                      style={{
                        paddingRight: this.state.selectedUser ? "0px" : userTablePadding,
                      }}
                      onClick={() => {
                        this.setState({
                          selectedUser: user,
                        })
                        this.props.selectUser(user.id)
                      }}
                    >
                      <Button className="user-button">{user.displayName}</Button>
                    </td>

                    {this.props.extended && !this.state.selectedUser ? (
                      <React.Fragment>
                        <td>{user.attributes.firstName}</td>
                        <td>{user.attributes.lastName}</td>
                        <td>
                          {user.attributes.userType
                            ? this.state.userTypes[user.attributes.userType] || (
                                // Render this warning if the user has an assigned userTypeId
                                // but the assigned id does not have an associated name
                                <>
                                  <Icon icon="warning" />
                                  <span> {t("errors.no_longer_exists")} </span>
                                </>
                              )
                            : "-"}
                        </td>
                        <td className={expired ? "user-registration-expired" : ""}>
                          {expired ? `${t("labels.expired")}` : user.status}
                        </td>
                        <td>{user.lastLogin ? moment(user.lastLogin).format("ll") : null}</td>
                        <td>{moment(user.created).format("ll")}</td>
                        <td>
                          {user.attributes.access.endDate ? moment(user.attributes.access.endDate).format("ll") : null}
                        </td>
                        <td>{moment(user.updated).format("ll")}</td>
                        <td>{user.attributes.timezone}</td>
                        <td>{user.attributes.access.restrictProjects}</td>
                      </React.Fragment>
                    ) : null}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )
    } else {
      return <Loading />
    }
  }
}
