import React, { Component } from "react"

import ProjectMap, { ProjectMarker } from "@dashboard/ProjectMap"

import EcosuiteComponent from "@common/EcosuiteComponent"
import DateRangeUtils from "@common/utils/DateRangeUtils"
import moment from "moment"
import { Col, Row } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import { kebabCase } from "lodash"

export const GRAPH_COLORS = [
  "#4bb3d8",
  "#00C4A0",
  "#fec87e",
  "#EC6732",
  "#988dec",
  "#ff9b91",

  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#c12834",
  "#01BAEF",
  "#FFD65b",
  "#f7a98c",
  "#9DD9D2",
  "#F6511D",
  "#d84add",
  "#3891A6",
  "#9380c9",
  "#00FFC5",
  "#97CC04",
  "#98CE00",
]

export const LAYOUT_HEADER = "header"
export const LAYOUT_FULL = "full"
export const LAYOUT_SPLIT = "SPLIT"
export const LAYOUT_CONTENT_RIGHT = "content-right"
export const LAYOUT_CONTENT_BOTTOM = "content-bottom"
export const LAYOUT_CONTENT_BOTTOM_RIGHT = "content-bottom-right"

/**
 * Provides support for rendering a view, including common layouts.
 *
 * At a minumum views extending this base class want to override: renderMainView(). Based on the layout renderFooter() and renderSiderBar() may also be needed.
 * Optionally child classes can add a renderViewControls() method, to add a standardised controls section to the view.
 * The renderLayout() method can be extended to offer additional layout support and ultimately renderContent() can be used to change the rendering entirely.
 *
 * The MapView offers a common Map based view.
 */
export default class EcosuiteView extends EcosuiteComponent {
  constructor(props, name) {
    super(props)
    this.name = name
  }

  isReadOnly() {
    return !this.props.groups.includes("data-write")
  }

  isAssetManager() {
    return this.props.userType === "asset-manager"
  }

  getLayout() {
    return null // by default we render the full content view
  }

  renderContent() {
    if (this.renderViewControls) {
      return (
        <React.Fragment>
          <div className="content-with-controls">{this.renderLayout()}</div>
          {this.renderViewControls()}
        </React.Fragment>
      )
    }
    return this.renderLayout()
  }

  getLeftSplitWidth() {
    return 6
  }

  getRightSplitWidth() {
    return 12 - this.getLeftSplitWidth()
  }

  renderLayout() {
    if (this.getLayout() === LAYOUT_SPLIT) {
      return (
        <React.Fragment>
          <Row>{this.renderHeader()}</Row>
          <Row className="content content-full">
            <Col sm={this.getLeftSplitWidth()}>{this.renderLeftView()}</Col>
            <Col sm={this.getRightSplitWidth()}>{this.renderRightView()}</Col>
          </Row>
          <Row>{this.renderFooter()}</Row>
        </React.Fragment>
      )
    } else if (this.getLayout() === LAYOUT_CONTENT_BOTTOM) {
      return (
        <React.Fragment>
          <div className="content content-full">
            <div className="content-main">{this.renderMainView()}</div>
            <div className="content-footer">{this.renderFooter()}</div>
          </div>
        </React.Fragment>
      )
    } else if (this.getLayout() === LAYOUT_CONTENT_BOTTOM_RIGHT) {
      return (
        <React.Fragment>
          <div className="content">
            <div className="content-main">{this.renderMainView()}</div>
            <div className="content-footer">{this.renderFooter()}</div>
          </div>
          <div className="content-sidebar">{this.renderSiderBar()}</div>
        </React.Fragment>
      )
    } else if (this.getLayout() === LAYOUT_CONTENT_RIGHT) {
      return (
        <React.Fragment>
          <div className="content">
            <div className="content-main content-main-full">{this.renderMainView()}</div>
          </div>
          <div className="content-sidebar">{this.renderSiderBar()}</div>
        </React.Fragment>
      )
    } else if (this.getLayout() === LAYOUT_HEADER) {
      return (
        <React.Fragment>
          <Row>{this.renderHeader()}</Row>
          <Row className="content content-full">
            <Col className="content-with-title">{this.renderMainView()}</Col>
          </Row>
        </React.Fragment>
      )
    } else {
      // Default to a full content view
      return <div className="content content-full">{this.renderMainView()}</div>
    }
  }

  renderHeader() {
    return null
  }

  /**
   * The main method that should be implemented, renders the content in the primary view
   */
  renderMainView() {
    return null
  }

  renderLeftView() {
    return null
  }

  renderRightView() {
    return null
  }

  renderFooter() {
    return null
  }

  renderSiderBar() {
    return null
  }
}

/**
 * Provides support for rendering a Map specific view.
 */
export class MapView extends EcosuiteView {
  getMapMarkers() {
    return this.props.projects == null
      ? []
      : this.props.projects.map((project) => {
          return project.lat && project.long ? (
            <React.Fragment key={project.code}>
              <ProjectMarker project={project} />
            </React.Fragment>
          ) : null
        })
  }

  renderMainView() {
    return <div className="content-map">{this.renderMapView()}</div>
  }

  renderMapView() {
    return <ProjectMap markers={this.getMapMarkers()} />
  }
}

export class SideBarSection extends Component {
  render() {
    const toolTipId = `${kebabCase(this.props.title)}-tooltip`
    return (
      <>
        <div className={"sidebar-section " + (this.props.classNames ? this.props.classNames : "")} id={toolTipId}>
          <div className="sidebar-title-wrapper">
            <div className="sidebar-title">
              <p className="sidebar-subtitle">{this.props.subtitle}</p>
              {this.props.title}
            </div>
          </div>
          <div className="sidebar-content">{this.props.content}</div>
          {this.renderRange()}
        </div>
        {this.props.toolTip && (
          <UncontrolledTooltip placement="left" target={toolTipId}>
            {this.props.toolTip}
          </UncontrolledTooltip>
        )}
      </>
    )
  }

  renderRange() {
    if (this.props.range) {
      const dateFormat = DateRangeUtils.isShowTimeForRange(this.props.range) ? "lll" : "ll"
      // Energy date range ends are exclusive but we display as inclusive
      const end = moment(this.props.range.end).subtract(1, "millisecond")
      return <p className="sidebar-range">{`${this.props.range.start.format(dateFormat)} - ${end.format(dateFormat)}`}</p>
    }
  }
}
