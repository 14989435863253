import React from "react"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"

import Content from "./Content"
import SideBar from "./SideBar"

import "./Dashboard.css"

export default class Dashboard extends EcosuiteComponent {
  renderContent() {
    if (this.props.allProjects && this.props.moduleViews) {
      return (
        <div className="Dashboard">
          <SideBar
            refinements={this.props.refinements}
            selectProjectSortMethod={this.props.selectProjectSortMethod}
            autoCompletes={this.props.autoCompletes}
            selectedFilter={this.props.selectedFilter}
            selectedRefinements={this.props.selectedRefinements}
            disabledProjects={this.props.disabledProjects}
            searchTerm={this.props.searchTerm}
            portfolios={this.props.portfolios}
            portfolio={this.props.portfolio}
            allProjects={this.props.allProjects}
            projects={this.props.filteredProjects}
            selectedProject={this.props.selectedProject}
            groups={this.props.groups}
            moduleViews={this.props.moduleViews}
            restrictions={this.props.restrictions}
            loadTime={this.props.loadTime}
            actions={this.props.actions}
            version={this.props.version}
            portfoliosEnabled={this.props.portfoliosEnabled}
          />
          <Content
            autoCompletes={this.props.autoCompletes}
            portfolio={this.props.portfolio}
            allProjects={this.props.allProjects}
            filteredProjects={this.props.filteredProjects}
            selectedProjects={this.props.selectedProjects}
            project={this.props.selectedProject}
            groups={this.props.groups}
            loadTime={this.props.loadTime}
            actions={this.props.actions}
            moduleViews={this.props.moduleViews}
            restrictions={this.props.restrictions}
            userId={this.props.userId}
            username={this.props.username}
            portfoliosEnabled={this.props.portfoliosEnabled}
          />
        </div>
      )
    } else {
      return <Loading />
    }
  }
}
