import React, { useEffect, useState } from "react"

import { Loading } from "@common/EcosuiteComponent"
import FunnelViewTable from "./FunnelViewTable"
import FunnelViewCharts from "./FunnelViewCharts"

import type { FunnelViewData, StatusData } from "./FunnelView.d"
import type { ProjectTypeWithACDCSize, ProjectStatuses } from "@dashboard/process/ProcessModule.d"

import "./FunnelView.scss"
import i18n from "src/i18n"

/**
 * @param projects - note that open-api type `Project` does not have `acSize` and `dcSize` properties
 * @param projectStatuses - API schema defintion for each project status
 */
const { t } = i18n

export interface TogglesState {
  paused: boolean
  cancelled: boolean
  active: boolean
}

const FunnelView = ({ projects, projectStatuses }: { projects: ProjectTypeWithACDCSize[]; projectStatuses?: ProjectStatuses }): JSX.Element => {
  const [funnelViewData, setFunnelViewData] = useState<FunnelViewData | null>(null)

  // Only run this when projects change
  useEffect(() => {
    // Skip generating data if api definition is somehow undefined
    if (!projectStatuses) {
      return
    }

    const data: FunnelViewData = {
      // Create a map of empty arrays for each status code in config
      statusMap: projectStatuses.reduce((map: Record<string, StatusData>, status: string) => {
        map[status] = { status, projects: [], projectCount: 0, kWDC: 0 }
        return map
      }, {}),
      totalkWDC: 0,
    }

    // Populate the status map with project data
    projects.map((project) => {
      data.statusMap[project.status].projects.push(project)
      data.statusMap[project.status].kWDC += project.dcSize ?? 0
    })

    // Loop through config to update project count for each status,
    // cut off kWDC at 2 decimal points for each status and
    // calculate the totalkWDC for all status codes
    projectStatuses.map((status) => {
      data.statusMap[status].projectCount = data.statusMap[status].projects.length
      data.statusMap[status].kWDC = +data.statusMap[status].kWDC.toFixed(2)
      data.totalkWDC += data.statusMap[status].kWDC
    })

    setFunnelViewData(data)
  }, [JSON.stringify(projects), projectStatuses])

  const initialToggles = {
    paused: false,
    cancelled: false,
    active: false,
  }

  const [activeToggles, setActiveToggles] = React.useState<TogglesState>(() => {
    const storedToggles = localStorage.getItem("togglesState")
    return storedToggles ? JSON.parse(storedToggles) : initialToggles
  })

  // store toggle states in localstorage
  React.useEffect(() => {
    localStorage.setItem("togglesState", JSON.stringify(activeToggles))
  }, [activeToggles])

  const handleToggle = (toggleName: keyof TogglesState) => {
    setActiveToggles((prevToggles) => ({
      ...prevToggles,
      [toggleName]: !prevToggles[toggleName],
    }))
  }

  // Loading State
  if (!projectStatuses) return <Loading message={t("process.messages.acquiring_project_statuses")} />
  if (!funnelViewData) return <Loading message={t("process.messages.generating_data")} />

  const getTotalKWDC = (data: StatusData[]) => {
    return data.reduce((acc, cur) => acc + cur.kWDC, 0)
  }

  const filterFn = (data: StatusData[], activeToggles: TogglesState) => {
    if (!activeToggles.active && !activeToggles.paused && !activeToggles.cancelled) {
      return data
    }
    return data.map((datum) => {
      const updatedProjects = datum.projects.filter((p) => {
        const isPaused = p.paused
        const isCancelled = p.cancelled

        return (activeToggles.active && !isPaused && !isCancelled) || (activeToggles.paused && isPaused) || (activeToggles.cancelled && isCancelled)
      })
      return { ...datum, projects: updatedProjects, projectCount: updatedProjects.length, kWDC: updatedProjects.reduce((acc, cur) => acc + cur.dcSize, 0) }
    })
  }

  return (
    <div className="funnel-view">
      <FunnelViewTable
        statusMap={filterFn(Object.values(funnelViewData.statusMap), activeToggles)}
        totalkWDC={getTotalKWDC(filterFn(Object.values(funnelViewData.statusMap), activeToggles)).toFixed(2)}
        projectCount={projects.length}
        handleToggle={handleToggle}
        activeToggles={activeToggles}
      />
      <FunnelViewCharts statusMap={filterFn(Object.values(funnelViewData.statusMap), activeToggles)} />
    </div>
  )
}

export default FunnelView
