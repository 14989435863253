import React, { useState } from "react"
import { Button, FormText } from "reactstrap"
import { Label, Input, Form, FormGroup, Col, Container, Row, Modal, ModalBody, ModalFooter, ModalHeader, Progress } from "reactstrap"
import FileDropzone from "@common/input/files/FileDropzone"
import i18n from "src/i18n"
import RecordService from "./RecordService"
import { Typeahead } from "react-bootstrap-typeahead"
const { t } = i18n

const DistilleryPopover = ({ loadDistilleryDocuments, portfolios, projects }: { loadDistilleryDocuments: () => Promise<any[]>, portfolios: any[], projects: any[] }): JSX.Element | null => {
  const [files, setFiles] = useState<File[]>([])
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [portfolio, setPortfolio] = useState("")
  const [project, setProject] = useState("")
  const [progress, setProgress] = useState<number>(0)
  const [maxProgress, setMaxProgress] = useState<number>(0)

  const toggleModal = () => {
    setShowModal(!showModal)
  };

  const portfolioNames = portfolios.map((p: any) => p.code)
  const projectNames = projects.map((p: any) => p.code)

  return (
    <div>
      <Button
        title={"Distillery Upload"}
        onClick={() => {
          toggleModal();
        }}
        size="sm"
      >
        {"Distillery Upload"}
      </Button>

      <Modal isOpen={showModal} toggle={toggleModal} size={"xl"}>
        <ModalHeader toggle={toggleModal}>Distillery Upload</ModalHeader>
        <ModalBody>

          <Container id={"render-container"} style={{ height: "80%" }}>
            <Row id={"render-row"} style={{ height: "80%" }}>
              <Col id={"render-col"} style={{ display: "inline", height: "80%", fontSize: "18px" }}>

                <h1>Distillery</h1>

                <p>
                  {t("data.distillery.description")}
                </p>

                <p>
                  {t("data.distillery.description2")}
                </p>

                <hr />

                <Form>
                  <FormGroup row>
                    <Label for="portfolio" sm={3}>{t("labels.portfolio")}</Label>
                    <Col sm={9}>
                      <Typeahead
                        id="folderAutoComplete"
                        onChange={(selected: any) => {
                          setPortfolio(selected && selected.length ? (typeof selected[0] === "object" ? selected[0].label : selected[0]) : null)
                        }}
                        options={portfolioNames ?? []}
                        selected={portfolio ? [portfolio] : []}
                        allowNew={true}
                        disabled={false}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="project" sm={3}>{t("labels.project")}</Label>
                    <Col sm={9}>
                      <Typeahead
                        id="folderAutoComplete"
                        onChange={(selected: any) => {
                          setProject(selected && selected.length ? (typeof selected[0] === "object" ? selected[0].label : selected[0]) : null)
                        }}
                        options={projectNames ?? []}
                        selected={project ? [project] : []}
                        allowNew={true}
                        disabled={false}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="description" sm={3}>{t("labels.description")}</Label>
                    <Col sm={9}>
                      <Input
                        id="description"
                        name="description"
                        type="textarea"
                      />
                    </Col>
                    <FormText>
                      {t("data.distillery.portfolio description")}
                    </FormText>
                  </FormGroup>
                </Form>

              </Col>
              <Col id={"render-col"} style={{ display: "inline", height: "80%", fontSize: "18px" }}>

                <h1>Document Upload</h1>
                <FileDropzone onChange={(files) => setFiles(files)} />

              </Col>
            </Row>
          </Container>

          {maxProgress > 0 ? (
            <Progress max={maxProgress} value={progress} />
          ) : null}


        </ModalBody>
        <ModalFooter>

          <Button color={loading ? "secondary" : "primary"} onClick={async () => {
            setLoading(true)

            const uploadingFileNames = new Set(files.map((file) => file.name))

            // We need to wait for this many files to be uploaded, then we need to
            // start a distill job for each of them
            setMaxProgress(1 + uploadingFileNames.size * 2)
            setProgress(1) // always have some progress so it's more visible(?)

            await RecordService.distilleryUpload(project, files)

            setProgress(uploadingFileNames.size)

            let distillProgress = uploadingFileNames.size

            // Start distilling jobs for each of these files

            const documents = await loadDistilleryDocuments()
            const uploadedFiles = documents.filter((document) => uploadingFileNames.has(document.name))

            const jobs = await Promise.all(uploadedFiles.map(async (file: any) => {
              const s3Url = await RecordService.s3GetPresignedUrl(file.fileKey)
              const formData = await RecordService.createFormData(s3Url, file.name)
              const job = await RecordService.distill(formData)
              distillProgress++
              setProgress(distillProgress)
              return job
            }))

            setProgress(0)
            setMaxProgress(0)

            setLoading(false)
            setShowModal(false)
          }} >
            Upload
          </Button>{" "}
          <Button color="secondary" onClick={() => setShowModal(false)}>
            {t("buttons.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default DistilleryPopover
