import { useCurrentEffect } from "@common/hooks/useCurrentEffect"
import React, { useState } from "react"
import { listTariffs } from "@dashboard/data/tariffs/TariffService"
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead"
import i18n from "src/i18n"

const { t } = i18n
interface TariffAutoCompleteProps {
  selected?: string
  onSelect: (id: string) => void
}

/**
 * The tariff auto complete.
 * @param props - The props.
 * @constructor
 */
export const TariffAutoComplete = (props: TariffAutoCompleteProps) => {
  const { selected, onSelect } = props

  const [tariffs, setTariffs] = useState<Tariff[] | undefined>(undefined)

  useCurrentEffect((isCurrent) => {
    init(isCurrent)
  }, [])

  async function init(isCurrent: () => boolean) {
    await Promise.all([listTariffs()]).then(([tariffs]) => {
      if (isCurrent()) {
        setTariffs(tariffs)
      }
    })
  }

  /**
   * Build the media options.
   */
  const buildOptions = () => {
    return tariffs ? tariffs.map((tariff) => tariff.id) : []
  }

  const getName = (tariffs: Array<Tariff>, id: string) => {
    const tariff = tariffs.find((t) => t.id === id)
    return tariff?.name || tariff?.rate || tariff?.id || ""
  }

  return (
    <Typeahead
      options={buildOptions()}
      isLoading={tariffs === undefined}
      inputProps={{ required: true }}
      placeholder={`${t("data.dialogs.enter_tariff")}`}
      onChange={(tariff) => onSelect.call(this, tariff[0])}
      selected={selected ? [selected] : []}
      renderMenu={(results, menuProps) => {
        return (
          <Menu {...menuProps}>
            {results.map((r, i) => (
              <MenuItem option={r} position={i} key={r}>
                {getName(tariffs || [], r)}
              </MenuItem>
            ))}
          </Menu>
        )
      }}
    />
  )
}
