import React from "react"

import Moment from "moment"
import { extendMoment } from "moment-range"

import Settings from "@common/Settings"
import EcosuiteComponent, { Message, Warning } from "@common/EcosuiteComponent"
import DateRangeUtils from "@common/utils/DateRangeUtils"
import * as Tracker from "@common/utils/TrackingUtils"

import { EcosuiteModuleHeaderWithRouter } from "./EcosuiteModuleHeader"
import "./EcosuiteModule.css"
import i18n from "src/i18n"

const { t } = i18n
const moment = extendMoment(Moment)

export const PRODUCT_GENERATION = "generation",
  PRODUCT_CONSUMPTION = "consumption",
  PRODUCT_STORAGE = "storage"

/**
 * The EcosuiteModule is the key point into rendering content in the AMS Dashboard.
 * It provides controls allows users to select the content being viewed.
 */
class EcosuiteModule extends EcosuiteComponent {
  constructor(props, name) {
    super(props)
    this.name = name

    let products = this.getProducts()
    let selectedProductIds = this.getSelectedProductIds()
    selectedProductIds.filter((productId) => this.getProduct(productId)?.enabled) // Restrict to enabled products incase access has been revoked

    this.state = {
      products: products,
      selectedProductIds: selectedProductIds,
      moduleView: Settings.getSetting("moduleView", this.getDefaulModuleView(), this.name),
      projectView: Settings.getSetting("projectView", this.getDefaultProjectView(), this.name),
      rangeName: Settings.getSetting("rangeName", "last7Days"),
      start: moment(Settings.getSetting("start", moment().startOf("day"))),
      end: moment(Settings.getSetting("end", moment().endOf("day"))),
    }

    // EP-710 If there a single project is selected we make sure that the range only covers that project
    this.state.range = DateRangeUtils.getRange(
      this.state.rangeName,
      this.getCustomRange(),
      this.props.project ? [this.props.project] : this.props.projects,
    )

    this.toggleProduct = this.toggleProduct.bind(this)
    this.getExclusiveRange = this.getExclusiveRange.bind(this)

    this.selectModuleView = this.selectModuleView.bind(this)
    this.selectProjectView = this.selectProjectView.bind(this)
    this.selectRange = this.selectRange.bind(this)
  }

  componentDidMount() {
    this._isMounted = true

    if (this.props.project) {
      Tracker.emit({ name: `Module: ${this.name} Project: ${this.props.project.code} View: ${this.state.projectView}` })
    } else {
      Tracker.emit({ name: `Module: ${this.name} View: ${this.state.moduleView}` })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  isComponentMounted() {
    return this._isMounted
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate()

    if (this.props.project && !prevProps.project) {
      Tracker.emit({ name: `Module: ${this.name} Project: ${this.props.project.code} View: ${this.state.projectView}` })
    } else if (!this.props.project && prevProps.project) {
      Tracker.emit({ name: `Module: ${this.name} View: ${this.state.moduleView}` })
    }
  }

  isAssetManagementEnabled() {
    return this.props.userType === "asset-manager"
  }

  setStateIfMounted(state, callback) {
    if (this.isComponentMounted()) {
      super.setState(state, callback)
    }
  }

  getSelectedProductIds() {
    return Settings.getSetting("selectedProductIds", this.getDefaultProductIds())
  }

  getProducts() {
    if (this.props.products) {
      return this.props.products
    }
    let products = [
      {
        id: PRODUCT_GENERATION,
        code: "GEN",
        name: "Generation",
        enabled: this.props.groups && this.props.groups.includes(PRODUCT_GENERATION),
      },
      {
        id: PRODUCT_CONSUMPTION,
        code: "CON",
        name: "Consumption",
        enabled: this.props.groups && this.props.groups.includes(PRODUCT_CONSUMPTION),
      },
      {
        id: PRODUCT_STORAGE,
        code: "STO",
        name: "Storage",
        enabled: this.props.groups && this.props.groups.includes(PRODUCT_STORAGE),
      },
    ]
    return products
  }

  getProduct(productId) {
    return this.getProducts().find((product) => product.id === productId)
  }

  getDefaultProductIds() {
    return this.getProducts()
      .filter((product) => product.enabled)
      .map((product) => product.id)
  }

  toggleProduct(productId) {
    let selectedProductIds = this.state.selectedProductIds
    if (selectedProductIds.indexOf(productId) >= 0) {
      // Remove the product
      selectedProductIds = selectedProductIds.filter((selectedProductId) => selectedProductId !== productId)
    } else {
      // Add the product
      selectedProductIds = selectedProductIds.concat([productId])
    }

    Settings.setSetting("selectedProductIds", selectedProductIds) // Store the view for each EcosuiteModule
    this.setState({ selectedProductIds: selectedProductIds })
  }

  /**
   * Some views need to recalculate the "All Time" date range based on the project selection.
   * This is so that they can retrieve and show graphs over a more comparable date range.
   */
  recalculateAllTimeRange(prevProps) {
    if (this.state.rangeName === "allTime") {
      // EP-710 update the range to take into account the selected projects for "all time" as the selection can impact the date range
      if (prevProps.projects.length !== this.props.projects.length) {
        this.selectRange(this.state.rangeName)
      } else if (
        (prevProps.project && this.props.project && prevProps.project.code !== this.props.project.code) || // project changed
        (prevProps.project && !this.props.project) || // project deselected
        (!prevProps.project && this.props.project) // project selected
      ) {
        this.selectRange(this.state.rangeName)
      }
    }
  }

  isGenerationVisible() {
    return this.state.selectedProductIds.indexOf(PRODUCT_GENERATION) >= 0
  }

  isConsumptionVisible() {
    return this.state.selectedProductIds.indexOf(PRODUCT_CONSUMPTION) >= 0
  }

  isStorageVisible() {
    return this.state.selectedProductIds.indexOf(PRODUCT_STORAGE) >= 0
  }

  selectModuleView(viewId) {
    Settings.setSetting("moduleView", viewId, this.name) // Store the view for each EcosuiteModule
    this.setState({ moduleView: viewId })
    Tracker.emit({ name: `Module: ${this.name} View: ${viewId}` })

    let moduleView = this.getModuleView(viewId)
    if (moduleView && moduleView.childViewId) {
      Settings.setSetting("projectView", moduleView.childViewId, this.name) // Store the view for each EcosuiteModule
      // If the current view (module) has a preferred child view we use that when a project is selected
      this.setStateIfMounted({ projectView: moduleView.childViewId })
    }
  }

  selectProjectView(viewId) {
    Settings.setSetting("projectView", viewId, this.name) // Store the view for each EcosuiteModule
    this.setState({ projectView: viewId })
    Tracker.emit({ name: `Module: ${this.name} Project View: ${viewId}` })

    let projectView = this.getProjectView(viewId)
    if (projectView && projectView.parentViewId) {
      Settings.setSetting("moduleView", projectView.parentViewId, this.name) // Store the view for each EcosuiteModule
      // If the current view (module) has a preferred parent view we use that when a project is selected
      this.setStateIfMounted({ moduleView: projectView.parentViewId })
    }
  }

  selectRange(rangeName, customRange) {
    if (rangeName === "custom" && !customRange) {
      throw Error(`${t("errors.wrong_range_name")}`)
    }

    // EP-710 If there a single project is selected we make sure that the range only covers that project
    let range = DateRangeUtils.getRange(
      rangeName,
      customRange,
      this.props.project ? [this.props.project] : this.props.projects,
    )

    this.setState({
      range: range,
      rangeName: rangeName,
      start: customRange ? customRange.start.toDate() : null,
      end: customRange ? customRange.end.toDate() : null,
    })

    Settings.setSetting("rangeName", rangeName) // Share the range across all EcosuiteModules
    Settings.setSetting("start", customRange ? customRange.start : null)
    Settings.setSetting("end", customRange ? customRange.end : null)

    return this.getExclusiveRange(range)
  }

  getCustomRange() {
    if (this.state.start && this.state.end) {
      return moment.range(this.state.start, this.state.end)
    } else {
      // return null
      // FIXME There's a really weird bug the calling selectRange with 'null' for the customRange outsite the dropdown menu causes the browser to freeze.
      // This seems to cause an invalid date to be passed in the and the react-daterange-picker to go into an apparent inifinte loop trying to calculate the start date.
      // Given that the customRange is 'null' both for calls outsite the dropdown and inside it, I haven't been able to determine the exact cause, i.e. why it fails in one call but not the other.
      // Setting the range to contain actual dates rather than returning null seems to avoid the issue.
      return moment.range(
        moment(Settings.getSetting("start", moment().startOf("day"))),
        moment(Settings.getSetting("end", moment().endOf("day"))),
      )
    }
  }

  getExclusiveRange(range) {
    if (!range) {
      range = this.state.range
    }
    return DateRangeUtils.getExclusiveRange(range, this.state.dateRangeIncludesTime)
  }

  isRangeCurrent(range) {
    // EP-529 When the request returns, only load if the range still matches the latest provided, ignore response if it doesn't
    return range.isSame(this.getExclusiveRange())
  }

  renderContent() {
    return (
      <div className="Module">
        <div className="module-header">{this.renderHeader()}</div>
        <div className="module-content">{this.renderView()}</div>
      </div>
    )
  }

  renderHeader() {
    return (
      <EcosuiteModuleHeaderWithRouter
        restrictions={this.props.restrictions}
        groups={this.props.groups}
        // Make the products available for selection
        products={this.state.products}
        selectedProductIds={this.state.selectedProductIds}
        // Make the module views available for selection
        moduleViews={this.getModuleViews()}
        moduleView={this.getSelectedModuleView()}
        projectViews={this.getProjectViews()}
        projectView={this.getSelectedProjectView()}
        // Make the date range available for selection
        rangeName={this.state.rangeName}
        customRange={this.getCustomRange()}
        showTime={this.state.dateRangeIncludesTime}
        // Make the selected project and projects available
        portfolio={this.props.portfolio}
        project={this.props.project}
        projects={this.props.projects}
        // Make the actions available
        actions={{
          selectModuleView: this.selectModuleView,
          selectProjectView: this.selectProjectView,
          selectRange: this.selectRange,
          selectProject: this.props.actions.selectProject,
          toggleProduct: this.toggleProduct,
        }}
      />
    )
  }

  /**
   * Should return the default view to be presented to the user for this module
   */
  getDefaulModuleView() {
    if (this.getModuleViews().length > 0) {
      return this.getModuleViews()[0].id
    }
    return null
  }

  /**
   * Should return the default view to be presented to the user for this module
   */
  getDefaultProjectView() {
    if (this.getProjectViews().length > 0) {
      return this.getProjectViews()[0].id
    }
    return null
  }

  getModuleView(viewId) {
    return this.getModuleViews().find((view) => view.id === viewId)
  }

  getProjectView(viewId) {
    return this.getProjectViews().find((view) => view.id === viewId)
  }

  getSelectedModuleView() {
    return this.getModuleView(this.state.moduleView)
  }

  getSelectedProjectView() {
    return this.getProjectView(this.state.projectView)
  }

  getProjectViews() {
    return this.props.moduleViews ? this.props.moduleViews.project.views : []
  }

  getModuleViews() {
    return this.props.moduleViews ? this.props.moduleViews.portfolio.views : []
  }

  /**
   * Should render a view based on the selected options
   */
  renderView() {
    if (this.props.project) {
      if (this.getProjectViews().length) {
        return this.renderProjectView()
      } else {
        return <Warning message={`${t("warnings.no_access_to_project")}`} />
      }
    } else {
      if (this.getModuleViews().length) {
        const moduleView = this.getSelectedModuleView()
        if (moduleView) {
          const projectLimit = moduleView.projectLimit ? moduleView.projectLimit : 50 // EP-3163 Default to 50 projects if a limit hasn't neem explicitly set
          if (projectLimit > 0 && this.props.projects.length > projectLimit) {
            return (
              <Message
                message={`The ${moduleView.name} view only supports viewing ${projectLimit} projects at a time, please either filter down the number of selected projects or select the project you would like to view`}
              />
            )
          }
        }
        return this.renderModuleView()
      } else {
        return <Warning message={`${t("warnings.no_access_to_portfolio")}`} />
      }
    }
  }

  renderModuleView() {
    return null
  }

  renderProjectView() {
    return null
  }
}

export default EcosuiteModule
