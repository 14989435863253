import React from "react"
import { create, all } from "mathjs"
import { ContextMenuTrigger } from "react-contextmenu-v2"

import EnergyUtils from "@dashboard/energy/EnergyUtils"
import Utils from "@common/utils/Utils"

const math = create(all)

const ProjectsTableRow: React.FC<{
  // Prop Types
  // We need to define some of these types higher up the component hierachy (preferably when we first acquire their values)
  // Doing so will tidy up this component's type definitions
  project: {
    code: string
    name: string
    acSize?: number
    dcSize?: number
    peakPower?: number
  }
  projectStatus?: string
  projectReading?: {
    consumption: number
    generation: number
    storage: number
  }
  projectEnergyData: Record<string, number | undefined>
  expectedGenerationContent: string | HTMLElement
  isIrradianceComplete: boolean
  showGeneration: boolean
  showConsumption: boolean
  showStorage: boolean
  specificYield: number
}> = ({
  // Props
  project,
  projectStatus,
  projectReading,
  projectEnergyData,
  expectedGenerationContent,
  isIrradianceComplete,
  showGeneration,
  showConsumption,
  showStorage,
  specificYield,
}) => {
  // To prevent collapse
  // prettier-ignore
  const {
    predictedConsumption,
    expectedGeneration,
    predictedGeneration,
    normalisedGeneration,
    peakConsumption,
    consumptionCost,
    peakGeneration
  } = projectEnergyData

  return (
    <ContextMenuTrigger renderTag="tr" id={"project-row-" + project.code} key={project.code}>
      <td className={projectStatus ? projectStatus : undefined}>{project.code}</td>
      <td className={projectStatus ? projectStatus : undefined}>{project.name}</td>
      {showGeneration && !showConsumption ? (
        <>
          <td className={"numerical"}>{project.dcSize ? EnergyUtils.formatNumber(project.dcSize) : ""}</td>
          <td className={"numerical"}>{project.acSize ? EnergyUtils.formatNumber(project.acSize) : ""}</td>
        </>
      ) : null}
      {showConsumption && !showGeneration ? <td className={"numerical"}>{project.peakPower ? EnergyUtils.formatNumber(project.peakPower) : ""}</td> : null}
      {showStorage ? (
        <>
          <td className="numerical ranged">{projectReading ? EnergyUtils.formatNumberAsKilo(projectReading.storage) : ""}</td>
        </>
      ) : null}
      {showConsumption ? (
        <>
          <td className="numerical ranged">{projectReading ? EnergyUtils.formatNumberAsKilo(projectReading.consumption) : ""}</td>
          <td className="numerical ranged">{predictedConsumption ? EnergyUtils.formatNumberAsKilo(predictedConsumption) : ""}</td>
          <td className="numerical ranged">{projectReading && predictedConsumption ? EnergyUtils.getPercent(projectReading.consumption, predictedConsumption) : ""}</td>
          {!showGeneration ? (
            <>
              <td className="numerical ranged">{peakConsumption ? EnergyUtils.formatNumberAsKilo(peakConsumption) : ""}</td>
              <td className="numerical ranged">{peakConsumption && project.peakPower ? math.round?.((EnergyUtils.convertToKilo(peakConsumption) / project.peakPower) * 100) : ""}</td>
              <td className="numerical ranged">{consumptionCost ? Utils.formatCurrency(consumptionCost, undefined, undefined, undefined) : ""}</td>
            </>
          ) : null}
        </>
      ) : null}
      {showGeneration ? (
        <>
          <td className="numerical ranged">{projectReading ? EnergyUtils.formatNumberAsKilo(projectReading.generation) : ""}</td>
          <td className="numerical ranged">{expectedGenerationContent}</td>
          <td className="numerical ranged">{isIrradianceComplete && projectReading && expectedGeneration ? EnergyUtils.getPercent(projectReading.generation, expectedGeneration) : ""}</td>
          <td className="numerical ranged">{predictedGeneration && predictedGeneration ? EnergyUtils.formatNumberAsKilo(predictedGeneration) : ""}</td>
          <td className="numerical ranged" title={`Based on forecast: ${EnergyUtils.displayWattHours(predictedGeneration)}`}>
            {projectReading && predictedGeneration && EnergyUtils.getPercent(projectReading.generation, predictedGeneration)}
          </td>
          <td className="numerical ranged">{EnergyUtils.formatNumber(specificYield / 1000, {maximumFractionDigits: 3})}</td>
          <td className="numerical ranged">{normalisedGeneration ? normalisedGeneration.toLocaleString("en") : ""}</td>
          {!showConsumption ? (
            <>
              <td className="numerical ranged">{peakGeneration ? EnergyUtils.formatNumberAsKilo(peakGeneration) : ""}</td>
              <td className="numerical ranged">{peakGeneration && project.acSize ? math.round?.((EnergyUtils.convertToKilo(peakGeneration) / project.acSize) * 100) : ""}</td>
            </>
          ) : null}
        </>
      ) : null}
    </ContextMenuTrigger>
  )
}

export default ProjectsTableRow
