import React from "react"
import moment from "moment-timezone"
import { Loading } from "@common/EcosuiteComponent"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts"
import DateRangeGraph from "../DateRangeGraph"
import { EnergyGraphTooltip } from "../EnergyGraphTooltip"
import GraphUtils from "@common/utils/GraphUtils"
import i18n from "src/i18n"
import Aggregations from "@common/Aggregations"
import { ReferenceLabel } from "../ReferenceLabel"

const { t } = i18n

function generateFutureSplitForecastData(data, aggregation, range) {
  switch (aggregation) {
    case Aggregations.Month: {
      return data.map((datum) => {
        if (moment(new Date(datum.time)).isSame(moment(), "month")) {
          const predictedGenerationFull = datum.predictedGeneration
          const daysInRange = Math.min(
            moment(range.localEndDate).diff(moment(datum.time), "days"),
            moment().daysInMonth(),
          )
          const predictedGeneration = (moment().date() / daysInRange) * predictedGenerationFull
          const predictedGenerationDiff = predictedGenerationFull - predictedGeneration
          return { ...datum, predictedGeneration, predictedGenerationFull, predictedGenerationDiff }
        }
        return { ...datum, predictedGenerationFull: datum.predictedGeneration, predictedGenerationDiff: 0 }
      })
    }
    case Aggregations.Year:
      return data.map((datum) => {
        if (moment(new Date(datum.time)).isSame(moment(), "year")) {
          const predictedGenerationFull = datum.predictedGeneration
          const predictedGeneration = ((moment().month() + 1) / 12) * predictedGenerationFull
          const predictedGenerationDiff = predictedGenerationFull - predictedGeneration
          return { ...datum, predictedGeneration, predictedGenerationFull, predictedGenerationDiff }
        }
        return { ...datum, predictedGenerationFull: datum.predictedGeneration, predictedGenerationDiff: 0 }
      })
    default:
      return data.map((d) => ({ ...d, predictedGenerationFull: d.predictedGeneration, predictedGenerationDiff: 0 }))
  }
}

function convertFutureForecastsToPredictedGenerationDiff(data, timezone) {
  return data.map((datum) => {
    //Create a timestamp using the projects timezone so it can be correctly compared to the current time
    if (moment.tz(datum.dateKey, timezone).unix() > moment().unix()) {
      return {
        ...datum,
        predictedGenerationDiff: datum.predictedGenerationFull,
        predictedGenerationFull: 0,
        predictedGeneration: 0,
      }
    }
    return datum
  })
}

function renderPredictedDaylightMarkers(predictedGenerationDatums) {
  if (!predictedGenerationDatums) {
    return
  }
  let systemPredictedDatums = Object.values(Object.values(predictedGenerationDatums)[0].assetDatums)[0]
  if (!systemPredictedDatums.Rise) {
    return
  }
  let dayStart = moment(Object.keys(predictedGenerationDatums)[0]).startOf("day").toDate().getTime()
  let rise = systemPredictedDatums.Rise.split(":")
  let riseSeconds = +rise[0] * 60 * 60
  let riseTime = dayStart + riseSeconds * 1000

  let set = systemPredictedDatums.Set.split(":")
  let setSeconds = +set[0] * 60 * 60
  let setTime = dayStart + setSeconds * 1000
  return (
    <>
      <ReferenceLine
        x={riseTime}
        stroke={GRAPH_COLORS[2]}
        strokeDasharray={"4 8"}
        label={<ReferenceLabel value={"Sunrise"} dx={-68} dy={16} fontSize={"12px"} fill={GRAPH_COLORS[2]} />}
      />
      <ReferenceLine
        x={setTime}
        stroke={GRAPH_COLORS[2]}
        strokeDasharray={"4 8"}
        label={<ReferenceLabel value={"Sunset"} dx={-12} dy={16} fontSize={"12px"} fill={GRAPH_COLORS[2]} />}
      />
    </>
  )
}

export default class TotalGenerationBarGraph extends DateRangeGraph {
  renderContent() {
    const timezone = this.props.project?.timezone
      ? this.props.project.timezone
      : this.props.assets[0]?.timezone
        ? this.props.assets[0].timezone
        : "America/New_York"

    if (this.props.graphDatums) {
      let data = Object.keys(this.props.graphDatums).map((dateKey) => {
        let entry = this.props.graphDatums[dateKey]
        let date = moment(dateKey)
        return {
          dateKey: dateKey,
          date: date.toDate(),
          time: date.toDate().getTime(),
          //keep in mind that this timestamp effectively sets (project timezone) as utc 0
          name: date.format("lll"),
          generation: this.round(entry.generation),
          expectedGeneration:
            this.props.expectedGenerationDatums && this.props.expectedGenerationDatums[dateKey]
              ? this.round(this.props.expectedGenerationDatums[dateKey].expectedGeneration)
              : null,
          predictedGeneration:
            this.props.predictedGenerationDatums && this.props.predictedGenerationDatums[dateKey]
              ? this.round(this.props.predictedGenerationDatums[dateKey].predictedGeneration)
              : null,
          max: entry.max,
        }
      })

      const formattedData = convertFutureForecastsToPredictedGenerationDiff(
        generateFutureSplitForecastData(data, this.props.aggregation, this.props.range),
        timezone,
      )

      let aggregation = this.props.aggregation
      let graphType = aggregation === "day" ? "linear" : "monotone"
      return (
        <ResponsiveContainer width="100%" height={250} className="ecogy-tooltip">
          <BarChart
            data={formattedData}
            margin={{ top: 5, right: 30, left: 0, bottom: 10 }}
            onClick={this.zoomDateRange}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
            <XAxis
              dataKey="time"
              domain={this.getDomain(this.props.range, this.props.aggregation)}
              tickFormatter={(time) => {
                return GraphUtils.dateFormat(time, this.props.range, this.props.aggregation)
              }}
            />
            <YAxis />
            <Tooltip
              content={
                <EnergyGraphTooltip
                  assets={this.props.assets}
                  getDataTitle={getDataTitle}
                  range={this.props.range}
                  aggregation={this.props.aggregation}
                  config={{
                    predictedGenerationDiff: {
                      override: (payload) => {
                        if (moment(payload.payload.time).isAfter(moment(), aggregation))
                          return { ...payload, hide: true }
                        if (moment(payload.payload.time).isBefore(moment(), aggregation))
                          return { ...payload, hide: true }
                        return payload
                      },
                    },
                    predictedGeneration: {
                      override: (payload, ctx) => {
                        const predictedGenerationDiffDatum = ctx.find(
                          (datum) => datum.dataKey === "predictedGenerationDiff",
                        )
                        if (!predictedGenerationDiffDatum) return payload
                        if (!moment(payload.payload.time).isAfter(moment(), aggregation)) return payload
                        return { ...payload, value: predictedGenerationDiffDatum.value }
                      },
                    },
                  }}
                />
              }
            />
            <Legend iconType="plainline" />
            {this.props.showGeneration ? (
              <Bar
                name={getDataTitle("generation")}
                type={graphType}
                dataKey="generation"
                stroke={GRAPH_COLORS[0]}
                fill={GRAPH_COLORS[0]}
                dot={aggregation === "day"}
              />
            ) : null}
            {this.props.showGeneration ? (
              <Bar
                name={getDataTitle("expectedGeneration")}
                type={graphType}
                dataKey="expectedGeneration"
                stroke={GRAPH_COLORS[1]}
                fill={GRAPH_COLORS[1]}
                dot={aggregation === "day"}
              />
            ) : null}
            {this.props.showGeneration ? (
              <Bar
                name={getDataTitle("predictedGeneration")}
                type={graphType}
                dataKey="predictedGeneration"
                stroke={GRAPH_COLORS[2]}
                fill={GRAPH_COLORS[2]}
                dot={aggregation === "day"}
                stackId={"predictedGenerationBar"}
              />
            ) : null}
            {this.props.showGeneration ? (
              <Bar
                name={getDataTitle("predictedGenerationRemainingTime")}
                type={graphType}
                dataKey="predictedGenerationDiff"
                stroke={GRAPH_COLORS[2]}
                fill={"#202020"}
                dot={true}
                stackId={"predictedGenerationBar"}
              />
            ) : null}
            {this.props.showGeneration ? renderPredictedDaylightMarkers(this.props.predictedGenerationDatums) : null}
          </BarChart>
        </ResponsiveContainer>
      )
    }

    return <Loading />
  }
}

var getDataTitle = function (name) {
  switch (name) {
    case "predictedGeneration":
      return t("dataTitles.predicted_generation")
    case "predictedGenerationRemainingTime":
      return t("dataTitles.predicted_gen_remaining_time")
    case "expectedGeneration":
      return t("dataTitles.expected_generation")
    case "generation":
      return t("dataTitles.generation")
    case "surplusForecast":
      return `Estimated Forecast for remaining time`
    default:
      return name
  }
}
