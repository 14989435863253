import React, { Component } from "react"
import Moment from "moment"
import { extendMoment } from "moment-range"

import { SideBarSection, LAYOUT_CONTENT_RIGHT } from "@common/module/EcosuiteView"
import EcosuiteComponent, { Loading, Error } from "@common/EcosuiteComponent"

import EnergyUtils from "../EnergyUtils"

import EnergyView from "./EnergyView"

import Site from "./site/Site"
import { ConsumptionGenerationPieGraph, SelfConsumptionPieGraph, EnergyConsumedPieGraph } from "../graphs/EnergyGraphs"
import AssetSummary from "./info/AssetSummary"
import Emissions from "./info/Emissions"
import EnergyService from "../EnergyService"
import ProjectUtils from "@common/utils/ProjectUtils"
import Logger from "@common/Logger"
import i18n from "src/i18n"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

const moment = extendMoment(Moment)
const { t } = i18n

class ProjectEnergyOverView extends EnergyView {
  componentDidMount() {
    super.componentDidMount()

    this.loadForecast()
    this.loadDeviceMetaData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project) {
      this.loadForecast()
      this.loadDeviceMetaData()
    }
  }

  /**
   * @param {Array<Object>} devices - the array of devices found in the system hierarchy
   * @returns {Object} - { "deviceType + deviceID": device, ... }
   */
  getDevicesAsObject(devices) {
    if (devices) {
      return devices.reduce((prev, value) => {
        return {
          ...prev,
          [`${value.type}${value.id}`]: value,
        }
      }, {})
    } else {
      return {}
    }
  }

  loadDeviceMetaData() {
    this.setStateIfMounted({ metaData: null })

    const projectId = this.props.project.code
    EnergyService.getDeviceMetaData(projectId).then((metaData) => {
      if (this.isProjectCurrent(projectId)) {
        Object.keys(metaData).forEach((sourcePath) => {
          const devicePath = `${ProjectUtils.getDeviceType(sourcePath)}${ProjectUtils.getDeviceId(sourcePath)}`
          const devicesObj = sourcePath ? this.getDevicesAsObject(ProjectUtils.getAssetsFromPath([this.props.project], sourcePath).system?.devices) : {}

          if (devicesObj[devicePath] && devicesObj[devicePath].serialNumber) {
            metaData[sourcePath].deviceInfo.serialNumber = devicesObj[devicePath].serialNumber
          }
        })
        this.setStateIfMounted({ metaData: metaData })
      } else {
        Logger.debug(`Ignoring out of date response for project: ${projectId}`)
      }
    })
  }

  loadForecast() {
    this.setStateIfMounted({ firstYearForecast: null, nextYearForecast: null })

    const project = this.props.project
    const projectId = project.code
    const generationStart = this.props.project?.generationStart
    // No generation start date means no generation systems which means we can skip loading generation data
    if (!generationStart) {
      return
    }

    const firstYearRange = moment.range(generationStart, ProjectUtils.projectMoment(project, generationStart).add(1, "year"))
    EnergyService.getProjectPredictedGeneration(firstYearRange, null, projectId).then((response) => {
      if (this.isProjectCurrent(projectId)) {
        this.setStateIfMounted({ firstYearForecast: response.projectDatums })
      } else {
        Logger.debug(`Ignoring out of date response for project: ${projectId}`)
      }
    })

    const nextYearStart = ProjectUtils.projectMoment(project).startOf("day")
    const nextYearRange = moment.range(nextYearStart, ProjectUtils.projectMoment(project, nextYearStart).add(1, "year"))
    EnergyService.getProjectPredictedGeneration(nextYearRange, null, projectId).then((response) => {
      if (this.isProjectCurrent(projectId)) {
        this.setStateIfMounted({ nextYearForecast: response.projectDatums })
      } else {
        Logger.debug(`Ignoring out of date response for project: ${projectId}`)
      }
    })
  }

  getInstantaneousTime() {
    if (this.props.instantaneousTimestamp) {
      return moment(this.props.instantaneousTimestamp).format("lll")
    }
    return null
  }

  getLayout() {
    return LAYOUT_CONTENT_RIGHT
  }

  getSiteStatus(siteId) {
    if (this.props.projectStatus) {
      return this.props.projectStatus.sites[siteId]
    }
    return null
  }

  getSiteReading(siteId) {
    if (this.props.readings) {
      return this.props.readings.sites[siteId]
    }
    return null
  }

  renderMainView() {
    if (this.props.project) {
      return (
        <div>
          <Project {...this.props} firstYearForecast={this.state.firstYearForecast} nextYearForecast={this.state.nextYearForecast} />

          {Object.values(this.props.project.sites).map((site) => {
            let siteStatus = this.isContentValid(this.props.projectStatus) ? this.props.projectStatus.sites[site.code] : this.props.projectStatus
            let siteDatums = this.isContentValid(this.props.datums) ? this.props.datums.sites[site.code] : this.props.datums
            let siteConsumptionCost = this.isContentValid(this.props.consumptionCost) ? this.props.consumptionCost.sites[site.code] : this.props.consumptionCost
            let sitePredictedConsumption = this.isContentValid(this.props.predictedConsumption) ? this.props.predictedConsumption.sites[site.code] : this.props.predictedConsumption
            let siteExpectedGeneration = this.isContentValid(this.props.expectedGeneration) ? this.props.expectedGeneration.sites[site.code] : this.props.expectedGeneration
            let sitePredictedGeneration = this.isContentValid(this.props.predictedGeneration) ? this.props.predictedGeneration.sites[site.code] : this.props.predictedGeneration
            let siteReadings = this.isContentValid(this.props.readings) ? this.props.readings.sites[site.code] : this.props.readings

            return (
              <Site
                key={site.code}
                groups={this.props.groups}
                project={this.props.project}
                site={site}
                metaData={this.state.metaData}
                datums={siteDatums}
                datumsRange={this.props.datumsRange}
                datumsAggregation={this.props.datumsAggregation}
                consumptionCost={siteConsumptionCost}
                predictedConsumption={sitePredictedConsumption}
                expectedGeneration={siteExpectedGeneration}
                predictedGeneration={sitePredictedGeneration}
                readings={siteReadings}
                siteStatus={siteStatus}
                range={this.props.range}
                selectRange={this.props.selectRange}
                showGeneration={this.props.showGeneration}
                showConsumption={this.props.showConsumption}
                showStorage={this.props.showStorage}
                userAlerts={this.props.userAlerts}
              />
            )
          })}
        </div>
      )
    } else {
      return <Loading />
    }
  }

  renderSiderBar() {
    return (
      <React.Fragment>
        {this.props.showGeneration ? (
          <React.Fragment>
            {this.props.lastMonthsEnergyReadings && <UncontrolledTooltip target="last-months-generation">{t("energy.tooltips.last_months_generation")}</UncontrolledTooltip>}
            <SideBarSection
              title={t("energy.labels.last_months_generation")}
              content={<TotalGeneration lastMonthsEnergyReadings={this.props.lastMonthsEnergyReadings} id="last-months-generation" />}
            />
          </React.Fragment>
        ) : null}
        {this.props.showGeneration ? <Emissions readings={this.props.readings} sourceType="generation" /> : null}
        {this.props.showGeneration ? (
          <SideBarSection title={t("energy.labels.instantaneous")} subtitle={this.getInstantaneousTime()} content={<InstantaneousGeneration instantaneous={this.props.instantaneous} />} />
        ) : null}
        {this.props.showConsumption ? <SideBarSection title={t("energy.labels.energy_consumed")} content={<EnergyConsumedPieGraph readings={this.props.readings} />} /> : null}
        {this.props.showConsumption ? <SideBarSection title={t("energy.labels.self_consumption")} content={<SelfConsumptionPieGraph readings={this.props.readings} />} /> : null}
        {this.props.showConsumption && this.props.showGeneration ? (
          <SideBarSection title={t("energy.labels.generation_vs_consumption")} content={<ConsumptionGenerationPieGraph readings={this.props.readings} />} />
        ) : null}
      </React.Fragment>
    )
  }
}

class Project extends Component {
  render() {
    return (
      <div className="project-details">
        {}
        <AssetSummary
          className="project-details-info"
          range={this.props.range}
          asset={this.props.project}
          project={this.props.project}
          status={this.props.projectStatus}
          readings={this.props.readings}
          consumptionCost={this.props.consumptionCost}
          predictedConsumption={this.props.predictedConsumption}
          expectedGeneration={this.props.expectedGeneration}
          predictedGeneration={this.props.predictedGeneration}
          showGeneration={this.props.showGeneration}
          showConsumption={this.props.showConsumption}
          showStorage={this.props.showStorage}
          firstYearForecast={this.props.firstYearForecast}
          nextYearForecast={this.props.nextYearForecast}
        />
      </div>
    )
  }
}

class TotalGeneration extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.lastMonthsEnergyReadings)) {
      return <Error error={this.props.lastMonthsEnergyReadings.getError()} />
    }
    if (this.isContentValid(this.props.lastMonthsEnergyReadings) && this.props.lastMonthsEnergyReadings.generation !== undefined) {
      return (
        <div className="sidebar-heading" id={this.props.id && this.props.id}>
          {EnergyUtils.displayWattHours(this.props.lastMonthsEnergyReadings.generation)}
        </div>
      )
    } else {
      return <Loading />
    }
  }
}

class InstantaneousGeneration extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.instantaneous)) {
      return <Error error={this.props.instantaneous.getError()} />
    }
    if (this.isContentValid(this.props.instantaneous) && this.props.instantaneous.generation !== undefined) {
      return <div className="sidebar-heading">{EnergyUtils.formatWattsAsKiloWatts(this.props.instantaneous.generation)}</div>
    } else {
      return <Loading />
    }
  }
}

export default ProjectEnergyOverView
