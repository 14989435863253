import React, { useState } from "react"
import { deleteRFI, deleteRFIReply, getCodeRFI, getRFI, rfiReplySchema, rfiSchema } from "@dashboard/process/views/rfi/RFIService"
import { Col, Row } from "reactstrap"
import { Loading } from "@common/EcosuiteComponent"
import RFIDetails from "@dashboard/process/views/rfi/RFIDetails"
import UserAdminService from "@admin/users/UserAdminService"
import RFIViewControls from "@dashboard/process/views/rfi/RFIViewControls"
import RFISelector from "@dashboard/process/views/rfi/RFISelector"
import { RFIEditor, RFIEditorValue } from "@dashboard/process/views/rfi/RFIEditor"
import { listCodeFiles } from "@dashboard/process/views/media/MediaService"
import { useCurrentEffect } from "@common/hooks/useCurrentEffect"
import { RFIStored } from "@ecogy-types/openapi-types"

/**
 * The RFI view.
 * @param code - The current project code.
 * @param userId - The current user ID.
 * @constructor
 */
export const RFIView = ({ code, userId }: { code: string; userId: string }): JSX.Element => {
  // Whether the view is initializing.

  const [initializingData, setInitializingData] = useState<boolean>(true)
  // All root level data that could be used.
  // All RFI.
  const [allRFI, setAllRFI] = useState<RFIStored[]>([])
  // The shown RFI. This is what is modified when using the search bar.
  const [shownRFI, setShownRFI] = useState<RFIStored[]>([])
  const [showResolved, setShowResolved] = useState<boolean>(true)
  // All users.
  const [allUsers, setAllUsers] = useState<User[]>([])
  // The current user.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined)
  // The schemas.
  const [currentRFISchema, setCurrentRFISchema] = useState<Schema>(undefined)
  const [currentRFIReplySchema, setCurrentRFIReplySchema] = useState<Schema>(undefined)

  // The project media.
  const [allMedia, setAllMedia] = useState<MediaResponse[] | undefined>(undefined)

  // The currently selected RFI.
  const [selectedRFI, setSelectedRFI] = useState<RFIStored | undefined>()

  // Whether the view is currently loading data.
  const [isPrimaryViewLoading, setIsPrimaryViewLoading] = useState<boolean>(false)

  // The object that is currently being edited.
  const [editing, setEditing] = useState<RFIStored | RFIReplyStored | undefined>()
  // What is being edited?
  const [rfiEditorValue, setRFIEditorValue] = useState<RFIEditorValue | undefined>()

  useCurrentEffect(
    (isCurrent) => {
      initializeData(isCurrent)
    },
    [code],
  )

  /**
   * Initialize the root data before loading the entire view.
   * @param isCurrent Whether the render is current.
   */
  const initializeData = async (isCurrent: () => boolean) => {
    setInitializingData(true)

    clearUI()

    // Get all current RFI.
    const rfiResponse = (await getCodeRFI(code)) as RFIStored[]
    // Get all users.
    const usersResponse = (await UserAdminService.getUsers()) as { users: User[] }
    const parsedUsers = usersResponse.users
    // Get the schemas.
    const rfiSchemaResponse = await rfiSchema()
    const rfiReplySchemaResponse = await rfiReplySchema()

    // Get all media.
    //
    // Don't wait on the response, let it run in the background.
    listCodeFiles(code).then((media) => {
      if (isCurrent()) {
        setAllMedia(media)
      }
    })

    if (isCurrent()) {
      setAllRFI(rfiResponse)
      setShownRFI(rfiResponse)
      setAllUsers(parsedUsers)
      // Set the current user.
      setCurrentUser(parsedUsers.find((user: User) => user.id === userId))

      setCurrentRFISchema(rfiSchemaResponse)
      setCurrentRFIReplySchema(rfiReplySchemaResponse)

      setInitializingData(false)
    }
  }

  /**
   * Clear the UI.
   */
  const clearUI = () => {
    setEditing(undefined)
    setRFIEditorValue(RFIEditorValue.RFI)
    setSelectedRFI(undefined)
  }

  /**
   * Refresh an RFI.
   * @param id - The RFI ID.
   */
  const refreshRFI = async (id: string) => {
    setIsPrimaryViewLoading(true)
    const newRFI = await getRFI(id)

    const newAllRFI: RFIStored[] = []
    const newShownRFI: RFIStored[] = []

    allRFI.forEach((rfi) => {
      if (rfi.id !== id) {
        newAllRFI.push(rfi)
      }
    })
    shownRFI.forEach((rfi) => {
      if (rfi.id !== id) {
        newShownRFI.push(rfi)
      }
    })

    setAllRFI([...newAllRFI, newRFI])
    setShownRFI([...newShownRFI, newRFI])
    setSelectedRFI(newRFI)
    setIsPrimaryViewLoading(false)
  }

  /**
   * Delete the selected RFI.
   * @param id - The RFI ID.
   */
  const deleteSelectedRFI = async (id: string) => {
    setIsPrimaryViewLoading(true)

    await deleteRFI(id)
    const filtered = allRFI.filter((rfi) => rfi.id !== id)
    setAllRFI(filtered)

    clearUI()
    setIsPrimaryViewLoading(false)
  }

  /**
   * Delete the selected RFI.
   * @param id - The RFI ID.
   */
  const deleteSelectedRFIReply = async (id: string) => {
    setIsPrimaryViewLoading(true)

    await deleteRFIReply(id)

    setEditing(undefined)
    setRFIEditorValue(RFIEditorValue.RFI)
    setIsPrimaryViewLoading(false)
  }

  /**
   * Get a user from the state.
   * @param id - The user ID.
   */
  const getUserInState = (id: string): User | undefined => {
    if (allUsers) {
      return allUsers.find((user) => user.id === id)
    }
  }

  // If the root view is still loading, return a loading view.
  if (initializingData) {
    return <Loading />
  }

  return (
    <>
      <Row>
        {allRFI.length > 0 && (
          <Col md={"3"}>
            <RFISelector
              shownRFI={showResolved ? shownRFI : shownRFI.filter((rfi) => !rfi.resolved)}
              selectedRFI={selectedRFI}
              selectRFI={(rfi) => {
                if (!selectedRFI) {
                  // If no RFI is selected, select this RFI.
                  setSelectedRFI(rfi)
                } else if (selectedRFI.id === rfi.id) {
                  if (editing || rfiEditorValue !== undefined) {
                    // If the user has clicked on the current RFI, and is currently editing, then return to the RFI.
                    setSelectedRFI(rfi)
                  } else {
                    // If the user is not editing, then deselect the RFI.
                    setSelectedRFI(undefined)
                  }
                } else {
                  // If the user did not click on the same RFI, then select the new RFI.
                  setSelectedRFI(rfi)
                }

                // Clear the editing state.
                setRFIEditorValue(undefined)
                setEditing(undefined)
              }}
              getUser={getUserInState}
            />
          </Col>
        )}
        <Col>
          {isPrimaryViewLoading ? (
            <Loading />
          ) : selectedRFI && rfiEditorValue === undefined ? (
            <RFIDetails
              rfi={selectedRFI}
              author={getUserInState(selectedRFI.author)}
              getUser={getUserInState}
              currentUser={currentUser}
              setEditing={(object, value) => {
                setEditing(object)
                setRFIEditorValue(value)
              }}
              onDeleteRFIReply={deleteSelectedRFIReply}
            />
          ) : !editing && rfiEditorValue == null ? (
            // TODO: Add a more elegant solution instead of an empty box.
            <></>
          ) : (
            <RFIEditor
              code={code}
              allMedia={allMedia}
              value={editing}
              selectedRFI={selectedRFI}
              rfiSchema={currentRFISchema}
              rfiReplySchema={currentRFIReplySchema}
              rfiEditorValue={rfiEditorValue}
              onCreateRFI={async (rfi) => {
                setEditing(undefined)
                setRFIEditorValue(undefined)
                await refreshRFI(rfi.id)
              }}
              onEditRFI={async (id) => {
                setEditing(undefined)
                setRFIEditorValue(undefined)
                await refreshRFI(id)
              }}
              onCreateRFIReply={async () => {
                setEditing(undefined)
                setRFIEditorValue(undefined)
              }}
              onEditRFIReply={async (id) => {
                setEditing(undefined)
                setRFIEditorValue(undefined)
                await refreshRFI(id)
              }}
            />
          )}
        </Col>
      </Row>
      <RFIViewControls
        allRFI={allRFI}
        shownRFI={shownRFI}
        selectedRFI={selectedRFI}
        setSelectedRFI={setSelectedRFI}
        onResolveRFI={async (id) => await refreshRFI(id)}
        setShownRFI={setShownRFI}
        showResolved={showResolved}
        setShowResolved={setShowResolved}
        onDeleteRFI={!selectedRFI || rfiEditorValue ? undefined : deleteSelectedRFI}
        onCreateNewRFI={() => {
          clearUI()
        }}
        onCreateNewRFIReply={
          !selectedRFI || rfiEditorValue
            ? undefined
            : () => {
                setEditing(undefined)
                setRFIEditorValue(RFIEditorValue.RFIReply)
              }
        }
      />
    </>
  )
}

export default RFIView
