import React, { Component } from "react"
import { Input, ListGroup, ListGroupItem } from "reactstrap"

/**
 * The Project browser a single project to be selected and viewed.
 */

export class ProjectBrowser extends Component {
  render() {
    return (
      <div className="project-selector">
        <ListGroup className={this.props.selectedProject ? "" : "portfolio-selected"}>
          {this.props.projects.map((project) => {
            return (
              <ListGroupItem
                key={project.code}
                active={this.props.selectedProject && this.props.selectedProject.code === project.code}
                tag="button"
                action
                onClick={() => this.props.actions.selectProject(project)}
                className={!this.props.disabledProjects.includes(project.code) ? "checked" : "unchecked"}
                title={project.code}
              >
                {!this.props.selectedProject ? (
                  <Input
                    type="checkbox"
                    checked={!this.props.disabledProjects.includes(project.code)}
                    onChange={(e) => {
                      e.stopPropagation()
                      this.props.actions.toggleProject(project.code)
                    }}
                  />
                ) : null}
                {project.name}
              </ListGroupItem>
            )
          })}
        </ListGroup>
      </div>
    )
  }
}
