import React from "react"
import { Button, Alert, Input, Col, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import update from "immutability-helper"

import EcosuiteComponent, { Loading, Error } from "@common/EcosuiteComponent"
import EcosuiteForm, {
  EcosuiteFieldTemplate,
  EcosuiteArrayFieldTemplate,
  EcosuiteObjectFieldTemplate,
  FormError,
} from "@common/form/EcosuiteForm"
import validateFormData from "@rjsf/core/lib/validate"
import EcosuiteObjectField from "@common/form/EcosuiteObjectField"
import EcosuiteNumberField from "@common/form/EcosuiteNumberField"
import ProjectUtils from "@common/utils/ProjectUtils"

import ProFormaService from "./ProFormaService"
import getProFormaUiSchema from "./ProFormaUISchema"
import getProFormaWidgets from "./ProFormaUIWidgets"
import Logger from "@common/Logger"
import BackdropDialog from "@common/input/button/BackdropDialog"
import FinanceService from "../project/finance/FinanceService"
import { isGoogleDocument } from "@common/GoogleUtils"
import { importToProForma } from "../ImportService"
import Schemas from "@common/Schemas"
import ChangeNoteModal from "@common/form/ChangeNoteModal"
import { uniqBy } from "lodash"
import ProFormaUtils from "@dashboard/finance/views/pro-forma-inputs/ProFormaUtils"
import i18n from "src/i18n"

var jsprim = require("jsprim")
const { t } = i18n

export default class ProFormaForm extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.submitFormRef = React.createRef()

    this.state = {
      note: "",
      versionName: "",
      showVersionModal: false,
      showImportDialog: false,
      showSetFinancialModelDialog: false,
    }

    this.projectProFormaChanged = this.projectProFormaChanged.bind(this)
    this.updateProjectProForma = this.updateProjectProForma.bind(this)
    this.toggleVersion = this.toggleVersion.bind(this)
    this.saveVersion = this.saveVersion.bind(this)
    this.deleteVersion = this.deleteVersion.bind(this)
    this.restoreVersion = this.restoreVersion.bind(this)
    this.getFinancialModelImport = this.getFinancialModelImport.bind(this)
    this.renderCashFlowFilters = this.renderCashFlowFilters.bind(this)
    this.filterCashFlows = this.filterCashFlows.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    this.setStateIfMounted({ proForma: this.props.proForma, validationErrorSchema: undefined })
    this.loadSchema()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (prevProps.proForma !== this.props.proForma) {
      this.setStateIfMounted({ proForma: this.props.proForma, validationErrorSchema: undefined })
    }
    if (prevProps.project !== this.props.project) {
      this.loadSchema()
    }
  }

  loadSchema() {
    Schemas.getProFormaSchema().then((schema) => {
      this.setStateIfMounted({ schema: schema })
    })
  }

  async loadProForma() {
    return await this.props.loadProForma()
  }

  isReadOnly() {
    return this.props.readonly
  }

  async loadVersions(selectedVersion) {
    this.props.loadVersions(selectedVersion)
  }

  projectProFormaChanged(form) {
    let proForma = form.formData

    if (this.maintainRates(proForma)) {
      Logger.debug(`Updated pro forma as the rates have been modified`)
    }

    this.setStateIfMounted({ proForma: proForma })
  }

  /**
   * Updates the rate configuration on the pro forma.
   * @param {*} proForma The pro forma to maintain
   * @return true if the pro forma was modified, false if the pro forma didn't change
   */
  maintainRates(proForma) {
    const originalProForma = jsprim.deepCopy(proForma)
    let updated = false
    if (proForma && proForma.cashFlows && proForma.cashFlows.length) {
      proForma.cashFlows.forEach((proFormaCashFlow, proFormaCashFlowIdx) => {
        if (proFormaCashFlow.payments) {
          proFormaCashFlow.payments.forEach((payment, paymentIdx) => {
            if (
              payment.paymentType === "recurring" ||
              payment.paymentType === "generation" ||
              payment.paymentType === "size"
            ) {
              if (!payment.recurrence || payment.recurrence.billedAtStart === undefined) {
                if (!payment.recurrence) {
                  payment.recurrence = {}
                }
                payment.recurrence.billedAtStart = true // we default billing to the start of the period, this is already defined in the schema but we're not seeing it be applied
                updated = true
              }
            }
            if (payment.recurrence && payment.recurrence.rateType === "variable") {
              let term = payment.recurrence.term ? payment.recurrence.term : 0
              let rates = []
              if (payment.recurrence.rates) {
                for (let i = 0; i < term; i++) {
                  rates[i] = payment.recurrence.rates[i] // Add rate entries to match the term keeping any existing ones
                }
              } else {
                for (let i = 0; i < term; i++) {
                  rates[i] = undefined // Add rate entries to match the term
                }
              }
              proFormaCashFlow.payments[paymentIdx] = update(payment, { recurrence: { rates: { $set: rates } } })

              // We compare the updated record to the original to see if there are any differences
              if (this.props.proForma.cashFlows && this.props.proForma.cashFlows[proFormaCashFlowIdx]) {
                const rateTypeModified =
                  this.props.proForma.cashFlows[proFormaCashFlowIdx].payments[paymentIdx]?.rateType !==
                  payment.recurrence.rateType
                const ratesModified = !jsprim.deepEqual(
                  originalProForma.cashFlows[proFormaCashFlowIdx].payments[paymentIdx].rates,
                  proFormaCashFlow.payments[paymentIdx].rates,
                )

                updated = updated || ratesModified || rateTypeModified
              }
            }
          })
        }
      })
    }

    return updated
  }

  async updateProjectProForma(form) {
    this.setStateIfMounted({ expandAll: false })

    let proForma = form.formData
    const validationResults = validateFormData(proForma, this.getProFormaSchema())

    if (validationResults.errors?.length) {
      this.setStateIfMounted({ validationErrorSchema: validationResults.errorSchema })
    } else if (!this.state.note) {
      this.toggleChangeNoteModal()
    } else {
      this.setStateIfMounted({ loading: true, error: null })
      if (this.isVersion()) {
        const [, version] = this.props.proForma.id.split("/")
        Logger.info(`Updating Pro Forma: ${this.props.project.code} version: ${version}`)
        ProFormaService.saveProjectProFormaVersion(this.props.project.code, proForma, version, this.state.note)
          .then(async (proForma) => {
            // Update the parent pro forma.
            await this.loadProForma()
            // Load the versions.
            this.loadVersions(proForma).then(() => {
              this.setStateIfMounted({
                loading: false,
                note: "",
              })
            })
          })
          .catch((err) => {
            this.setStateIfMounted({ loading: false, expandAll: true, error: err })
          })
      } else {
        Logger.info(`Saving Pro Forma: ${this.props.project.code}`)
        ProFormaService.saveProjectProForma(this.props.project.code, proForma, this.state.note)
          .then(async () => {
            // Update the parent pro forma.
            await this.loadProForma()

            this.setStateIfMounted({
              loading: false,
              note: "",
            })
          })
          .catch((err) => {
            this.setStateIfMounted({ loading: false, expandAll: true, error: err })
          })
      }
    }
  }

  isVersion() {
    // Version pro formas are in the form pro-forma-<project code>/<version>
    return this.props.proForma?.id?.indexOf("/") > 0
  }

  saveVersion() {
    if (!this.state.versionName) {
      this.setStateIfMounted({ versionError: t("errors.version_error") })
    } else {
      this.setStateIfMounted({
        versionMessage: t("messages.version_mesg") + this.state.versionName,
        versionError: null,
      })
      const proForma = jsprim.deepCopy(this.state.proForma)
      delete proForma.id
      delete proForma.created
      delete proForma.updated

      Logger.info(`Creating Pro Forma: ${this.props.project.code} version: ${this.state.versionName}`)
      ProFormaService.saveProjectProFormaVersion(this.props.project.code, proForma, this.state.versionName)
        .then(() => {
          this.setStateIfMounted(
            {
              versionError: null,
              versionMessage: "Pro Forma version saved",
              versionName: "",
            },
            () => {
              this.toggleVersion()
            },
          )
          this.loadVersions()
        })
        .catch((err) => {
          this.setStateIfMounted({ versionError: err.message, versionMessage: null })
        })
    }
  }

  deleteVersion() {
    const projectId = this.props.project.code
    const [, version] = this.props.proForma.id.split("/")
    const deleteConfirmed = confirm(`${t("data.proforma.version_confirm_delete_msg")} "${version}"`)
    if (deleteConfirmed) {
      this.setStateIfMounted({ loading: true })
      ProFormaService.deleteProjectProFormaVersion(projectId, version)
        .then(() => {
          this.setStateIfMounted({ loading: false, version: null })
          this.loadVersions()
        })
        .catch((err) => {
          this.setStateIfMounted({ loading: false })
          Logger.error(err)
          alert(`${t("data.errorMsg.unable_todelete_version")}: ${version}`)
        })
    }
  }

  restoreVersion() {
    const projectId = this.props.project.code
    const [, version] = this.props.proForma.id.split("/")
    const restoreConfirmed = confirm(t("data.proforma.version_confirm_restore_msg", version))
    if (restoreConfirmed) {
      this.setStateIfMounted({ loading: true })
      const proForma = jsprim.deepCopy(this.props.proForma)
      proForma.id = this.state.proForma.id
      proForma.updated = this.props.originalProForma.updated
      ProFormaService.saveProjectProForma(projectId, proForma, `Restoring verion: ${version}`)
        .then(() => {
          this.loadProForma().then(() => {
            this.setStateIfMounted({ loading: false, version: null })
          })
        })
        .catch((err) => {
          this.setStateIfMounted({ loading: false })
          Logger.error(err)
          alert(`Unable to restore version: ${version}`)
        })
    }
  }

  renderVersion() {
    return (
      <Modal isOpen={this.state.showVersionModal} toggle={this.toggleVersion}>
        <ModalHeader toggle={this.toggleVersion}>Tag Version</ModalHeader>
        <ModalBody>
          {t("data.modal_body.version_tagging")}
          <Input
            type="text"
            placeholder="version name"
            required={true}
            value={this.state.versionName}
            onChange={(e) => {
              this.setStateIfMounted({ versionName: e.target.value })
            }}
          />
          {this.state.versionMessage ? <Alert color="info">{this.state.versionMessage}</Alert> : null}
          {this.state.versionError ? <Alert color="danger">{this.state.versionError}</Alert> : null}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggleVersion}>
            {t("buttons.cancel")}
          </Button>
          <Button color="primary" onClick={this.saveVersion}>
            {t("buttons.tag_version")}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    )
  }

  toggleVersion() {
    const modified = !jsprim.deepEqual(this.props.proForma, this.state.proForma)
    if (modified) {
      alert(t("data.proforma.changes_alert"))
    } else {
      this.setStateIfMounted({
        showVersionModal: !this.state.showVersionModal,
        versionError: null,
        versionMessage: "",
      })
    }
  }

  getProFormaSchema() {
    const proFormaSchema = jsprim.deepCopy(this.state.schema)
    if (proFormaSchema) {
      const project = this.props.project
      const paths = [ProjectUtils.getPath(project)]
      const pathNames = [`${ProjectUtils.getPath(project)} (Entire Project)`]
      Object.values(project.sites).forEach((site) => {
        paths.push(ProjectUtils.getPath(project, site))
        pathNames.push(`${ProjectUtils.getPath(project, site)} (Site: ${site.name})`)

        Object.values(site.systems).forEach((system) => {
          paths.push(ProjectUtils.getPath(project, site, system))
          pathNames.push(
            `${ProjectUtils.getPath(project, site, system)} (Site: ${site.name} -> System: ${system.name})`,
          )
        })
      })
      // We add project specific path enums to the generation, size and tariff payment types
      proFormaSchema.definitions.payment.dependencies.paymentType.oneOf[2].properties.path.enum = paths
      proFormaSchema.definitions.payment.dependencies.paymentType.oneOf[2].properties.path.enumNames = pathNames
      proFormaSchema.definitions.payment.dependencies.paymentType.oneOf[3].properties.path.enum = paths
      proFormaSchema.definitions.payment.dependencies.paymentType.oneOf[3].properties.path.enumNames = pathNames
      proFormaSchema.definitions.payment.dependencies.paymentType.oneOf[4].properties.path.enum = paths
      proFormaSchema.definitions.payment.dependencies.paymentType.oneOf[4].properties.path.enumNames = pathNames

      if (this.props.records && this.props.records.length) {
        const recordIds = this.props.records.map((record) => record.id)
        const recordNames = this.props.records.map((record) => record.name)
        proFormaSchema.definitions.cashFlow.properties.recordId.enum = recordIds
        proFormaSchema.definitions.cashFlow.properties.recordId.enumNames = recordNames
      }

      if (
        (!ProFormaUtils.isVersion(this.state.proForma) && project?.dcSize) ||
        this.state.proForma?.versionProject?.dcSize
      ) {
        proFormaSchema.properties.systemSize.description =
          "REFERENCE ONLY: The Project -> System configuration overrides this value when forecasting generation"
        proFormaSchema.properties.systemProduction.description =
          "REFERENCE ONLY: The Project -> System configuration overrides this value when forecasting generation"
        proFormaSchema.properties.degradation.description =
          "REFERENCE ONLY: The Project -> System configuration overrides this value when forecasting generation"
      }
    }

    return proFormaSchema
  }

  async getFinancialModelImport(mergeMethod) {
    const projectId = this.props.project.code
    return importToProForma(projectId, mergeMethod)
  }

  /**
   * @param {(() => void) | undefined} callback - what to do after the state updates
   */
  toggleChangeNoteModal(callback) {
    this.setStateIfMounted({ isChangeNoteOpen: !this.state.isChangeNoteOpen }, callback)
  }

  renderContent() {
    const { t } = i18n
    if (
      this.state.schema &&
      this.props.settings &&
      this.props.categories &&
      this.props.IRRCategories &&
      !this.state.loading
    ) {
      if (this.isContentError(this.state.proForma)) {
        return <Error error={this.state.proForma.getError()} />
      } else if (this.isContentValid(this.state.proForma)) {
        let fields = {
          ObjectField: EcosuiteObjectField,
          NumberField: EcosuiteNumberField,
        }

        return (
          <div className="proforma-content">
            {this.props.settings.financialModelUrl ? ( // If indicated, show the backdrop dialog associated with
              // importing and exporting from the financial model.
              <BackdropDialog
                type={"option"}
                title={t("data.proforma.body_title")}
                body={
                  <>
                    <p>
                      {t("data.proforma.body_text_info")}{" "}
                      <a target={"_blank"} rel={"noreferrer"} href={this.props.settings.financialModelUrl}>
                        {this.props.settings.financialModelUrl}
                      </a>
                      {t("data.proforma.if_incorrect")}{" "}
                      <span style={{ fontWeight: "bold" }}>{t("data.proforma.financial_model_url")}</span>{" "}
                      {t("data.proforma.setting_within")}{" "}
                      <span style={{ fontWeight: "bold" }}>{t("data.proforma.finance")}</span> {t("data.proforma.tab")}.
                    </p>
                  </>
                }
                isOpen={this.state.showImportDialog}
                inputTooltip={t("data.proforma.input_tooltip")}
                optionProps={{
                  options: {
                    overwrite: t("data.proforma.overwrite"),
                    replace: t("data.proforma.replace"),
                  },
                  defaultOptionKey: "overwrite",
                  optionInfo: {
                    overwrite: {
                      color: "info",
                      text: t("data.proforma.overwrite_text"),
                      performText: t("data.proforma.overwrite_performText"),
                    },
                    replace: {
                      color: "warning",
                      text: t("data.proforma.replace_text"),
                      performText: t("data.proforma.replace_performText"),
                    },
                  },
                }}
                onCancel={() => this.setStateIfMounted({ showImportDialog: false })}
                onConfirm={async (option) => {
                  const response = await this.getFinancialModelImport(option)
                  const updatedProforma = response.updatedProForma
                  this.setStateIfMounted({
                    proForma: updatedProforma,
                    form: { formData: updatedProforma },
                  })
                }}
                onComplete={() => this.setStateIfMounted({ showImportDialog: false })}
              />
            ) : (
              <BackdropDialog
                type={"input"}
                title={t("data.proforma.missing_financial_model")}
                body={
                  <>
                    <p>{t("data.proforma.no_financial_model_text")}</p>
                    <p>
                      {t("data.proforma.no_financial_model_inform")},{" "}
                      <span style={{ fontWeight: "bold" }}>{t("data.proforma.hosted_by_googleSheets")}.</span>
                    </p>
                  </>
                }
                inputTooltip={t("data.proforma.financial_model_url")}
                isOpen={this.state.showSetFinancialModelDialog}
                onCancel={() => this.setStateIfMounted({ showSetFinancialModelDialog: false })}
                inputValidator={(value) => isGoogleDocument(value)}
                onValidationErrorMessage={t("errors.google_doc_input")}
                onConfirm={async (value) => {
                  // If the input is empty, then generate.
                  const currentSettings = this.props.settings
                  currentSettings.financialModelUrl = value
                  await FinanceService.updateProjectSettings(this.props.project.code, currentSettings)
                }}
                displayText={t("data.proforma.input_warning")}
                inputProps={{
                  inputPlaceholder: t("data.proforma.google_doc_url"),
                  performText: t("loadingMsg.update_finance"),
                }}
                onComplete={() =>
                  this.setStateIfMounted({
                    showSetFinancialModelDialog: false,
                    showImportDialog: true,
                  })
                }
              />
            )}
            <div className="ecogy-form">
              <EcosuiteForm
                schema={this.getProFormaSchema()}
                uiSchema={getProFormaUiSchema()}
                formData={this.state.proForma}
                FieldTemplate={EcosuiteFieldTemplate}
                ObjectFieldTemplate={EcosuiteObjectFieldTemplate}
                ArrayFieldTemplate={EcosuiteArrayFieldTemplate}
                onSubmit={this.updateProjectProForma}
                onChange={this.projectProFormaChanged}
                transformErrors={(errors) => uniqBy(errors, (r) => r.stack)}
                onError={(e) => {
                  const propertyId = `root${e[0]?.property
                    .replaceAll("].", "_")
                    .replaceAll("[", "_")
                    .replaceAll(".", "_")}`
                  try {
                    Logger.debug("Focus on error property: " + propertyId)
                    document.getElementById(propertyId).focus()
                  } catch (e) {
                    Logger.error("Can't focus on error property: " + propertyId)
                  }
                }}
                fields={fields}
                widgets={getProFormaWidgets()}
                formContext={{
                  project: this.props.project,
                  proForma: this.state.proForma,
                  originalProForma: this.props.proForma,
                  readonly: this.isReadOnly(),
                  expandAll: !!this.state.expandAll,
                  categories: this.props.categories,
                  IRRCategories: this.props.IRRCategories,
                  startDate: this.state.proForma.projectStartDate,
                  cashFlowFilter: this.renderCashFlowFilters,
                }}
                // disabled={this.isReadOnly()}
                extraErrors={this.state.validationErrorSchema}
                showErrorList={false}
                noHtml5Validate // force ajv validator to handle html5 errors instead of the browser
              >
                {this.renderButtons()}

                {/* react-jsonschema-form doesn't expose submit() or requestSubmit() inside of its ref */}
                {/* So a reference to this button is needed to submit from inside a modal / nested component */}
                <button ref={(ref) => (this.submitFormRef = ref)} style={{ display: "none" }} />
              </EcosuiteForm>
            </div>
            {this.renderVersion()}

            <ChangeNoteModal
              isOpen={this.state.isChangeNoteOpen}
              toggle={() => this.toggleChangeNoteModal()}
              submit={() => this.toggleChangeNoteModal(() => this.submitFormRef.click())}
              canSubmit={!!this.state.note}
            >
              <Col className="audit-section">
                <Alert color="info">{t("alertsInfo.alert_audit")}</Alert>
                <Input type="textarea" value={this.state.note} onChange={this.saveNote} className="audit-note" />
              </Col>
            </ChangeNoteModal>
          </div>
        )
      }
    }
    return <Loading message={t("loadingMsg.loading_proforma")} />
  }

  renderCashFlowFilters() {
    const { t } = i18n
    return (
      <div className="pro-forma-cash-flow-filters">
        <div>
          <Label>{t("labels.category")}</Label>
          <Input id="search-category" type="select" onChange={this.filterCashFlows}>
            <option value="">{t("labels.all")}</option>
            {Object.values(this.props.categories).map((category) => {
              return (
                <option key={category.name} value={category.name}>
                  {category.name}
                </option>
              )
            })}
          </Input>

          <Label>{t("labels.search")}</Label>
          <Input id="search-term" value={this.state.searchTerm} onChange={this.filterCashFlows} />

          <Button
            className="float-end"
            onClick={() => {
              Array.from(document.getElementsByClassName("cash-flow-entry")).forEach((element) => {
                element.classList.remove("collapsed")
              })
            }}
          >
            {t("buttons.expand_all")}
          </Button>
          <Button
            className="float-end"
            onClick={() => {
              Array.from(document.getElementsByClassName("cash-flow-entry")).forEach((element) => {
                element.classList.add("collapsed")
              })
            }}
          >
            {t("buttons.collapse_all")}
          </Button>
        </div>
      </div>
    )
  }

  filterCashFlows() {
    const searchTerm = document.getElementById("search-term").value
    const category = document.getElementById("search-category").value

    const proForma = this.props.proForma
    if (proForma && proForma.cashFlows) {
      proForma.cashFlows.forEach((cashFlow, idx) => {
        const entryId = `root_cashFlows_${idx}-entry`
        if (
          (!category || category === cashFlow.category) &&
          (!searchTerm || JSON.stringify(cashFlow).toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)
        ) {
          document.getElementById(entryId).classList.remove("search-hide")
        } else {
          document.getElementById(entryId).classList.add("search-hide")
        }
      })
    }
  }

  renderButtons() {
    const importId = "import-financial-model-button"
    const { t } = i18n

    return (
      <div className="ecogy-form-buttons row">
        <Col className="message-section" sm="12">
          {this.renderMessages()}
        </Col>
        {this.isVersion() ? (
          <Col className="button-section" sm="4">
            <Button color="primary" onClick={() => this.toggleChangeNoteModal()} disabled={this.isReadOnly()}>
              {t("buttons.update_version")}
            </Button>
            <Button color="ecogy" type="submit" onClick={this.restoreVersion} disabled={this.isReadOnly()}>
              {t("buttons.restore_version")}
            </Button>
            <Button color="danger" type="submit" onClick={this.deleteVersion} disabled={this.isReadOnly()}>
              {t("buttons.delete_version")}
            </Button>
          </Col>
        ) : (
          <Col className="button-section" sm="3">
            <Button color="primary" onClick={() => this.toggleChangeNoteModal()} disabled={this.isReadOnly()}>
              {t("buttons.save")}
            </Button>
            <Button disabled={this.isReadOnly()} onClick={this.toggleVersion}>
              {t("buttons.tag_version")}
            </Button>
            <UncontrolledTooltip target={importId}>{t("data.proforma.import_data")}</UncontrolledTooltip>
            <Button
              id={importId}
              color={"primary"}
              onClick={() =>
                this.setStateIfMounted(
                  this.props.settings.financialModelUrl
                    ? { showImportDialog: true }
                    : { showSetFinancialModelDialog: true },
                )
              }
            >
              {t("buttons.import")}
            </Button>
          </Col>
        )}
      </div>
    )
  }

  renderMessages() {
    if (this.state.error) {
      return (
        <FormError
          error={this.state.error}
          toggle={() => {
            this.setStateIfMounted({ error: null })
          }}
        />
      )
    } else {
      return null
    }
  }
}
