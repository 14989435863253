import React from "react"

import EcosuiteComponent, { Error } from "@common/EcosuiteComponent"
import Settings from "@common/Settings"

import GraphSelector from "@dashboard/GraphSelector"

import DatumUtils from "./datums/DatumUtils"

import TotalAreaGraph from "./TotalAreaGraph"

import TotalGenerationBarGraph from "./generation/TotalGenerationBarGraph"
import TotalConsumptionBarGraph from "./consumption/TotalConsumptionBarGraph"
import GenerationLineGraph from "./generation/GenerationLineGraph"
import GenerationStackedBarGraph from "./generation/GenerationStackedBarGraph"

import EventLineGraph from "@dashboard/event/line-graph/EventLineGraph"
import TotalPowerLineGraph from "./TotalPowerLineGraph"
import GenerationPowerLineGraph from "./generation/GenerationPowerLineGraph"
import ConsumptionPowerLineGraph from "./consumption/ConsumptionPowerLineGraph"
import StoreageLineGraph from "./storage/StorageLineGraph"
import i18n from "src/i18n"

const { t } = i18n
export default class EnergyDashboardFooter extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state = {
      energyGraphView: Settings.getSetting("energyGraphView", "total"),
    }
    this.selectGraph = this.selectGraph.bind(this)
  }

  selectGraph(graph) {
    Settings.setSetting("energyGraphView", graph)
    this.setState({ energyGraphView: graph })
  }

  renderContent() {
    if (this.isContentError(this.props.datums)) {
      return <Error error={this.props.datums.getError()} />
    } else if (this.isContentValid(this.props.datums)) {
      // Filter down the datums to the selected projects
      let projectDatums = {}
      this.props.projects.forEach((project) => {
        return (projectDatums[project.code] = this.props.datums.projects[project.code])
      })
      let graphDatums = DatumUtils.groupDatums(this.props.projects, projectDatums)
      let consumptionCostDatums = this.isContentValid(this.props.consumptionCost)
        ? DatumUtils.groupConsumptionCostDatums(this.props.projects, this.props.consumptionCost.projects)
        : null
      let predictedConsumptionDatums = this.isContentValid(this.props.predictedConsumption)
        ? DatumUtils.groupPredictedConsumptionDatums(this.props.projects, this.props.predictedConsumption.projects)
        : null
      let expectedGenerationDatums = this.isContentValid(this.props.expectedGeneration)
        ? DatumUtils.groupExpectedGenerationDatums(this.props.projects, this.props.expectedGeneration.projects)
        : null
      let predictedGenerationDatums = this.isContentValid(this.props.predictedGeneration)
        ? DatumUtils.groupPredictedGenerationDatums(this.props.projects, this.props.predictedGeneration.projects)
        : null
      let aggregation = this.props.datums.aggregation
      let range = this.props.datums.range

      let centered =
        this.state.energyGraphView === "totalGeneration" ||
        this.state.energyGraphView === "totalConsumption" ||
        this.state.energyGraphView === "projectGenerationBar"

      let eventLine = (
        <EventLineGraph
          groups={this.props.groups}
          projects={this.props.projects}
          range={this.props.range}
          dotAggregate={aggregation + "s"}
          type="energy"
          centered={centered}
          style={{
            marginLeft: "60px",
            width: "calc(100% - 90px)",
          }}
        />
      )

      let graphs = {
        total: {
          graph: (
            <React.Fragment>
              {this.props.showConsumption ? (
                <EventLineGraph
                  groups={this.props.groups}
                  projects={this.props.projects}
                  range={this.props.range}
                  dotAggregate={aggregation + "s"}
                  type="energy"
                  style={{
                    marginLeft: "60px",
                    marginRight: "60px",
                    width: "calc(100% - 150px)",
                  }}
                />
              ) : (
                eventLine
              )}

              <TotalAreaGraph
                assets={this.props.projects}
                graphDatums={graphDatums}
                datums={projectDatums}
                consumptionCostDatums={consumptionCostDatums}
                predictedConsumptionDatums={predictedConsumptionDatums}
                expectedGenerationDatums={expectedGenerationDatums}
                predictedGenerationDatums={predictedGenerationDatums}
                range={range}
                aggregation={aggregation}
                showGeneration={this.props.showGeneration}
                showConsumption={this.props.showConsumption}
                showStorage={this.props.showStorage}
                selectRange={this.props.selectRange}
              />
            </React.Fragment>
          ),
          name: `${t("energy.graphNames.total")} (kW)`,
        },
      }

      if (this.props.showGeneration) {
        graphs.totalGeneration = {
          graph: (
            <React.Fragment>
              {eventLine}
              <TotalGenerationBarGraph
                assets={this.props.projects}
                graphDatums={graphDatums}
                datums={projectDatums}
                consumptionCostDatums={consumptionCostDatums}
                predictedConsumptionDatums={predictedConsumptionDatums}
                expectedGenerationDatums={expectedGenerationDatums}
                predictedGenerationDatums={predictedGenerationDatums}
                range={range}
                aggregation={aggregation}
                showGeneration={this.props.showGeneration}
                showConsumption={this.props.showConsumption}
                selectRange={this.props.selectRange}
              />
            </React.Fragment>
          ),
          name: `${t("energy.graphNames.total_generation")} (kW)`,
        }
        graphs.projectGenerationLine = {
          graph: (
            <React.Fragment>
              {eventLine}
              <GenerationLineGraph
                assets={this.props.projects}
                graphDatums={graphDatums}
                datums={projectDatums}
                range={range}
                aggregation={aggregation}
                selectRange={this.props.selectRange}
              />
            </React.Fragment>
          ),
          name: `${t("energy.graphNames.project_generation")} (kW)`,
        }
        graphs.projectGenerationBar = {
          graph: (
            <React.Fragment>
              {eventLine}
              <GenerationStackedBarGraph
                assets={this.props.projects}
                graphDatums={graphDatums}
                datums={projectDatums}
                range={range}
                aggregation={aggregation}
                selectRange={this.props.selectRange}
              />
            </React.Fragment>
          ),
          name: `${t("energy.graphNames.project_generation_stacked")} (kW)`,
        }
        graphs.projectGenerationNormalised = {
          graph: (
            <React.Fragment>
              {eventLine}
              <GenerationLineGraph
                assets={this.props.projects}
                graphDatums={graphDatums}
                datums={projectDatums}
                range={range}
                aggregation={aggregation}
                normalise={true}
                selectRange={this.props.selectRange}
              />
            </React.Fragment>
          ),
          name: t("energy.graphNames.project_generation_normalized"),
        }
      }
      if (this.props.showConsumption) {
        graphs.totalConsumption = {
          graph: (
            <React.Fragment>
              {this.props.showConsumption ? (
                <EventLineGraph
                  groups={this.props.groups}
                  projects={this.props.projects}
                  range={this.props.range}
                  dotAggregate={aggregation + "s"}
                  type="energy"
                  centered={centered}
                  style={{
                    marginLeft: "60px",
                    marginRight: "60px",
                    width: "calc(100% - 150px)",
                  }}
                />
              ) : (
                eventLine
              )}

              <TotalConsumptionBarGraph
                assets={this.props.projects}
                graphDatums={graphDatums}
                datums={projectDatums}
                consumptionCostDatums={consumptionCostDatums}
                predictedConsumptionDatums={predictedConsumptionDatums}
                expectedGenerationDatums={expectedGenerationDatums}
                predictedGenerationDatums={predictedGenerationDatums}
                range={range}
                aggregation={aggregation}
                showGeneration={this.props.showGeneration}
                showConsumption={this.props.showConsumption}
                selectRange={this.props.selectRange}
              />
            </React.Fragment>
          ),
          name: `${t("energy.graphNames.costs_and_consumption")} (kWh)`,
        }
      }

      let readingsGraphName = `${t("labels.generation")} (kW) & ${t("table_headings.demand")} (kW)`
      if (!this.props.showConsumption) {
        readingsGraphName = `${t("labels.generation")} (kW)`
      } else if (!this.props.showGeneration) {
        readingsGraphName = `${t("table_headings.demand")} (kW)`
      }

      graphs.readings = {
        graph: (
          <React.Fragment>
            {eventLine}
            <TotalPowerLineGraph
              assets={this.props.projects}
              graphDatums={graphDatums}
              datums={projectDatums}
              range={range}
              aggregation={aggregation}
              showGeneration={this.props.showGeneration}
              showConsumption={this.props.showConsumption}
              selectRange={this.props.selectRange}
            />
          </React.Fragment>
        ),
        name: readingsGraphName,
      }

      if (this.props.showGeneration) {
        graphs.projectGenerationPower = {
          graph: (
            <React.Fragment>
              {eventLine}
              <GenerationPowerLineGraph
                assets={this.props.projects}
                graphDatums={graphDatums}
                datums={projectDatums}
                range={range}
                aggregation={aggregation}
                selectRange={this.props.selectRange}
              />
            </React.Fragment>
          ),
          name: `${t("energy.graphNames.project_generation_power")} (kW)`,
        }
      }

      if (this.props.showConsumption) {
        graphs.projectConsumptionPower = {
          graph: (
            <React.Fragment>
              {eventLine}
              <ConsumptionPowerLineGraph
                assets={this.props.projects}
                graphDatums={graphDatums}
                datums={projectDatums}
                range={range}
                aggregation={aggregation}
                selectRange={this.props.selectRange}
              />
            </React.Fragment>
          ),
          name: `${t("energy.graphNames.project_consumption_power")} (kW)`,
        }
      }

      if (this.props.showStorage) {
        graphs.storageGenerationLine = {
          graph: (
            <React.Fragment>
              {eventLine}
              <StoreageLineGraph
                assets={this.props.projects}
                graphDatums={graphDatums}
                datums={projectDatums}
                range={range}
                aggregation={aggregation}
                selectRange={this.props.selectRange}
              />
            </React.Fragment>
          ),
          name: `${t("energy.graphNames.project_storage")} (kW)`,
        }
      }

      return (
        <GraphSelector
          selectedGraph={this.state.energyGraphView}
          selectGraph={this.selectGraph}
          graphs={graphs}
          range={this.props.range}
          selectRange={this.props.selectRange}
        />
      )
    } else {
      return <GraphSelector range={this.props.range} selectRange={this.props.selectRange} />
    }
  }
}
