import { Button } from "@aws-amplify/ui-react"
import * as React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Organization, useOrganization, useUpdateOrganization } from "src/services/organization"
import { toast } from "react-toastify"
import { formatData, formatForm } from "src/SuperAdmin/helpers"
import OrganizationFormFields, { OrganizationDataSharingFeilds, OrganizationAdvancedFeilds } from "src/SuperAdmin/OrganizationFormFields"
import { S3LogoHandler } from "src/SuperAdmin/S3LogoHandler"
import { Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap"
import { Table } from "ka-table"

interface Props {
  organizationId: string,
  projects: Project[]
}

export default function OrganizationSettingsForm(props: Props) {
  const { organizationId, projects} = props
  const { data: thisOrg, isLoading, isError } = useOrganization(organizationId)
  const { mutateAsync, isLoading: isUpdating } = useUpdateOrganization()
  const [submitting, setSubmitting] = React.useState(false)
  const [tabs, setTab] = React.useState("general")

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    reset,
    setValue,
  } = useForm<Organization>({
    defaultValues: {},
  })
  const watchEnableSharing = watch("dataSharing.enableSharing", "Do not share my data")

  React.useEffect(() => {
    if (organizationId === thisOrg?.id) {
      reset(formatData(thisOrg))
    }
  }, [thisOrg, organizationId])

  const onSubmit: SubmitHandler<Organization> = async (data) => {
    setSubmitting(true)
    const imageUrl = await S3LogoHandler(data.settings?.logoUrl)
    try {
      await mutateAsync(formatForm(data, imageUrl) as Organization)
      toast.success("Organization updated succesfully!")
      setSubmitting(false)
    } catch (e) {
      toast.error("Failed to update organization!")
      setSubmitting(false)
    }
  }


  if (isLoading) return <div>Loading...</div>
  if (isError) return <div>Error while fetching organization</div>

  function renderDataSharingTable() {
    if(!projects){return}
    return (
      <div className="table custom-theme-demo">
      <Table 
        data={projects}
        rowKeyField={"Project Code"}
        columns={[
          {
            key: "name",
            title: "Project Name",
            width: 250,  
          },
          {
            key: "code",
            title: "Project Code",
            width: 180,
          },
          {
            key: "Partners",
            title: "Partners",
            width: 180,
          },
          {
            key: "Earnings",
            title: "Earnings",
            width: 180,
          }
        ]}
      />
      </div>
    )
  }
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="inputs-theme" style={{ height: "86vh", overflowY: "auto" }}>
        <Nav tabs>
      <NavItem>
        <NavLink className={"general"} onClick={() => setTab("general")}>
          General
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink className={"Advanced"} onClick={() => setTab("Advanced")}>
          Advanced
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink className={"Data sharing"} onClick={() => setTab("Data sharing")}>
          Data sharing
        </NavLink>
      </NavItem>
    </Nav>
    <TabContent activeTab={tabs}>
      <TabPane tabId={"general"}>
        <OrganizationFormFields
          control={control}
          errors={errors}
          organization={thisOrg}
          reset={reset}
          setValue={setValue}
        />
      </TabPane>
      <TabPane tabId={"Advanced"}>
        <OrganizationAdvancedFeilds
          control={control}
          errors={errors}
          organization={thisOrg}
          reset={reset}
          setValue={setValue}
        />
      </TabPane>
      <TabPane tabId={"Data sharing"}>
         <OrganizationDataSharingFeilds
          control={control}
          errors={errors}
          organization={thisOrg}
          reset={reset}
          setValue={setValue}
        />
          {watchEnableSharing !== "Do not share my data" ? renderDataSharingTable() : null}
      </TabPane>
    </TabContent>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "16px", paddingRight: "12px" }}>
        <Button
          variation="primary"
          onClick={handleSubmit(onSubmit)}
          isLoading={submitting}
          loadingText="Updating organization..."
          isDisabled={!isDirty}
        >
          Update Organization
        </Button>
      </div>
    </form>
  )
}
