import React from "react"
import { Input } from "reactstrap"
import moment from "moment"

import { EcosuiteComponentError } from "@common/EcosuiteComponent"
import EcosuiteView from "@common/module/EcosuiteView"

import ProFormaService from "./ProFormaService"
import Logger from "@common/Logger"
import RecordService from "../record/RecordService"
import FinanceService from "../project/finance/FinanceService"
import { LAYOUT_HEADER } from "../../../../common/module/EcosuiteView"
import ProFormaForm from "./ProFormaForm"
import ProFormaIRR from "@dashboard/finance/views/pro-forma-inputs/ProFormaIRR"
import i18n from "src/i18n"

const { t } = i18n

export default class ProFormaView extends EcosuiteView {
  constructor(props) {
    super(props, "project-proForma")

    this.loadProForma = this.loadProForma.bind(this)
    this.loadVersions = this.loadVersions.bind(this)
    this.selectVersion = this.selectVersion.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadProForma()
    this.loadCategories()
    this.loadIRRCategories()
    this.loadRecords()
    this.loadProjectSettings()
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project) {
      this.loadProForma()
      this.loadCategories()
      this.loadIRRCategories()
      this.loadRecords()
      this.loadProjectSettings()
    }
  }

  getLayout() {
    return LAYOUT_HEADER
  }

  async loadProForma() {
    this.setStateIfMounted({ proForma: null })
    const projectId = this.props.project.code
    return ProFormaService.getProjectProForma(projectId)
      .then((proForma) => {
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({ proForma: proForma, originalProForma: proForma })
          this.loadVersions()
        } else {
          Logger.debug(`Ignoring out of date repsonse for project: ${projectId}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({ proForma: new EcosuiteComponentError(err) })
        }
      })
  }

  async loadVersions(selectedVersion) {
    this.setStateIfMounted({ versions: null, version: selectedVersion })
    const projectId = this.props.project.code
    return ProFormaService.getVersions(projectId)
      .then((versions) => {
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({ versions: versions, version: selectedVersion })
        } else {
          Logger.debug(`Ignoring out of date repsonse for project: ${projectId}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({ versions: new EcosuiteComponentError(err) })
        }
      })
  }

  async loadCategories() {
    const projectId = this.props.project.code
    if (projectId) {
      RecordService.getCategories(projectId)
        .then((response) => {
          if (this.isProjectCurrent(projectId)) {
            this.setStateIfMounted({ categories: response.categories })
          } else {
            Logger.debug(`Ignoring out of date repsonse for project: ${projectId}`)
          }
        })
        .catch((error) => {
          Logger.error(error)
          if (this.isProjectCurrent(projectId)) {
            this.setStateIfMounted({
              hasError: true,
              error: error,
            })
          }
        })
    } else {
      this.setStateIfMounted({ categories: undefined })
    }
  }

  async loadIRRCategories() {
    const projectId = this.props.project.code
    if (projectId) {
      FinanceService.getIRRCategories(projectId)
        .then((response) => {
          if (this.isProjectCurrent(projectId)) {
            this.setStateIfMounted({ IRRCategories: response })
          } else {
            Logger.debug(`Ignoring out of date repsonse for project: ${projectId}`)
          }
        })
        .catch((error) => {
          Logger.error(error)
          if (this.isProjectCurrent(projectId)) {
            this.setStateIfMounted({
              hasError: true,
              error: error,
            })
          }
        })
    } else {
      this.setStateIfMounted({ IRRCategories: undefined })
    }
  }

  async loadRecords() {
    const projectId = this.props.project.code
    if (projectId) {
      RecordService.getProjectRecords(projectId)
        .then((response) => {
          if (this.isProjectCurrent(projectId)) {
            this.setStateIfMounted({ records: response.records })
          } else {
            Logger.debug(`Ignoring out of date repsonse for project: ${projectId}`)
          }
        })
        .catch((error) => {
          Logger.error(error)
          if (this.isProjectCurrent(projectId)) {
            this.setStateIfMounted({
              hasError: true,
              error: error,
            })
          }
        })
    } else {
      this.setStateIfMounted({ records: undefined })
    }
  }

  loadProjectSettings() {
    this.setStateIfMounted({
      settings: null,
      error: null,
    })

    const projectId = this.props.project.code
    FinanceService.getProjectSettings(projectId)
      .then((settings) => {
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({
            settings: settings,
          })
        } else {
          Logger.debug(`Ignoring out of date repsonse for project: ${projectId}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({
            error: "Error while retrieving categories for project " + projectId,
          })
        }
      })
  }

  isReadOnly() {
    return super.isReadOnly()
  }

  selectVersion(versionId) {
    var version = null
    if (versionId && this.state.versions && this.state.versions.length) {
      version = this.state.versions.find((version) => version.id === versionId)
    }
    this.setStateIfMounted({ version: version })
  }

  getVersionDisplayName(version) {
    return `${version.id.split("/")[1]} (${moment(version.created).format("lll")})`
  }

  renderHeader() {
    return (
      <div className="title-bar">
        <div className="version-controls">
          {this.state.versions && this.state.versions.length ? (
            <Input
              className="version-controls"
              type="select"
              disabled={!this.state.versions || !this.state.versions.length}
              onChange={(e) => {
                this.selectVersion(e.target.value)
              }}
              value={this.state.version ? this.state.version.id : ""}
            >
              <option>{this.state.version ? `${t("labels.view_latest")}` : `${t("labels.view_version")}`}</option>
              {this.state.versions.map((version) => {
                return (
                  <option key={version.id} value={version.id}>
                    {this.getVersionDisplayName(version)}
                  </option>
                )
              })}
            </Input>
          ) : null}
        </div>
        <h2>{`${this.props.project.name} ${t("data.proforma.modelling_assumptions")}`} </h2>
      </div>
    )
  }

  renderInfo() {
    return ProFormaIRR.renderForecastGenerationInfo(this.props.project, this.state.version)
  }

  renderMainView() {
    return (
      <>
        <div className="pro-forma-alert">{this.renderInfo()}</div>
        <ProFormaForm
          project={this.props.project}
          proForma={this.state.version ? this.state.version : this.state.proForma}
          categories={this.state.categories}
          IRRCategories={this.state.IRRCategories}
          records={this.state.records}
          settings={this.state.settings}
          readOnly={this.isReadOnly()}
          loadProForma={this.loadProForma}
          loadVersions={this.loadVersions}
          originalProForma={this.state.originalProForma}
        />
      </>
    )
  }
}
