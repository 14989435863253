import React from "react"
import AddressLookup from "./AddressLookup"
import EcosuiteComponent from "@common/EcosuiteComponent"
import i18n from "src/i18n"

const { t } = i18n
var jsprim = require("jsprim")

class AddressLookupForEcosuiteForm extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.getAddress = this.getAddress.bind(this)
    this.gotAddress = this.gotAddress.bind(this)
    this.setLatLong = this.setAddress.bind(this)
  }

  setAddress(addressDetails) {
    const form = this.props.form
    const _formData = jsprim.deepCopy(this.props.formData)
    _formData.address = addressDetails.address
    _formData.town = addressDetails.town
    _formData.state = addressDetails.state
    _formData.lat = addressDetails.latitude
    _formData.long = addressDetails.longitude
    this.props.parent.setStateIfMounted({ formData: _formData, lat: _formData.lat, long: _formData.long })

    const newProps = jsprim.deepCopy(form.props)
    newProps.formData.address = _formData.address
    newProps.formData.town = _formData.town
    newProps.formData.state = _formData.state
    newProps.formData.lat = _formData.lat
    newProps.formData.long = _formData.long
  }

  gotAddress(addressDetails) {
    if (addressDetails) {
      this.setAddress(addressDetails)
      this.props.parent.setStateIfMounted({ error: undefined })
    } else {
      this.setAddress(null, null)
      this.props.parent.setStateIfMounted({ error: `${t("errors.Address not found")}` })
    }
  }

  getAddress() {
    return this.props?.formData?.address
  }

  renderContent() {
    return <AddressLookup formData={this.props.formData} getAddress={this.getAddress} gotAddress={this.gotAddress} disabled={this.props.disabled} />
  }
}

export default AddressLookupForEcosuiteForm
