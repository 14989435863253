import Icon from "@common/display/Icon"
import React, { useState } from "react"
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import i18n from "src/i18n"

const { t } = i18n
const AuditViewControls = ({
  // Props
  auditTypes,
  toggleAuditType,
}: {
  auditTypes: Record<string, boolean>
  toggleAuditType: (toggledAuditType: Record<string, boolean>) => void
}): JSX.Element => {
  const [isDropDownOpen, setDropDownOpen] = useState(false)

  return (
    <div className="content-view-controls">
      {/*   Dropdown Controller   */}
      <ButtonDropdown
        size="sm"
        isOpen={isDropDownOpen}
        toggle={(e: React.MouseEvent<HTMLElement>) => {
          if (e.currentTarget?.id === "audit-view-controls-type-toggle") {
            setDropDownOpen(!isDropDownOpen)
          } else {
            setDropDownOpen(e.currentTarget?.id?.includes("audit-type"))
          }
        }}
      >
        {/*   Dropdown Toggle Button   */}
        <DropdownToggle id="audit-view-controls-type-toggle">
          <span>{t("dropdown_menus.audit_types")}</span>
          <Icon icon={isDropDownOpen ? "expand_less" : "expand_more"} />
        </DropdownToggle>

        {/*   Dropdown Menu   */}
        <DropdownMenu>
          {/*   Dropdown Item to toggle all diff types   */}

          {/* Dropdown Item for each diff type   */}
          {Object.entries(auditTypes).map(([auditType, bool]) => {
            return (
              <DropdownItem
                id={`audit-type-${auditType}`}
                key={`audit-type-${auditType}`}
                className="audit-view-controls__dropdown-item"
                onClick={() => {
                  toggleAuditType({ [auditType]: !bool })
                }}
              >
                <span>{auditType}</span>
                <Icon icon={bool ? "check_box" : "check_box_outline_blank"} />
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  )
}

export default AuditViewControls
