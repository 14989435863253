import { useMutation, useQuery, useQueryClient } from "react-query"
import { del, get, post, put } from "../setup"
import { toast } from "react-toastify"

export interface Organization {
  id?: string
  name: string
  domain?: string
  slug: string
  created?: string
  updated?: string
  googleIntegration?: {
    serviceRequestTemplateId?: string
    projectMilestoneDatesId?: string
    checklistTemplateId?: string
    googleDrivePrivateKey?: string
    googleDriveClientEmail?: string
  }
  solarNetworkCredentials?: {
    host?: string
    sshHost?: string
    wssHost?: string
    secret?: string
    token?: string
  }
  settings?: {
    projectMilestoneRecipients?: string[]
    logoUrl?: string
    salesforceUrl?: string
    qgisUrl?: string
    isPublic?: boolean
    slackXbobToken?: string
  }
  softLedger: {
    clientId: string
    tenantUUID: string
    clientSecret: string
  }
  dataSharing: {
    enableSharing: string
  }
}

export const getOrganizationsApi = () => get<{ Items: Array<Organization> }>("/organizations")

export const createOrganizationApi = (
  body: Omit<Organization, "id"> & { email: string; firstName: string; lastName: string },
) => post<typeof body, Organization>("/organizations", body)

export const deleteOrganizationApi = (id: string) => del<void>(`/organizations/${id}`)

export const getOrganizationByIdApi = (id: string) => get<Organization>(`/organizations/${id}`)

export const updateOrganizationApi = (body: Organization) => put<Organization, Organization>("/organizations", body)

export const getOrganizationSchemaApi = () => get<object>("/schemas/organization")

export const getOrganizationUiSchemaApi = () => {
  return { id: { "ui:widget": "hidden", "ui:description": " " } }
}

export function useOrganizations() {
  return useQuery({
    queryKey: ["organizations"],
    queryFn: async () => {
      const response = await getOrganizationsApi()
      return response
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  })
}

export function useOrganization(id: string | undefined) {
  return useQuery({
    queryKey: [`organization/${id}`],
    queryFn: async () => {
      const response = await getOrganizationByIdApi(id as string)
      return response
    },
    
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: Boolean(id),
  })
}

export function useOrganizationSchema() {
  return useQuery({
    queryKey: ["organizationSchema"],
    queryFn: async () => {
      const response = await getOrganizationSchemaApi()
      return response
    },
  })
}

export function useCreateOrganization() {
  const client = useQueryClient()
  return useMutation(createOrganizationApi, {
    onSuccess: () => client.invalidateQueries(["organizations"]),
    onError: (e: Error) => {
      toast.error(e.message)
    },
  })
}

export function useUpdateOrganization() {
  const client = useQueryClient()
  return useMutation(updateOrganizationApi, {
    onSuccess: (data) => {
      client.invalidateQueries(["organizations"])
      client.invalidateQueries([`organization/${data.id}`])
    },
    onError: (e: Error) => {
      toast.error(e.message)
    },
  })
}

export function useDeleteOrganization() {
  const client = useQueryClient()
  return useMutation(deleteOrganizationApi, {
    onSuccess: () => client.invalidateQueries(["organizations"]),
  })
}
