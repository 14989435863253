import React, { Component } from "react"
import { Route } from "react-router-dom"

import { Warning } from "@common/EcosuiteComponent"

import Organizations from "./organizations/Organizations"
import SettingsAdmin from "./settings/SettingsAdmin"
import UserAdmin from "./users/UserAdmin"
import LimitedAdmin from "./users/LimitedAdmin"

import "./Admin.css"
import i18n from "src/i18n"
import Contacts from "./Contacts"
import CreateContact from "./Contacts/CreateContact"
import OrganizationSettings from "./organizations/OrganizationSettings"

const { t } = i18n
class Admin extends Component {
  constructor(props) {
    super(props)

    this.renderOrganizations = this.renderOrganizations.bind(this)
    this.renderUserAdmin = this.renderUserAdmin.bind(this)
    this.renderSettings = this.renderSettings.bind(this)
  }

  getTitle(title) {
    return title + " - Ecosuite"
  }

  render() {
    return (
      <React.Fragment>
        <Route exact path="/admin/settings" render={this.renderSettings} />
        <Route exact path="/admin/contacts" render={() => <Contacts />} />
        <Route exact path="/admin/contacts/create" render={() => <CreateContact />} />
        <Route
          exact
          path="/admin/contacts/update/:id"
          render={({ match }) => <CreateContact id={match?.params?.id} />}
        />
        <Route exact path="/admin/organizations" render={this.renderOrganizations} />
        <Route exact path="/admin/users" render={this.renderUserAdmin} />
        <Route path="/admin/users/:userId" render={this.renderUserAdmin} />
        <Route path="/admin/user-admin-limited" render={() => <LimitedAdmin username={this.props.username} />} />
        <Route
          path="/admin/organization-settings"
          render={() => <OrganizationSettings projects={this.props.projects} userId={this.props.currentUserId} />}
        />
      </React.Fragment>
    )
  }

  renderSettings() {
    if (this.props.groups.includes("solarnetwork") || this.props.groups.includes("data-write")) {
      document.title = this.getTitle("Settings")
      return <SettingsAdmin groups={this.props.groups} />
    } else {
      return <Warning title={`${t("labels.notice")}`} message={`${t("settings.messages.unauthorised_access")}`} />
    }
  }

  renderUserAdmin({ match }) {
    if (this.props.groups.includes("user-admin")) {
      document.title = this.getTitle("User Management")
      return (
        <UserAdmin
          selectedUserId={match.params.userId}
          groups={this.props.groups}
          projects={this.props.projects}
          portfolios={this.props.portfolios}
        />
      )
    } else {
      return <Warning title={`${t("labels.notice")}`} message={`${t("settings.messages.unauthorised_access")}`} />
    }
  }

  renderOrganizations({ match }) {
    if (this.props.groups.includes("user-admin")) {
      document.title = this.getTitle("Organization Management")
      return <Organizations selectedOrganizationId={match.params.organizationId} />
    } else {
      return <Warning title={`${t("labels.notice")}`} message={`${t("settings.messages.unauthorised_access")}`} />
    }
  }
}

export default Admin
