import React, { useState } from "react"
import { Loading } from "@common/EcosuiteComponent"
import "./Checklist.scss"
import { useCurrentEffect } from "@common/hooks/useCurrentEffect"
import {
  getProjectChecklistSchema,
  getCodesProjectChecklist,
} from "@dashboard/process/views/checklists/ChecklistService"
import { ChecklistForm } from "@dashboard/process/views/checklists/ChecklistForm"
import i18n from "src/i18n"

// TODO: MOVE
export interface StoredProjectChecklist {
  readonly id: string
  readonly codeType: string
  readonly version: string
  readonly parentId: string
  readonly checklistType: string
  readonly schema: Schema
}

// TODO: MOVE
export interface StoredProjectChecklistTemplate {
  readonly id: string
  readonly codeType: string
  readonly version: string
  readonly checklistType: string
  readonly schema: Schema
}

/**
 * The checklist tabs.
 */
export enum ChecklistTab {
  NTP = "NTP",
  PTO = "PTO",
  ICR = "ICR",
}

/**
 * Convert the tab to an index.
 * @param index - The index.
 */

const { t } = i18n
export const indexToTab = (index: number): ChecklistTab => Object.values(ChecklistTab)[index]

/**
 * A container for checklist data.
 */
export interface ChecklistDataContainer {
  ntpData?: unknown
  ptoData?: unknown
  icrData?: unknown
}

/**
 * A container for checklist schemas.
 */
export interface ChecklistSchemaContainer {
  ntpSchema?: Schema
  ptoSchema?: Schema
  icrSchema?: Schema
}

/**
 * The checklist view props.
 */
interface ChecklistViewProps {
  code: string
  state: string
}

/**
 * Check if a checklist is of a checklist type.
 * @param checklist - The checklist.
 * @param checklistType - The checklist type.
 */
const isChecklistOfChecklistType = (checklist: StoredProjectChecklist, checklistType: string): boolean => {
  return checklist.checklistType === checklistType
}

/**
 * Inject a checklist shcema with meta details.
 *
 * This allows for easier identification when editing checklists and reading them.
 * @param checklist - The checklist.
 * @param checklistTab - The checklist tab.
 */
const injectChecklistSchemaMetaDetails = (checklist: Schema, checklistTab: ChecklistTab): StoredProjectChecklist => {
  return {
    ...checklist,
    _checklistType: checklistTab,
  }
}

const ChecklistView = (props: ChecklistViewProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [tab, setTab] = useState<ChecklistTab>(ChecklistTab.NTP)
  const [checklistData, setChecklistData] = useState<ChecklistDataContainer>({})
  const [editedChecklistData, setEditedChecklistData] = useState<ChecklistDataContainer>({})
  const [checklistSchemas, setChecklistSchemas] = useState<ChecklistSchemaContainer>({})

  useCurrentEffect(
    (isCurrent) => {
      init(isCurrent)
    },
    [props.code, props.state],
  )

  async function init(isCurrent: () => boolean) {
    setIsLoading(true)

    await Promise.all([
      getCodesProjectChecklist(props.code),
      getProjectChecklistSchema(props.code, ChecklistTab.NTP),
      getProjectChecklistSchema(props.code, ChecklistTab.PTO),
      getProjectChecklistSchema(props.code, ChecklistTab.ICR),
    ]).then(([data, ntpSchema, ptoSchema, icrSchema]) => {
      if (isCurrent()) {
        // Get the current checklist data.
        const dataContainer = {
          ntpData: data.find((d) => isChecklistOfChecklistType(d, ChecklistTab.NTP))?.schema,
          ptoData: data.find((d) => isChecklistOfChecklistType(d, ChecklistTab.PTO))?.schema,
          icrData: data.find((d) => isChecklistOfChecklistType(d, ChecklistTab.ICR))?.schema,
        }
        setChecklistData(dataContainer)
        setEditedChecklistData(dataContainer)
        // Get all the available templates.
        setChecklistSchemas({
          ntpSchema: injectChecklistSchemaMetaDetails(ntpSchema, ChecklistTab.NTP),
          ptoSchema: injectChecklistSchemaMetaDetails(ptoSchema, ChecklistTab.PTO),
          icrSchema: injectChecklistSchemaMetaDetails(icrSchema, ChecklistTab.ICR),
        })
        setIsLoading(false)
      }
    })
  }

  /**
   * Refresh the checklist schemas.
   */
  async function refreshChecklistSchemas() {
    const [ntpSchema, ptoSchema, icrSchema] = await Promise.all([
      getProjectChecklistSchema(props.code, ChecklistTab.NTP),
      getProjectChecklistSchema(props.code, ChecklistTab.PTO),
      getProjectChecklistSchema(props.code, ChecklistTab.ICR),
    ])
    setChecklistSchemas({
      ntpSchema,
      ptoSchema,
      icrSchema,
    })
  }

  /**
   * Refresh the checklist data.
   */
  async function refreshChecklistData() {
    const data = await getCodesProjectChecklist(props.code)
    const dataContainer = {
      ntpData: data.find((d) => isChecklistOfChecklistType(d, ChecklistTab.NTP))?.schema,
      ptoData: data.find((d) => isChecklistOfChecklistType(d, ChecklistTab.PTO))?.schema,
      icrData: data.find((d) => isChecklistOfChecklistType(d, ChecklistTab.ICR))?.schema,
    }
    setChecklistData(dataContainer)
    setEditedChecklistData(dataContainer)
  }

  if (isLoading) {
    return <Loading message={t("process.messages.loading_checklist")} />
  }

  return (
    <div className="view-checklist">
      <ChecklistForm
        code={props.code}
        state={props.state}
        tab={tab}
        setTab={setTab}
        checklistSchemas={checklistSchemas}
        checklistData={checklistData}
        editedChecklistData={editedChecklistData}
        setEditedChecklistData={setEditedChecklistData}
        refreshChecklistSchemas={refreshChecklistSchemas}
        refreshChecklistData={refreshChecklistData}
      />
    </div>
  )
}

export default ChecklistView
