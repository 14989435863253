import React from "react"
import { ButtonToolbar, ButtonGroup, Button, NavLink } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import Moment from "moment"
import { extendMoment } from "moment-range"

import DateRangeUtils from "@common/utils/DateRangeUtils"

import CustomRange from "./CustomRange"
import EcosuiteComponent from "@common/EcosuiteComponent"
import Aggregations from "@common/Aggregations"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"
import { withRouter } from "react-router-dom"

const moment = extendMoment(Moment)
const { t } = i18n
class EcosuiteModuleHeader extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state = {
      dateRangeOpen: false,
      customRange: DateRangeUtils.getRange(this.props.rangeName, this.props.customRange, this.props.projects),
      isViewsOpen: false,
    }

    this.toggleDateRange = this.toggleDateRange.bind(this)
    this.toggleViews = this.toggleViews.bind(this)
    this.toggleProjectViews = this.toggleProjectViews.bind(this)

    this.selectRange = this.selectRange.bind(this)
    this.zoomOut = this.zoomOut.bind(this)
    this.updateCustomRange = this.updateCustomRange.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (!this.props.customRange.isSame(prevProps.customRange) || this.props.rangeName !== prevProps.rangeName) {
      this.setStateIfMounted({
        customRange: DateRangeUtils.getRange(this.props.rangeName, this.props.customRange, this.props.projects),
      })
    }
  }

  isShowViewSelector() {
    return this.props.project === undefined || this.props.project === null
  }

  isShowLayoutSelector() {
    return this.props.layouts && this.props.layouts.length > 0
  }

  toggleDateRange() {
    // if (this.state.dateRangeOpen && this.state.customRange) {
    //   // We are closing the date range so save the custom date range that was being entered
    //   this.selectRange("custom", this.state.customRange)
    // } else {
    this.setStateIfMounted({
      dateRangeOpen: !this.state.dateRangeOpen,
    })
    // }
  }

  selectRange(rangeName, customRange) {
    // When selecting a range we clear the customRange that may have been set
    this.setStateIfMounted(
      {
        customRange: null,
      },
      () => {
        // Whatever range has been explicitly selected is sent to the rest of the application
        this.props.actions.selectRange(rangeName, customRange)
      },
    )
  }

  updateCustomRange(range) {
    // We keep an internal record of the custom range as it's edited
    this.setStateIfMounted({ customRange: range })
  }

  toggleViews() {
    this.setStateIfMounted({
      isViewsOpen: !this.state.isViewsOpen,
    })
  }

  toggleProjectViews() {
    this.setStateIfMounted({
      isProjectViewsOpen: !this.state.isProjectViewsOpen,
    })
  }
  isModuleViewEnabled() {
    if (this.props.moduleView && this.props.moduleView.options) {
      return this.props.moduleView.options.view
    }
    return true // default to enabled
  }

  isProjectViewEnabled() {
    if (this.props.projectView && this.props.projectView.options) {
      return this.props.projectView.options.view
    }
    return this.isModuleViewEnabled()
  }

  isProductEnabled() {
    if (this.props.project && this.props.projectView && this.props.projectView.options) {
      return this.props.projectView.options.product
    } else if (this.props.moduleView && this.props.moduleView.options) {
      return this.props.moduleView.options.product
    } else {
      return true // default to enabled
    }
  }

  isDateRangeEnabled() {
    if (this.props.project && this.props.projectView && this.props.projectView.options) {
      return this.props.projectView.options.date
    } else if (this.props.moduleView && this.props.moduleView.options) {
      return this.props.moduleView.options.date
    } else {
      return true // default to enabled
    }
  }

  getModuleView() {
    if (this.props.moduleView) {
      return this.props.moduleView
    } else {
      return this.props.moduleViews[0] // handle a view being removed or not selected
    }
  }

  getProjectView() {
    if (this.props.projectView) {
      return this.props.projectView
    } else {
      return this.props.projectViews[0] // handle a view being removed or not selected
    }
  }

  renderContent() {
    return (
      <div>
        {this.renderViewSelector()}
        <ButtonToolbar className="float-end header-selector">
          {/* {this.renderProductSelector()} */}
          {this.renderDateSelector()}
          {this.renderDateNavigator()}
        </ButtonToolbar>

        <ButtonToolbar className="float-start header-selector">{this.renderProductSelector()}</ButtonToolbar>

        {this.renderInfo()}
      </div>
    )
  }

  renderInfo() {
    if (this.props.project) {
      if (this.props.project.name) {
        return (
          <>
            <div className="module-header-banner">{`${t("labels.project")}`}</div>
            <div className="module-header-title">{`${this.props.project.name} (${this.props.project.code})`}</div>
          </>
        )
      } else {
        return (
          <>
            <div className="module-header-banner">{`${t("labels.new_project")}`}</div>
            <div className="module-header-title">
              <a
                href="https://docs.ecosuite.io/user-guide/modules/data/project/new-project-guide"
                target="_blank"
                rel="noreferrer"
              >{`${t("labels.new_project_naming_convention")}`}</a>
              <Icon icon="open_in_new" />
            </div>
          </>
        )
      }
    } else if (this.props.projects.length) {
      return (
        <div>
          <div className="module-header-banner">{t("labels.portfolio")}</div>
          <div className="module-header-title">{this.props.portfolio ? this.props.portfolio.name : ""}</div>
        </div>
      )
    } else {
      return null
    }
  }

  renderViewSelector() {
    let selectedModuleView = this.getModuleView()

    if (this.props.project) {
      return this.renderProjectViewSelector()
    } else if (selectedModuleView && !this.props.project) {
      return this.renderModuleViewSelector()
    }
  }

  renderModuleViewSelector() {
    let selectedModuleView = this.getModuleView()

    let navWrap = ""
    const navElement = document.getElementById("moduleNav")
    if (navElement !== null) {
      if (navElement.clientHeight > 50 || navElement.className === "nav wrap app-mode nav-tab-pageView") {
        navWrap = "wrap"
      }
      if (
        navElement.clientWidth <= navElement.parentNode.parentElement.clientWidth - 875 &&
        navElement.clientHeight < 50
      ) {
        navWrap = ""
      }
    }

    return (
      <div style={{ display: "block" }}>
        <ul id="moduleNav" className={`nav ${navWrap} app-mode nav-tab-pageView`}>
          {this.props.moduleViews.map((moduleView) => {
            return (
              <li
                key={moduleView.id}
                className={"nav-item" + (selectedModuleView.id === moduleView.id ? " selected" : "")}
                id={`view__${moduleView.id}`}
              >
                <NavLink
                  className="active nav-link"
                  onClick={() => {
                    // Replace with the first view of the selected module
                    //
                    // E.g: /data/record/... to /data.
                    const firstPart = "/" + this.props.location.pathname.split("/")[1]
                    this.props.history.replace(firstPart)
                    this.props.actions.selectModuleView(moduleView.id)
                  }}
                >
                  {t(`views.${moduleView.name}`)}
                </NavLink>
                {moduleView.tooltip !== undefined ? (
                  <UncontrolledTooltip target={`view__${moduleView.id}`}>
                    {t(`viewTooltips.${moduleView.tooltip}`)}
                  </UncontrolledTooltip>
                ) : null}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  renderProjectViewSelector() {
    let selectedProjectView = this.getProjectView()

    let navWrap = ""
    const navElement = document.getElementById("nav")
    if (navElement !== null) {
      if (navElement.clientHeight > 50 || navElement.className === "nav wrap app-mode nav-tab-pageView") {
        navWrap = "wrap"
      }
      if (
        navElement.clientWidth <= navElement.parentNode.parentElement.clientWidth - 875 &&
        navElement.clientHeight < 50
      ) {
        navWrap = ""
      }
    }

    return (
      <div style={{ display: "block" }}>
        <ul id="nav" className={`nav ${navWrap} app-mode nav-tab-pageView`}>
          {this.props.projectViews.map((projectView) => {
            return (
              <li
                key={projectView.id}
                className={"nav-item" + (selectedProjectView.id === projectView.id ? " selected" : "")}
                id={`view__${projectView.id}`}
              >
                <NavLink
                  className="active nav-link"
                  onClick={() => {
                    // Replace with the first view of the selected module
                    //
                    // E.g: /data/record/... to /data.
                    const firstPart = "/" + this.props.location.pathname.split("/")[1]
                    this.props.history.replace(firstPart)
                    this.props.actions.selectProjectView(projectView.id)
                  }}
                >
                  {t(`views.${projectView.name}`)}
                  {projectView.tooltip !== undefined ? (
                    <UncontrolledTooltip target={`view__${projectView.id}`}>
                      {t(`viewTooltips.${projectView.tooltip}`)}
                    </UncontrolledTooltip>
                  ) : null}
                </NavLink>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  renderProductSelector() {
    return (
      <ButtonGroup className={`header-button-group ${this.isProductEnabled() ? "" : "header-disabled"}`}>
        {this.props.products.map((product) => {
          return (
            <Button
              key={product.id}
              className={this.props.selectedProductIds.indexOf(product.id) >= 0 ? "selected" : null}
              onClick={() => {
                this.props.actions.toggleProduct(product.id)
              }}
              title={t(`productNames.${product.name}`)}
              disabled={!this.isProductEnabled() || !product.enabled}
            >
              {t(`productNames.${product.name}`)}
            </Button>
          )
        })}
      </ButtonGroup>
    )
  }

  renderDateSelector() {
    // EP-710 If there a single project is selected we make sure that the range only covers that project
    const projects = this.props.project ? [this.props.project] : this.props.projects
    return (
      <ButtonGroup
        className={`header-button-group date-range-group ${this.isDateRangeEnabled() ? "" : "header-disabled"}`}
      >
        <Button
          className={this.props.rangeName === "last30Days" ? "selected" : null}
          onClick={() => {
            this.selectRange("last30Days")
          }}
          disabled={!this.isDateRangeEnabled()}
        >
          {t("dates.Last 30 Days")}
        </Button>
        <Button
          className={this.props.rangeName === "last7Days" ? "selected" : null}
          onClick={() => {
            this.selectRange("last7Days")
          }}
          disabled={!this.isDateRangeEnabled()}
        >
          {t("dates.Last 7 Days")}
        </Button>
        <CustomRange
          isOpen={this.state.dateRangeOpen}
          projects={projects}
          selectRange={this.selectRange}
          updateCustomRange={this.updateCustomRange}
          toggle={this.toggleDateRange}
          range={DateRangeUtils.getRange(this.props.rangeName, this.props.customRange, projects)}
          rangeName={this.props.rangeName}
          customRange={this.state.customRange}
          disabled={!this.isDateRangeEnabled()}
          showTime={this.props.showTime}
          end={true}
        />
      </ButtonGroup>
    )
  }

  renderDateNavigator() {
    const projects = this.props.project ? [this.props.project] : this.props.projects
    const range = DateRangeUtils.getRange(this.props.rangeName, this.props.customRange, projects)
    const exclusiveRange = DateRangeUtils.getExclusiveRange(range, true)
    var aggregation = Aggregations.Hour
    if (exclusiveRange.diff("years") > 0) {
      aggregation = Aggregations.Year
    } else if (exclusiveRange.diff("months") > 0) {
      aggregation = Aggregations.Month
    } else if (exclusiveRange.diff("days") > 0) {
      aggregation = Aggregations.Day
    }
    const aggregationName = aggregation.charAt(0).toUpperCase() + aggregation.slice(1)

    return (
      <ButtonGroup className={`header-button-group ${this.isDateRangeEnabled() ? "" : "header-disabled"}`}>
        <Button
          className="zoom-button"
          title={`${t("labels.previous_aggregation_name", { aggregationName: aggregationName })}`}
          onClick={() => {
            const start = moment(range.start).subtract(1, Aggregations.getUnit(aggregation))
            const end = moment(range.end).subtract(1, Aggregations.getUnit(aggregation))
            if (aggregation === Aggregations.Month && range.end.isSame(moment(range.end).endOf(Aggregations.Month))) {
              // If at the end of the month previously, make sure that we still are
              end.endOf(Aggregations.Month)
            }
            this.selectRange("custom", moment.range(start, end))
          }}
          disabled={!this.isDateRangeEnabled()}
        >
          <Icon icon="chevron_left" />
        </Button>
        <Button
          className="zoom-button"
          title={`${t("labels.zoom_out")}`}
          onClick={this.zoomOut}
          disabled={aggregation === Aggregations.Year || !this.isDateRangeEnabled()}
        >
          <Icon icon="expand_more" />
        </Button>
        <Button
          className="zoom-button"
          title={`${t("labels.next_aggregation_name", { aggregationName: aggregationName })}`}
          onClick={() => {
            const start = moment(range.start).add(1, Aggregations.getUnit(aggregation))
            const end = moment(range.end).add(1, Aggregations.getUnit(aggregation))
            if (aggregation === Aggregations.Month && range.end.isSame(moment(range.end).endOf(Aggregations.Month))) {
              // If at the end of the month previously, make sure that we still are
              end.endOf(Aggregations.Month)
            }
            this.selectRange("custom", moment.range(start, end))
          }}
          disabled={!this.isDateRangeEnabled()}
        >
          <Icon icon="chevron_right" />
        </Button>
      </ButtonGroup>
    )
  }

  zoomOut() {
    const projects = this.props.project ? [this.props.project] : this.props.projects
    const range = DateRangeUtils.getRange(this.props.rangeName, this.props.customRange, projects)
    const aggregation = DateRangeUtils.getAggregateForRange(range)

    switch (aggregation) {
      case "5minute":
      case "15minute":
        this.selectRange("custom", moment.range(moment(range.start).startOf("day"), moment(range.end).endOf("day")))
        break
      case "hour":
        this.selectRange("custom", moment.range(moment(range.start).startOf("month"), moment(range.end).endOf("month")))
        break
      case "day":
        this.selectRange("custom", moment.range(moment(range.start).startOf("year"), moment(range.end).endOf("year")))
        break
      default:
      // We don't zoom mode if we hit the upper zoom levels as there's nowhere to go
    }
  }
}

export default EcosuiteModuleHeader
export const EcosuiteModuleHeaderWithRouter = withRouter(EcosuiteModuleHeader)
