import React from "react"
import { Button, Alert, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap"

import EcosuiteForm, { FormError, EcosuiteObjectFieldTemplate, EcosuiteFieldTemplate, EcosuiteArrayFieldTemplate } from "@common/form/EcosuiteForm"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import Schemas from "@common/Schemas"
import Logger from "@common/Logger"
import EcosuiteNumberField from "@common/form/EcosuiteNumberField"
import AddressLookupForEcosuiteForm from "@common/form/AddressLookupForEcosuiteForm"
import ProjectUtils from "@common/utils/ProjectUtils"

import AssetInfo from "../AssetInfo"
import ProjectAdminService from "../ProjectAdminService"
import { extractPropertiesAndDependencies } from "@common/SchemaUtilities"
import TimezoneSelect from "@common/input/select/TimezoneSelect"
import ChangeNoteModal from "@common/form/ChangeNoteModal"
import ProjectExportDialog from "@common/project/ProjectExportDialog"
import i18n from "src/i18n"
import TimedMessage from "@common/display/TimedMessage"
import hideSchemaFields from "@common/form/HideSchemaFields"
import SiteDetailsContacts from "./SiteDetailsContacts"

const { t } = i18n

class SiteDetails extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.submitFormRef = React.createRef()

    this.createSite = this.createSite.bind(this)
    this.editSite = this.editSite.bind(this)
    this.deleteSite = this.deleteSite.bind(this)
    this.formCallback = this.formCallback.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
    this.addressLookup = this.addressLookup.bind(this)
  }

  confirmDelete(e) {
    this.setState({ showModal: !this.state.showModal })

    e.preventDefault()
  }

  formCallback(form) {
    this.form = form
  }

  componentDidMount() {
    super.componentDidMount()
    if (this.props.site) {
      Schemas.getSiteSchema().then((schema) => {
        this.setStateIfMounted({
          siteSchema: schema,
          formData: this.getFormData(this.props.site, schema),
        })
      })
    } else {
      Schemas.getSiteSchema().then((schema) => {
        this.setStateIfMounted({
          siteSchema: schema,
          formData: Object.assign({}, this.getFormData(this.props.site, schema), this.getNewFormDefaultValues(this.props.project)),
        })
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.site !== prevProps.site) {
      let formData = this.getFormData(this.props.site, this.state.siteSchema)
      this.setStateIfMounted({
        formData: formData,
        error: undefined,
        success: undefined,
      })
    }
  }

  /**
   * Function to get base default values for a new site form
   * @param {object} - project
   * @returns {object} - default values for the form
   */
  getNewFormDefaultValues(project) {
    return { name: project.name }
  }

  getFormData(site, schema) {
    const project = this.props.project
    if (site && schema) {
      const formData = extractPropertiesAndDependencies(schema, site)
      formData["updated"] = site["updated"]
      const updatedFormData = { ...formData, timezone: project.timezone, address: project.address }
      return updatedFormData
    }
  }

  createSite(form) {
    this.setStateIfMounted({
      error: undefined,
      loading: true,
      success: false,
      formData: form.formData,
    })
    ProjectAdminService.createSite(this.props.project.code, form)
      .then((data) => {
        this.setStateIfMounted({
          loading: false,
          formData: null,
        })
        this.props.siteAdded(data.site)
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, success: false, loading: false })
      })
  }

  editSite(form) {
    if (!this.state.note) {
      this.setStateIfMounted({ error: t("alertsInfo.alert_audit") })
      return
    }

    this.setStateIfMounted({
      error: undefined,
      loading: true,
      success: false,
      formData: form.formData,
    })
    ProjectAdminService.editSite(this.props.project.code, this.props.site.code, form, this.state.note)
      .then((data) => {
        this.setStateIfMounted({ loading: false, success: true, note: "" })
        this.props.siteChanged(data.site)
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, success: false, loading: false })
      })
  }

  deleteSite(e) {
    if (!this.state.note) {
      this.setStateIfMounted({ error: t("alertsInfo.alert_audit") })
      return
    }

    this.setStateIfMounted({ error: undefined, loading: true })
    ProjectAdminService.deleteSite(this.props.project.code, this.props.site.code, this.state.note)
      .then(() => {
        this.setStateIfMounted({ loading: false, showModal: false, deleted: true, note: "" })
        this.props.siteDeleted(this.props.site)
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, success: false, loading: false, showModal: false })
      })
    e.preventDefault()
  }

  addressLookup(props) {
    const form = this.form
    return (
      <div className="form-group field field-object">
        <Input
          type="text"
          name="address"
          className="form-group field field-string"
          value={props.value ? props.value : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
          required={props.required}
          onChange={(event) => {
            props.onChange(event.target.value)
          }}
          disabled={this.props.readonly}
        />
        <AddressLookupForEcosuiteForm form={form} formData={form?.state?.formData} parent={this} disabled={this.props.readonly} />
      </div>
    )
  }

  getUiSchema(formData) {
    return {
      "ui:order": [
        "code",
        "name",
        "productionStartDate",
        "generationStartDate",
        "consumptionStartDate",
        "storageStartDate",
        "address",
        "town",
        "state",
        "lat",
        "long",
        "parcelID",
        "parcelAcreage",
        "zoningDistrict",
        "ownerOfRecord",
        "utility",
        "emissionsApproximationMethod",
        "balancingAuthority",
        "co2EmissionsCoefficient",
        "*",
        "locationId",
        "plantId",
        "expectedDailyData",
      ],
      code: {
        "ui:disabled": this.props.site && this.props.site.code !== undefined,
      },
      address: { "ui:widget": "addressLookup" },
      timezone: {
        "ui:widget": (props) => {
          return (
            <TimezoneSelect
              value={props.value}
              onChange={(value) => {
                props.onChange(value)
              }}
              disabled={this.props.readonly}
            />
          )
        },
      },
      balancingAuthority: {
        "ui:description": <a href="https://www.eia.gov/electricity/gridmonitor/about">{t("data.site_details.balance_authority")}</a>,
      },
      contacts: {
        "ui:custom": () => (
          <div>
            <SiteDetailsContacts
              form={formData}
              handleContactsUpdate={(contactIds) => {
                this.setState({ formData: { ...formData, contactIds } })
              }}
            />
          </div>
        ),
      },

      pvwatts: {
        "ui:options": {
          label: false,
        },
      },
      links: {
        items: {
          "ui:options": {
            label: false,
          },
        },
      },
    }
  }

  /**
   Determines which fields of a schema should be hidden based on the user's available views.
   @param {string[]} moduleViews - ModuleViews object.
   @returns {string[]} - An array of field names to hide from the schema based on the user's permissions.
   */
  getSchemaFieldsToHideBasedOnPermission(permissions) {
    const ret = []
    if (!permissions?.includes("contacts")) {
      ret.push("contacts")
      ret.push("accessNotes")
    }
    return ret
  }

  /**
   * @param {(() => void) | undefined} callback - what to do after the state updates
   */
  toggleChangeNoteModal(callback) {
    this.setStateIfMounted({ isChangeNoteOpen: !this.state.isChangeNoteOpen }, callback)
  }

  toggleProjectExportDialog() {
    this.setStateIfMounted({ showExportDialog: !this.state.showExportDialog })
  }

  renderContent() {
    if (this.state.loading) {
      return <Loading />
    }
    if (this.state.deleted) {
      return <div>{t("messages.deleted")}</div>
    }

    if (this.state.siteSchema) {
      return (
        <div className="ecogy-form">
          <Row>
            <Col sm={9}>
              <EcosuiteForm
                idPrefix={"ecogy_site" + (this.props.site ? this.props.site.code : "")}
                schema={hideSchemaFields(this.getSchemaFieldsToHideBasedOnPermission(this.props.groups), this.state.siteSchema)}
                uiSchema={this.getUiSchema(this.state.formData)}
                formData={this.state.formData}
                onSubmit={this.props.site ? this.editSite : this.createSite}
                onChange={this.formDataChanged}
                ObjectFieldTemplate={EcosuiteObjectFieldTemplate}
                FieldTemplate={EcosuiteFieldTemplate}
                ArrayFieldTemplate={EcosuiteArrayFieldTemplate}
                formMounted={this.formCallback}
                disabled={this.props.readonly}
                fields={{ NumberField: EcosuiteNumberField }}
                widgets={{
                  addressLookup: this.addressLookup,
                }}
                formContext={{
                  readonly: this.props.readonly,
                  startDate: this.props.project ? ProjectUtils.getProjectStartDate([this.props.project]) : undefined,
                }}
              >
                <div className="ecogy-form-buttons row">
                  <Col className="message-section" sm="10">
                    {this.renderMessages()}
                  </Col>

                  {this.props.site ? (
                    <Col className="button-section">
                      <Button color="primary" onClick={() => this.toggleChangeNoteModal()} disabled={this.props.readonly}>
                        {t("buttons.submit")}
                      </Button>
                      <Button color="danger" onClick={this.confirmDelete} disabled={this.props.readonly}>
                        {t("buttons.delete")}
                      </Button>
                      <Button color="primary" onClick={() => this.toggleProjectExportDialog()}>
                        {t("buttons.export")}
                      </Button>
                    </Col>
                  ) : (
                    <Col className="button-section" sm="12">
                      <Button color="primary" type="submit" disabled={this.props.readonly}>
                        {t("buttons.create")}
                      </Button>
                    </Col>
                  )}
                </div>

                {/* react-jsonschema-form doesn't expose submit() or requestSubmit() inside of its ref */}
                {/* So a reference to this button is needed to submit from inside a modal / nested component */}
                <button ref={(ref) => (this.submitFormRef = ref)} style={{ display: "none" }} />
              </EcosuiteForm>
            </Col>
            <Col sm={3} className="project-side-panel">
              <div className="project-side-panel-content">
                <AssetInfo asset={this.props.site} predictedGeneration={this.props.predictedGeneration} />
              </div>
            </Col>
          </Row>

          {/* Modals */}
          {this.props.site ? this.renderModal() : null}
          <ChangeNoteModal
            isOpen={this.state.isChangeNoteOpen}
            toggle={() => this.toggleChangeNoteModal()}
            submit={() => this.toggleChangeNoteModal(() => this.submitFormRef.click())}
            canSubmit={!!this.state.note}
          >
            <Col className="audit-section">
              <Alert color="info">{t("alertsInfo.alert_audit")}</Alert>
              <Input type="textarea" value={this.state.note} onChange={this.saveNote} className="audit-note" />
            </Col>
          </ChangeNoteModal>
          <ProjectExportDialog
            project={this.props.project}
            showExportDialog={this.state.showExportDialog}
            setShowExportDialog={() => {
              this.toggleProjectExportDialog()
            }}
          />
        </div>
      )
    } else {
      return <Loading />
    }
  }

  renderMessages() {
    return (
      <React.Fragment>
        {this.state.success ? (
          <TimedMessage delay={8000}>
            <Alert color="info">{t("alertsInfo.site_updated")}</Alert>
          </TimedMessage>
        ) : null}
        <FormError
          error={this.state.error}
          toggle={() => {
            this.setStateIfMounted({ error: null })
          }}
        />
      </React.Fragment>
    )
  }

  renderModal() {
    const { t } = i18n
    return (
      <Modal isOpen={this.state.showModal} toggle={this.confirmDelete}>
        <ModalHeader toggle={this.confirmDelete}>{`Delete Site: ${this.props.site.code}?`}</ModalHeader>
        <ModalBody>
          <p>{t("data.confirmation_msg.confirm_delete_site", { siteCode: this.props.site.code, siteName: this.props.site.name })}</p>

          <div>
            {t("data.site_details.to_delete_site")}
            <ul>
              <li>{t("data.site_details.delete_site")}</li>
              <li>{t("data.site_details.delete_record")}</li>
              <li>{t("data.site_details.delete_events")}</li>
            </ul>
            {t("data.modal_body.storage_info_title")}
            <ul>
              <li>{t("data.financial_data")}</li>
              <li>{t("data.connectivity_data")}</li>
              <li>{t("data.energy_data")}</li>
            </ul>
            <Alert color="info">{t("alertsInfo.alert_audit")}</Alert>
            <Input type="textarea" value={this.state.note} onChange={this.saveNote} className="audit-note" />
            <br />
            <span>
              {t("data.modal_body.delete_alert")} <b style={{ color: "red" }}>{`Delete Site ${this.props.site.name}`}</b>
            </span>
            <Input value={this.state.deleteConfirmation} onChange={(e) => this.setState({ deleteConfirmation: e.target.value })} className="audit-note" />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.deleteSite} disabled={!this.state.note || this.state.deleteConfirmation !== `Delete Site ${this.props.site.name}`}>
            {t("labels.delete_site")}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              this.setStateIfMounted({ showModal: false })
            }}
          >
            {t("buttons.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default SiteDetails
