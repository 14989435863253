import React, { useEffect, useState } from "react"
import { Input } from "reactstrap"
import moment, { Moment } from "moment"

import ProjectionViewTable from "./ProjectionViewTable"
import "./ProjectionView.scss"

import type { TableDataKeyEnum, TableDataMap } from "./ProjectionView.d"
import type { MilestoneSchema, ProjectMilestones, ProjectTypeWithACDCSize } from "@dashboard/process/ProcessModule.d"

const TABLE_DATA_KEY_OPTIONS: {
  enum: TableDataKeyEnum[]
  enumNames: string[]
  enumUnits: string[]
} = {
  enum: ["dcSize", "projectCount"],
  enumNames: ["DC Size", "Project Count"],
  enumUnits: ["DC kW", "Project Count"],
}

/**
 *
 * Utility to create a Map with relevant project data
 * @returns Populated TableDataMap
 *
 */
function getTableData(projects: ProjectTypeWithACDCSize[], projectMilestones: ProjectMilestones[], quarters: string[]) {
  // Exit out when quarters is empty
  if (quarters.length <= 0) {
    return
  }

  // Initialize Table Data Map and populate with default values
  const tableData: TableDataMap = new Map()

  projectMilestones.map((stage) => {
    tableData.set(
      stage,
      quarters.reduce((map, quarter) => {
        return { ...map, [quarter]: { dcSize: 0, projectCount: 0, projects: [] } }
      }, {}),
    )
  })

  tableData.set(
    "Totals",
    quarters.reduce((map, quarter) => {
      return { ...map, [quarter]: { dcSize: 0, projectCount: 0, projectsEnum: [], projects: [] } }
    }, {}),
  )

  // Populate table data
  projects.map((project) => {
    Object.entries(project.milestones ?? {}).map(([stage, date]) => {
      // Type cast `stage` to get the keys of project.milestones
      const tableRow = stage as ProjectMilestones
      const tableColumn = `Q${moment(date).format("Q YYYY")}`

      // Get existing data from the table
      const tableRowData = tableData.get(tableRow) ?? {}
      const totalsRowData = tableData.get("Totals") ?? {}

      // Don't populate table data if the quarter is outside of the date range
      if (!tableRowData[tableColumn]) {
        return
      }

      // Update existing data
      tableData.set(tableRow, {
        ...tableRowData,
        [tableColumn]: {
          dcSize: tableRowData[tableColumn].dcSize + project.dcSize,
          projectCount: tableRowData[tableColumn].projectCount + 1,
          projects: [...(tableRowData[tableColumn].projects ?? []), project],
        },
      })

      // Deduping check to prevent `Totals` from containing the same project data multiple times
      // This happens when a project goes through multiple development stages in one quarter
      if (!totalsRowData[tableColumn].projectsEnum?.includes(project.code)) {
        // Update Totals with project data
        tableData.set("Totals", {
          ...totalsRowData,
          [tableColumn]: {
            dcSize: totalsRowData[tableColumn].dcSize + project.dcSize,
            projectCount: totalsRowData[tableColumn].projectCount + 1,
            projectsEnum: [...(totalsRowData[tableColumn].projectsEnum ?? []), project.code],
            projects: [...(totalsRowData[tableColumn].projects ?? []), project],
          },
        })
      }
    })
  })

  return tableData
}

/**
 *
 * Utility to convert passed schema into project milestones object
 * @return {
 *   enum: ProjectMilestones[],
 *   enumNames: string[]
 * }
 */
function initProjectMilestones(milestonesSchema: MilestoneSchema) {
  // enumNames as described in the ticket EP-2259
  // ["New Project", "Interconnection", "Permitting", "Construction", "PTO", "Operational"]
  // enumNames as it currently exists in the schema
  // ["Project Inititated", "Interconnection Started", "Permitting Started", "Construction Started", "PTO Started", "Project Operational"]
  const projectMilestones = {
    enum: [],
    enumNames: [],
  } as {
    enum: ProjectMilestones[]
    enumNames: string[]
  }

  Object.entries(milestonesSchema ?? {}).map(([value, { title }]) => {
    projectMilestones.enum.push(value as ProjectMilestones)
    projectMilestones.enumNames.push(title)
  })

  return projectMilestones
}

/**
 *
 * View Render
 *
 */
const ProjectionView = ({
  projects,
  milestonesSchema,
  initDateRange,
  dateRange,
}: {
  projects: ProjectTypeWithACDCSize[]
  milestonesSchema: MilestoneSchema
  initDateRange: (start: Moment, end: Moment) => void
  dateRange: {
    start: Moment
    end: Moment
  }
}): JSX.Element => {
  // pre render
  const [projectMilestones] = useState<{ enum: ProjectMilestones[]; enumNames: string[] }>(initProjectMilestones(milestonesSchema))

  // Table Data State
  const [tableHeaders, setTableHeaders] = useState<string[]>([])
  const [tableData, setTableData] = useState<TableDataMap>()
  const [tableDataKey, setTableDataKey] = useState<[TableDataKeyEnum, string]>(["dcSize", "DC kW"])

  // Set date range to two years when the component mounts
  useEffect(() => initDateRange(moment(), moment().add(2, "years")), [])

  // Set date range to two years when the projects change
  useEffect(() => initDateRange(moment(), moment().add(2, "years")), [JSON.stringify(projects)])

  // Populates Table with data when the date range or projects change
  useEffect(() => {
    // Generate list of quarters for the given dateRange
    const quarters: string[] = []
    for (const current = moment(dateRange.start); current.isBefore(dateRange.end); current.add(1, "quarter")) {
      quarters.push(`Q${current.format("Q YYYY")}`)
    }

    setTableHeaders(quarters)
    setTableData(getTableData(projects, projectMilestones.enum, quarters))
  }, [JSON.stringify(projects), dateRange.start.format("l"), dateRange.end.format("l")])

  return (
    <div className="content-with-controls">
      {/*   Table Component   */}
      <ProjectionViewTable tableHeaders={tableHeaders} tableData={tableData} tableDataKey={tableDataKey} tableRowNames={projectMilestones.enumNames} />

      {/*   View controls   */}
      <div className="content-view-controls">
        <Input
          className="projection-view-table-selector"
          name="select"
          type="select"
          defaultValue={0}
          onChange={(e) => {
            const idx = Number(e.target.value)
            setTableDataKey([TABLE_DATA_KEY_OPTIONS.enum[idx], TABLE_DATA_KEY_OPTIONS.enumUnits[idx]])
          }}
        >
          {TABLE_DATA_KEY_OPTIONS.enum.map((value, idx) => {
            return (
              <option key={value} value={idx}>
                {TABLE_DATA_KEY_OPTIONS.enumNames[idx]}
              </option>
            )
          })}
        </Input>
      </div>
    </div>
  )
}

export default ProjectionView
