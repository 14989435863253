import React from "react"
import moment from "moment"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import { Loading } from "@common/EcosuiteComponent"
import EcosuiteView from "@common/module/EcosuiteView"
import ProFormaService from "../../data/pro-forma/ProFormaService"
import ProjectUtils from "@common/utils/ProjectUtils"
import Schemas from "@common/Schemas"
import Settings from "@common/Settings"
import Utils from "@common/utils/Utils"
import { Tooltip } from "bootstrap"
import CalendarPopover from "./calendar/CalendarPopover"
import "@common/react-toggle.css"
import "./calendar/calendar.scss"
import { PRODUCT_CONSUMPTION, PRODUCT_GENERATION, PRODUCT_STORAGE } from "@common/module/EcosuiteModule"
import { Button, ButtonToolbar } from "reactstrap"
import i18n from "src/i18n"
import "./calendar/calendar.css"
import { isEmpty } from "lodash"

const { t } = i18n

const PRODUCT_MILESTONES = "milestones",
  PRODUCT_PAYMENTS = "payments"

const additionalProducts = [PRODUCT_MILESTONES, PRODUCT_PAYMENTS]

export default class CalendarView extends EcosuiteView {
  constructor(props) {
    super(props)
    this.eventMouseEnter = this.eventMouseEnter.bind(this)
    this.eventMouseLeave = this.eventMouseLeave.bind(this)
    this.getPaymentEvents = this.getPaymentEvents.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.setStateIfMounted({ additionalSelectedProductIds: Settings.getSetting("calendarAdditionalProducts", []) })
    Schemas.getRecordSchema().then((schema) => {
      const datePropertyTitles = {}
      populateDatePropertyTitles(datePropertyTitles, "record", schema, schema)
      this.setStateIfMounted({ recordSchema: schema, datePropertyTitles: datePropertyTitles })
    })
    Schemas.getProjectSchema().then((schema) => {
      this.setStateIfMounted({ projectSchema: schema })
      this.setStateIfMounted({ events: this.getEvents() })
    })
    Schemas.getProFormaSchema().then((schema) => {
      this.setStateIfMounted({ proFormaSchema: schema })
      this.setStateIfMounted({ events: this.getEvents() })
    })

    this.loadEvents()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.project !== prevProps.project ||
      JSON.stringify(this.props.projects.map((p) => p.code)) !== JSON.stringify(prevProps.projects.map((p) => p.code))
    ) {
      this.loadEvents()
    } else if (
      this.props.records !== prevProps.records ||
      this.props.selectedProductIds !== prevProps.selectedProductIds
    ) {
      this.setStateIfMounted({ events: this.getEvents() })
    }
  }

  loadEvents() {
    this.state.proFormas = null
    if (this.props.project) {
      // If a project is selected, load the pro forma and events for the selected project
      const projectId = this.props.project.code
      ProFormaService.getProjectProForma(projectId).then((proForma) => {
        if (this.isProjectCurrent(projectId) && !isEmpty(proForma)) {
          this.setStateIfMounted({ proFormas: [proForma] }, () => {
            this.setStateIfMounted({ events: this.getEvents() })
          })
        }
      })
    } else {
      const projectsCodes = this.props.projects.map((project) => project.code)
      ProFormaService.getProFormas().then((proFormas) => {
        if (this.areProjectsCurrent(projectsCodes)) {
          const filteredProFormas = proFormas?.filter((proforma) =>
            projectsCodes.includes(proforma.id.replace("proforma-", "")),
          )
          this.setStateIfMounted({ proFormas: filteredProFormas }, () => {
            this.setStateIfMounted({ events: this.getEvents() })
          })
        }
      })

      this.setStateIfMounted({ proForma: null }, () => {
        this.setStateIfMounted({ events: this.getEvents() })
      })
    }
  }

  renderMainView() {
    if (!this.props.records) {
      return (
        <div className="processes-calendar">
          <Loading />
        </div>
      )
    } else {
      return (
        <div className="processes-calendar">
          <FullCalendar
            height="parent"
            initialView="dayGridMonth"
            plugins={[dayGridPlugin]}
            weekends={true}
            events={this.state.events}
            eventMouseEnter={this.eventMouseEnter}
            eventMouseLeave={this.eventMouseLeave}
            eventContent={(info) => {
              return <div id={info.event.id}>{info.event.title}</div>
            }}
            eventDidMount={(info) => {
              new Tooltip(info.el, {
                title: info.event.extendedProps.description ? info.event.extendedProps.description : "",
                placement: "top",
                trigger: "hover",
                container: "body",
              })
            }}
            dayMaxEventRows={false}
          />
          <CalendarPopover event={this.state.event} />
          {this.renderAdditionalProductSelector()}
        </div>
      )
    }
  }

  renderAdditionalProductSelector() {
    return (
      <ButtonToolbar className="bottomToolbar">
        {additionalProducts.map((product) => (
          <Button
            id={product}
            key={product}
            onClick={() => this.toggleAdditionalProduct(product)}
            className={
              this.state.additionalSelectedProductIds && this.state.additionalSelectedProductIds.indexOf(product) >= 0
                ? "selected"
                : null
            }
          >
            {t("calendar.labels." + product)}
          </Button>
        ))}
      </ButtonToolbar>
    )
  }

  toggleAdditionalProduct(productId) {
    var additionalSelectedProductIds = [...this.state.additionalSelectedProductIds]
    if (additionalSelectedProductIds.indexOf(productId) >= 0) {
      // Remove the product
      additionalSelectedProductIds = additionalSelectedProductIds.filter(
        (selectedProductId) => selectedProductId !== productId,
      )
    } else {
      // Add the product
      additionalSelectedProductIds = additionalSelectedProductIds.concat([productId])
    }
    Settings.setSetting("calendarAdditionalProducts", additionalSelectedProductIds)
    this.setStateIfMounted({ additionalSelectedProductIds: additionalSelectedProductIds }, () => {
      this.setStateIfMounted({ events: this.getEvents() })
    })
  }

  eventMouseEnter(info) {
    if (info.event.id && (!this.state.event || this.state.event.id !== info.event.id)) {
      this.setStateIfMounted({
        event: info.event,
      })
    }
  }

  eventMouseLeave() {
    this.setStateIfMounted({ event: null })
  }

  getRecords() {
    if (this.props.records) {
      if (this.props.project) {
        return this.props.records.filter((record) => record.path.startsWith(ProjectUtils.getPath(this.props.project)))
      } else {
        return this.props.records
      }
    } else {
      return []
    }
  }

  getFrequencyMonths(frequency) {
    switch (frequency) {
      case "monthly":
        return 1
      case "quarterly":
        return 3
      case "semi-annually":
        return 6
      case "annually":
        return 12
      default:
        throw "unrecognized frequency " + frequency
    }
  }

  getProject(projectId) {
    return this.props.projects.find((project) => project.code === projectId)
  }

  formatNumber(num) {
    if (Math.abs(num) < 1000) {
      return Utils.formatNumber(num, { maximumFractionDigits: 2 })
    } else {
      return Utils.formatNumber(num / 1000, { maximumFractionDigits: 1 }) + "k"
    }
  }

  truncateProjectName(name) {
    if (name.length > 20) {
      let words = name.split(" ")
      if (words.length >= 2) {
        return words[0] + "..." + words[words.length - 1]
      }
    }
    return name
  }

  getPaymentEvents() {
    var events = []
    if (this.state.proFormas && this.state.proFormaSchema) {
      this.state.proFormas.forEach((proForma) => {
        const project = this.getProject(proForma.id.replace("proforma-", ""))
        proForma?.cashFlows?.forEach((cashFlow) => {
          cashFlow.payments?.forEach((payment) => {
            if (payment.paymentType === "scheduled") {
              payment.payments?.forEach((singlePayment) => {
                const title =
                  (!this.props.project && project ? this.truncateProjectName(project.name) : "") +
                  " " +
                  (payment.subAccount ? payment.subAccount : "")
                const description =
                  (!this.props.project && project ? project.name : "") +
                  " " +
                  (payment.subAccount ? payment.subAccount : "") +
                  ": " +
                  t("calendar.messages.payment_due") +
                  " " +
                  Utils.formatCurrency(singlePayment.amountDue) +
                  " " +
                  (singlePayment.notes ? singlePayment.notes : "")
                events.push({
                  title:
                    title.substring(0, 28) +
                    " " +
                    t("calendar.messages.payment_due") +
                    " " +
                    t("calendar.messages.currency_symbol") +
                    this.formatNumber(singlePayment.amountDue),
                  description: description,
                  date: singlePayment.dueDate,
                })
              })
            } else if (payment.paymentType === "recurring") {
              const frequencyMonths = this.getFrequencyMonths(payment.recurrence.frequency)
              var date = moment(payment.recurrence.startDate)
              const endDate = moment(date).add(payment.recurrence.term, "year")
              var isStart = true
              while (!date.isAfter(endDate)) {
                const amount = this.getAmount(payment, date)
                if (!isStart || payment.billedAtStart) {
                  const title =
                    (!this.props.project && project ? this.truncateProjectName(project.name) : "") + " " + cashFlow.name
                  events.push({
                    title:
                      title.substring(0, 28) +
                      " " +
                      t("calendar.messages.payment_due") +
                      " " +
                      (amount ? t("calendar.messages.currency_symbol") + this.formatNumber(amount) : ""),
                    description:
                      (!this.props.project && project ? project.name : "") +
                      " " +
                      cashFlow.name +
                      " " +
                      t("calendar.messages.payment_due") +
                      " " +
                      (amount ? Utils.formatCurrency(amount) : "") +
                      (payment.subAccount ? payment.subAccount : "") +
                      " " +
                      (payment.recurrence.paymentInstructions ? payment.recurrence.paymentInstructions : ""),
                    date: date.format("YYYY-MM-DD"),
                  })
                }
                isStart = false
                date = date.add(frequencyMonths, "month")
              }
            }
          })
        })
      })
    }

    return events
  }

  getAmount(payment, date) {
    if (payment.recurrence.rateType !== "fixed") {
      return undefined
    }
    const yearsSinceStart = date.diff(payment.recurrence.startDate, "years")
    let rate =
      payment.recurrence.startRate *
      (1 + (payment.recurrence.escalator ? payment.recurrence.escalator * 0.01 : 0)) ** yearsSinceStart
    rate = payment.recurrence.ceilingRate ? Math.min(payment.recurrence.ceilingRate, rate) : rate
    rate = payment.recurrence.floorRate ? Math.max(payment.recurrence.floorRate, rate) : rate
    return rate
  }

  getEvents() {
    var events = []
    var paymentEvents =
      this.state.additionalSelectedProductIds.indexOf(PRODUCT_PAYMENTS) >= 0 ? this.getPaymentEvents() : []
    if (this.props.project) {
      events = this.getProjectEvents(this.props.project)
    } else {
      this.props.projects.forEach((project) => {
        const projectEvents = this.getProjectEvents(project)
        events = events.concat(projectEvents)
      })
    }
    if (this.props.records && this.state.datePropertyTitles) {
      // We add events for the dates we find on the records
      this.getRecords().forEach((record) => {
        events = events.concat(getEventsForObject(this.state.datePropertyTitles, "record", record, record))
      })
    }

    return events.concat(paymentEvents)
  }

  getProjectEvents(project) {
    var events = []
    if (this.state.projectSchema && project) {
      const projectPrefix = this.props.project ? "" : project.name + ": "
      if (project.startDate) {
        events.push({
          title: this.state.projectSchema.properties.startDate.title,
          description: projectPrefix + this.state.projectSchema.properties.startDate.description,
          date: project.startDate,
        })
      }
      if (project.productionStartDate && this.state.projectSchema.properties.productionStartDate) {
        events.push({
          title: projectPrefix + this.state.projectSchema.properties.productionStartDate.title,
          description: this.state.projectSchema.properties.productionStartDate.description,
          date: project.productionStartDate,
        })
      }
      if (project.reportStartDate && this.state.projectSchema.properties.reportStartDate) {
        events.push({
          title: projectPrefix + this.state.projectSchema.properties.reportStartDate.title,
          description: this.state.projectSchema.properties.reportStartDate.description,
          date: project.reportStartDate,
        })
      }
      if (this.props.selectedProductIds.indexOf(PRODUCT_GENERATION) >= 0 && project.generationStartDate) {
        events.push({
          title: projectPrefix + this.state.projectSchema.properties.generationStartDate.title,
          description: this.state.projectSchema.properties.generationStartDate.description,
          date: project.generationStartDate,
        })
      }
      if (this.props.selectedProductIds.indexOf(PRODUCT_CONSUMPTION) >= 0 && project.consumptionStartDate) {
        events.push({
          title: projectPrefix + this.state.projectSchema.properties.consumptionStartDate.title,
          description: this.state.projectSchema.properties.consumptionStartDate.description,
          date: project.consumptionStartDate,
        })
      }
      if (this.props.selectedProductIds.indexOf(PRODUCT_STORAGE) >= 0 && project.storageStartDate) {
        events.push({
          title: projectPrefix + this.state.projectSchema.properties.storageStartDate.title,
          description: this.state.projectSchema.properties.storageStartDate.description,
          date: project.storageStartDate,
        })
      }
      if (this.state.additionalSelectedProductIds.indexOf(PRODUCT_MILESTONES) >= 0 && project.milestones) {
        events = events.concat(
          Object.entries(project.milestones).map((milestone) => {
            return {
              title: projectPrefix + this.state.projectSchema.properties.milestones.properties[milestone[0]].title,
              description: "",
              date: milestone[1],
            }
          }),
        )
      }
    }

    return events
  }
}

const getEventsForObject = (datePropertyTitles, propertyPath, record, object) => {
  var events = []
  Object.keys(object).forEach((propertyName) => {
    // Currently ignoring the payments definitions as we don't want them on the calendar
    if (propertyName !== "payments") {
      if (typeof object[propertyName] === "string") {
        const propertyNamePath = propertyPath + "-" + propertyName
        const dateTitle = datePropertyTitles[propertyNamePath]
        if (dateTitle) {
          const date = object[propertyName]
          events.push({
            title: `${record.path}: ${dateTitle}`,
            rawDate: date,
            date: moment(date).format("YYYY-MM-DD"),
            id: `${record.id}-${propertyNamePath}`,
            record: record,
          })
        }
      } else if (typeof object[propertyName] === "object") {
        const propertyNamePath = propertyPath + "-" + propertyName
        if (object[propertyName]) {
          if (Array.isArray(object[propertyName])) {
            object[propertyName].forEach((subProperty) => {
              if (typeof subProperty === "object") {
                events = events.concat(getEventsForObject(datePropertyTitles, propertyNamePath, record, subProperty))
              }
            })
          } else {
            events = events.concat(
              getEventsForObject(datePropertyTitles, propertyNamePath, record, object[propertyName]),
            )
          }
        }
      }
    }
  })
  return events
}

var populateDatePropertyTitles = function (datePropertyTitles, path, schema, object, dependencyKey) {
  Object.keys(object.properties).forEach((propertyName) => {
    if (propertyName !== dependencyKey) {
      let property = object.properties[propertyName]

      if (property.$ref) {
        let definitionPath = property.$ref.split("#/definitions/")[1]
        let definition = schema.definitions[definitionPath]
        object.properties[propertyName] = definition
      } else if (property.type === "array" && property.items && property.items.$ref) {
        let definitionPath = property.items.$ref.split("#/definitions/")[1]
        let definition = schema.definitions[definitionPath]
        object.properties[propertyName] = definition
      }

      if (property.format === "date") {
        datePropertyTitles[path + "-" + propertyName] = property.title
      }
    }
  })

  Object.keys(object.properties).forEach((propertyName) => {
    let property = object.properties[propertyName]
    if (property.type === "object") {
      populateDatePropertyTitles(datePropertyTitles, `${path}.${propertyName}`, schema, property, dependencyKey)
    } else if (property.type === "array" && property.items && property.items.type === "object") {
      populateDatePropertyTitles(datePropertyTitles, `${path}.${propertyName}`, schema, property.items, dependencyKey)
    }
  })

  if (object.dependencies && Object.keys(object.dependencies).length > 0) {
    Object.keys(object.dependencies).forEach((dependencyKey) => {
      // let dependencyValue = object.dependencies[dependencyKey].properties[dependencyKey].enum[0]
      object.dependencies[dependencyKey].oneOf.forEach((dependency) => {
        populateDatePropertyTitles(datePropertyTitles, path, schema, dependency, dependencyKey)
      })
    })
  }
}
