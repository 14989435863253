import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"

import { Loading, Warning } from "@common/EcosuiteComponent"

import EventModule from "./module/event/EventModule"
import EnergyModule from "./module/energy/EnergyModule"
import ConnectivityModule from "./module/connectivity/ConnectivityModule"
import FinanceModule from "./module/finance/FinanceModule"
import AssetModule from "./module/data/DataModule"
import ProcessModule from "./module/process/ProcessModule"
import DRModule from "./module/demandresponse/DRModule"
import { PRODUCT_CONSUMPTION, PRODUCT_GENERATION, PRODUCT_STORAGE } from "@common/module/EcosuiteModule"
import { t } from "i18next"

export default class Content extends Component {
  constructor(props) {
    super(props)
    this.renderEventModule = this.renderEventModule.bind(this)
    this.renderEnergyModule = this.renderEnergyModule.bind(this)
    this.renderDRModule = this.renderDRModule.bind(this)
    this.renderConnectivityModule = this.renderConnectivityModule.bind(this)
    this.renderFinanceModule = this.renderFinanceModule.bind(this)
    this.renderAssetsModule = this.renderAssetsModule.bind(this)
    this.renderProcessesModule = this.renderProcessesModule.bind(this)
    this.renderDataRecord = this.renderDataRecord.bind(this)
    this.renderAnyAssets = this.renderAnyAssets.bind(this)
  }

  renderEventModule() {
    return (
      <EventModule
        portfolio={this.props.portfolio}
        projects={this.props.project ? this.props.filteredProjects : this.props.selectedProjects}
        project={this.props.project}
        loadTime={this.props.loadTime}
        restrictions={this.props.restrictions}
        groups={this.props.groups}
        actions={this.props.actions}
        userId={this.props.userId}
        moduleViews={this.props.moduleViews["event"]}
        username={this.props.username}
      />
    )
  }

  renderEnergyModule() {
    return (
      <EnergyModule
        portfolio={this.props.portfolio}
        projects={this.props.project ? this.props.filteredProjects : this.props.selectedProjects}
        filteredProjects={this.props.filteredProjects}
        project={this.props.project}
        loadTime={this.props.loadTime}
        restrictions={this.props.restrictions}
        groups={this.props.groups}
        actions={this.props.actions}
        userId={this.props.userId}
        moduleViews={this.props.moduleViews["energy"]}
      />
    )
  }

  renderDRModule() {
    return (
      <DRModule
        portfolio={this.props.portfolio}
        projects={this.props.project ? this.props.filteredProjects : this.props.selectedProjects}
        allProjects={this.props.allProjects}
        project={this.props.project}
        loadTime={this.props.loadTime}
        restrictions={this.props.restrictions}
        groups={this.props.groups}
        actions={this.props.actions}
        userId={this.props.userId}
        moduleViews={this.props.moduleViews["demandresponse"]}
      />
    )
  }

  renderConnectivityModule() {
    return (
      <ConnectivityModule
        portfolio={this.props.portfolio}
        projects={this.props.project ? this.props.filteredProjects : this.props.selectedProjects}
        project={this.props.project}
        loadTime={this.props.loadTime}
        restrictions={this.props.restrictions}
        groups={this.props.groups}
        actions={this.props.actions}
        moduleViews={this.props.moduleViews["connectivity"]}
      />
    )
  }

  renderFinanceModule() {
    return (
      <FinanceModule
        portfolio={this.props.portfolio}
        projects={this.props.project ? this.props.filteredProjects : this.props.selectedProjects}
        project={this.props.project}
        loadTime={this.props.loadTime}
        restrictions={this.props.restrictions}
        groups={this.props.groups}
        actions={this.props.actions}
        moduleViews={this.props.moduleViews["finance"]}
      />
    )
  }

  renderDataRecord(props) {
    return (
      <AssetModule
        portfolio={this.props.portfolio}
        autoCompletes={this.props.autoCompletes}
        allProjects={this.props.allProjects}
        projects={this.props.project ? this.props.filteredProjects : this.props.selectedProjects}
        project={this.props.project}
        loadTime={this.props.loadTime}
        restrictions={this.props.restrictions}
        groups={this.props.groups}
        actions={this.props.actions}
        userId={this.props.userId}
        userName={this.props.username}
        moduleViews={this.props.moduleViews["data"]}
        record={props.match.params.data}
      />
    )
  }

  renderAnyAssets() {
    return (
      <AssetModule
        portfolio={this.props.portfolio}
        autoCompletes={this.props.autoCompletes}
        allProjects={this.props.allProjects}
        projects={this.props.project ? this.props.filteredProjects : this.props.selectedProjects}
        project={this.props.project}
        loadTime={this.props.loadTime}
        restrictions={this.props.restrictions}
        groups={this.props.groups}
        actions={this.props.actions}
        userId={this.props.userId}
        userName={this.props.username}
        moduleViews={this.props.moduleViews["data"]}
      />
    )
  }

  renderAssetsModule() {
    return (
      <Switch>
        <Route path="/data/record/:data" render={this.renderDataRecord} />
        <Route path="*" render={this.renderAnyAssets} />
      </Switch>
    )
  }

  getProcessProducts() {
    let products = [
      {
        id: PRODUCT_GENERATION,
        code: "GEN",
        name: "Generation",
        enabled: this.props.groups && this.props.groups.includes(PRODUCT_GENERATION),
      },
      {
        id: PRODUCT_CONSUMPTION,
        code: "CON",
        name: "Consumption",
        enabled: this.props.groups && this.props.groups.includes(PRODUCT_CONSUMPTION),
      },
      {
        id: PRODUCT_STORAGE,
        code: "STO",
        name: "Storage",
        enabled: this.props.groups && this.props.groups.includes(PRODUCT_STORAGE),
      },
    ]
    return products
  }

  renderProcessesModule() {
    return (
      <ProcessModule
        portfolio={this.props.portfolio}
        projects={this.props.project ? this.props.filteredProjects : this.props.selectedProjects}
        project={this.props.project}
        loadTime={this.props.loadTime}
        restrictions={this.props.restrictions}
        products={this.getProcessProducts()}
        groups={this.props.groups}
        actions={this.props.actions}
        userId={this.props.userId}
        moduleViews={this.props.moduleViews["process"]}
      />
    )
  }

  renderNotFoundRoute() {
    return <Warning title={t("labels.notice")} message={t("errors.unauthorised_access")} />
  }

  render() {
    if (this.props.moduleViews) {
      return (
        <div className={`App-module${!this.props.project && this.props.portfoliosEnabled ? " App-portfolio" : ""}`}>
          <Switch>
            {this.props.groups.includes("event") ? <Route path="/events" render={this.renderEventModule} /> : null}
            {this.props.groups.includes("energy") ? <Route path="/energy" render={this.renderEnergyModule} /> : null}
            {this.props.groups.includes("demandresponse") ? (
              <Route path="/demandresponse" render={this.renderDRModule} />
            ) : null}
            {this.props.groups.includes("connectivity") ? (
              <Route path="/connectivity" component={this.renderConnectivityModule} />
            ) : null}
            {this.props.groups.includes("finance") ? (
              <Route path="/economics" render={this.renderFinanceModule} />
            ) : null}
            {this.props.groups.includes("legal") ? <Route path="/data" render={this.renderAssetsModule} /> : null}
            {this.props.groups.includes("processes") ? (
              <Route path="/forge" render={this.renderProcessesModule} />
            ) : null}
            <Route path="*" render={this.renderNotFoundRoute} />
          </Switch>
        </div>
      )
    } else {
      return <Loading />
    }
  }
}
