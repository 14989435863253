import React from "react"
import ReactDOM from "react-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import config from "../package.json"
import App from "./App"
import NiceModal from "@ebay/nice-modal-react"
import { I18nextProvider } from "react-i18next"
import i18n from "./i18n"
import { QueryClient, QueryClientProvider } from "react-query"
import SuAdmin from "./SuperAdmin/SuAdmin"
import AuthAmplifySchema from "./login/AuthAmplifySchema"
import { Authenticator, ThemeProvider } from "@aws-amplify/ui-react"
import { Auth } from "aws-amplify"
import SuAdminOrganizationDetails from "./SuperAdmin/SuAdminOrganizationDetails"
import SuCreateOrganization from "./SuperAdmin/SuCreateOrganization"
import SuOrganizationList from "./SuperAdmin/SuOrganizationList"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./index.css"

const queryClient = new QueryClient()

export const theme = {
  name: "my-theme",
  tokens: {
    colors: {
      background: {
        primary: { value: "#1c3841" },
      },
      font: {
        primary: { value: "black" },
        secondary: { value: "white" },
        error: { value: "#EE4B2B" },
      },
      border: {
        primary: { value: "white" },
        focus: { value: "white" },
        error: { value: "#EE4B2B" },
      },
    },
    components: {
      selectfield: {
        color: { value: "white" },
      },
    },
  },
}

ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <NiceModal.Provider>
          <Switch>
            <Route exact path="/su-admin" render={() => <SuAdmin />} />
            <Route
              exact
              path="/su-admin/organizations"
              render={() => (
                <ThemeProvider theme={theme}>
                  <SuOrganizationList />
                </ThemeProvider>
              )}
            />
            <Route
              exact
              path="/su-admin/organization/create"
              render={() => (
                <ThemeProvider theme={theme}>
                  <SuCreateOrganization />
                </ThemeProvider>
              )}
            />
            <Route
              exact
              path="/su-admin/organization/:id"
              render={({ match }) => (
                <ThemeProvider theme={theme}>
                  <SuAdminOrganizationDetails id={match.params.id} />
                </ThemeProvider>
              )}
            />
            <Route
              path="/*"
              render={() => (
                <Authenticator
                  services={{
                    handleSignIn({ username, password }) {
                      return Auth.signIn(username.toLowerCase(), password)
                    },
                    handleSignUp(formData) {
                      return Auth.signUp({
                        ...formData,
                        username: formData.username.toLowerCase(),
                      })
                    },
                  }}
                  components={AuthAmplifySchema}
                  formFields={{
                    confirmResetPassword: {
                      confirm_password: {
                        display: "none",
                        height: 0,
                      },
                    },
                  }}
                  hideSignUp={true}
                  passwordSettings={{
                    passwordPolicyMinLength: 8,
                    passwordPolicyCharacters: ["REQUIRES_LOWERCASE", "REQUIRES_UPPERCASE", "REQUIRES_NUMBERS"],
                  }}
                >
                  {() => <App version={config.version} />}
                </Authenticator>
              )}
            />
          </Switch>
          <ToastContainer position="bottom-left" theme="colored" />
        </NiceModal.Provider>
      </I18nextProvider>
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById("root"),
)
