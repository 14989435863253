import Icon from "@common/display/Icon"
import React from "react"
import { ButtonGroup, ButtonToolbar, Table } from "reactstrap"
import Toggle from "react-toggle"
import i18n from "src/i18n"

import type { StatusData } from "./FunnelView.d"
import { TogglesState } from "./FunnelView"

const { t } = i18n

type FunnelViewTableProps = {
  statusMap: StatusData[]
  totalkWDC: number | string
  projectCount: number
  handleToggle: (t: keyof TogglesState) => void
  activeToggles: TogglesState
}

const FunnelViewSubTable = ({
  projects,
  status,
  kWDC,
  filteredProjects,
}: StatusData & {
  filteredProjects: StatusData["projects"]
  filteredProjectCount: number
}): JSX.Element => {
  const [showSubTables, setShowSubTables] = React.useState(false)
  const projectsToDisplay = projects.filter((project) => {
    if (filteredProjects.length === 0) {
      return true
    }

    return filteredProjects.some((filteredProject) => filteredProject.code === project.code)
  })
  return (
    <>
      {/* Table row for each status code */}
      <tr key={status}>
        <td className={projectsToDisplay.length ? "hoverable" : ""} onClick={() => setShowSubTables(!showSubTables)}>
          {status}
          {!!projectsToDisplay.length && <Icon icon={showSubTables ? "expand_less" : "expand_more"} />}
        </td>
        <td className="align-right">{projectsToDisplay.length}</td>
        <td className="align-right">{kWDC.toLocaleString()}</td>
      </tr>

      {/* Collapsible sub table that lists each project and its dcSize */}
      {showSubTables &&
        projectsToDisplay.map((project) => (
          <tr key={project.code}>
            <td className="sub-table-padding gray-400" colSpan={2}>
              {`${project.name} - ( ${project.code} ) - ${
                project.paused ? `${t("refinementValues.Paused")}` : project.cancelled ? `${t("refinementValues.Cancelled")}` : `${t("refinementValues.Active")}`
              }`}{" "}
            </td>
            <td className="align-right gray-400">{project.dcSize?.toLocaleString()}</td>
          </tr>
        ))}
    </>
  )
}

const FunnelViewTable = ({ statusMap, totalkWDC, projectCount, handleToggle, activeToggles }: FunnelViewTableProps): JSX.Element => {
  const filteredProjects = statusMap
    .flatMap((entry) => entry.projects)
    .filter((project) => {
      const isPaused = project.paused
      const isCancelled = project.cancelled

      return (activeToggles.active && !isPaused && !isCancelled) || (activeToggles.paused && isPaused) || (activeToggles.cancelled && isCancelled)
    })

  return (
    <div className="funnel-view__table">
      <Table bordered dark>
        <thead>
          <tr>
            <th style={{ width: "70%" }}>{t("table_headings.status")}</th>
            <th className="align-center" style={{ width: "15%" }}>
              {t("process.labels.project_count")}
            </th>
            <th className="align-center" style={{ width: "15%" }}>
              kWdc
            </th>
          </tr>
        </thead>
        <tbody>
          {statusMap.map((mapEntry, idx) => {
            return (
              <FunnelViewSubTable key={`subtable-${idx}`} filteredProjects={filteredProjects} {...mapEntry} projectCount={mapEntry.projects.length} filteredProjectCount={filteredProjects.length} />
            )
          })}

          {/* Table row for totals */}
          <tr>
            <td>{t("table_headings.total")}</td>
            <td className="align-right">{filteredProjects.length > 0 ? filteredProjects.length : projectCount}</td>
            <td className="align-right">{totalkWDC.toLocaleString()}</td>
          </tr>
        </tbody>
      </Table>
      <div className="content-view-controls">
        <ButtonToolbar>
          <ButtonGroup className="header-button-group">
            <Toggle checked={activeToggles.paused} onChange={() => handleToggle("paused")} />
            <span className="react-toggle-text">{t("process.labels.show_paused")}</span>
            <Toggle checked={activeToggles.cancelled} onChange={() => handleToggle("cancelled")} />
            <span className="react-toggle-text">{t("process.labels.show_cancelled")}</span>
            <Toggle checked={activeToggles.active} onChange={() => handleToggle("active")} />
            <span className="react-toggle-text">{t("process.labels.show_active")}</span>
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    </div>
  )
}

export default FunnelViewTable
