import React from "react"
import { TariffAutoComplete } from "@dashboard/data/tariffs/TariffAutoComplete"
import { t } from "i18next"
import Icon from "@common/display/Icon"
import moment from "moment"

export default function getProFormaUiSchema() {
  return {
    archived: {
      "ui:description": t("ui.archived"),
    },
    projectStartDate: {
      "ui:title": t("ui.cash_flow_start_date"),
      "ui:description": "project_start_date_desc",
      "warning:match": {
        contextKey: "project",
        property: "startDate",
      },
    },
    systemSize: {
      "warning:match": {
        contextKey: "project",
        property: "dcSize",
      },
    },
    taxes: {
      "ui:options": {
        label: false,
      },
    },
    cashFlows: {
      "ui:array": "cashFlowFilter",
      "ui:collapse": {
        className: "cash-flow-entry",
        collapsed: true,
        renderTitle: (props, idx) => {
          const entry = props.items[idx].children.props.formData
          if (entry.name) {
            return (
              <>
                <h5>{entry.name}</h5>
                <aside>{`(${entry.category} / ${entry.account})`}</aside>
              </>
            )
          } else {
            return null
          }
        },
        isNewItem(props, idx) {
          const entry = props.items[idx].children.props.formData
          const cashFlows = props.formContext.originalProForma?.cashFlows

          return !cashFlows || cashFlows.find((cashFlow) => entry.id === cashFlow.id) === undefined
        },
      },
      "ui:item-label": t("labels.cash_flow"),
      items: {
        "ui:options": {
          label: false,
        },
        category: {
          "ui:widget": "categoriesWidget",
        },
        account: {
          "ui:widget": "accountsWidget",
        },
        flags: {
          "ui:options": {
            label: false,
          },
        },
        payments: {
          "ui:item-label": t("labels.payment"),
          items: {
            "ui:order": ["paymentType", "account", "amortization", "costs", "payments", "*"],
            "ui:options": {
              label: false,
            },
            path: {
              "ui:widget": "pathSelectWidget",
            },
            subAccount: {
              "ui:widget": "subAccountsWidget",
            },
            interestCategory: {
              "ui:widget": "categoriesWidget",
            },
            interestAccount: {
              "ui:widget": "accountsWidget",
            },
            recurrence: {
              "ui:order": [
                "startDate",
                "term",
                "rateType",
                "startRate",
                "floorRate",
                "ceilingRate",
                "escalator",
                "rates",
                "*",
              ],
              startDate: {
                "warning:custom": {
                  textElementRenderer: (data, ctx) => {
                    const payment = ctx.getParent().getParent()
                    const projectPath = payment.path
                    const ctxProject = ctx.project
                    if (payment.paymentType !== "generation") return null

                    // If the payment doesn't have a path set, return.
                    //
                    // This can happen if the user is importing the pro forma and is intending to manually update it.
                    if (!projectPath) return

                    const [, projectCode, site, system] = projectPath.split("/")

                    if (ctxProject.code !== projectCode) return null

                    let startDate = ctxProject.productionStartDate
                    if (site) {
                      if (ctxProject.sites && ctxProject.sites[site]) {
                        startDate = ctxProject.sites[site].productionStartDate
                      } else {
                        return null
                      }
                    }
                    if (system) {
                      if (
                        ctxProject.sites &&
                        ctxProject.sites[site] &&
                        ctxProject.sites[site].systems &&
                        ctxProject.sites[site].systems[system]
                      ) {
                        startDate = ctxProject.sites[site]?.systems[system].startDate
                      } else {
                        return null
                      }
                    }

                    if (moment(startDate).isBefore(payment.recurrence.startDate)) return null
                    if (startDate === payment.recurrence.startDate) return null

                    return (
                      <>
                        <Icon icon="error" className="date-warning" />
                        <span>
                          {t("data.confirmation_msg.confirm_start_date", {
                            date: moment(startDate).format("DD MMM YYYY"),
                          })}
                        </span>
                      </>
                    )
                  },
                  contextKey: "proForma",
                },
              },
              rates: {
                items: {
                  "ui:options": {
                    label: false,
                  },
                },
                "ui:layout": {
                  fixed: true,
                  type: "grid",
                  width: 2,
                  labelPrefix: "Year ",
                },
              },
              billedAtStart: {
                "ui:options": {
                  label: false,
                  labelPlaceholder: true,
                },
              },
            },
            instructions: {
              "ui:widget": "textarea",
            },
            payments: {
              "ui:item-label": t("labels.payment"),
              "ui:layout": {
                type: "list",
                inline: true,
                buttonsClassName: "field-array-object-buttons",
              },
              items: {
                "ui:options": {
                  label: false,
                },
                amountDue: {
                  "ui:options": {
                    inline: true,
                  },
                },
                dueDate: {
                  "ui:options": {
                    inline: true,
                  },
                },
                description: {
                  "ui:options": {
                    inline: true,
                  },
                },
              },
            },
            provider: {
              "ui:description": t("ui.open_tariff"),
              "ui:link": "https://openei.org/apps/IURDB/",
            },
            tariff: {
              "ui:widget": (props) => {
                const parseValue = () => {
                  if (props.value && typeof props.value === "string") {
                    return props.value
                  } else {
                    return ""
                  }
                }

                return <TariffAutoComplete onSelect={(value) => props.onChange(value)} selected={parseValue()} />
              },
            },
            notes: {
              "ui:widget": "textarea",
            },
          },
        },
      },
    },
  }
}
