import { Auth } from "aws-amplify"
import axios, { AxiosRequestConfig } from "axios"

export async function get<T>(url: string): Promise<T> {
  const session = await Auth.currentSession()
  const fullUrl = process.env.REACT_APP_ECOGY_API_URL + url

  const response = await fetch(fullUrl, {
    headers: {
      // @ts-ignore
      Authorization: session.idToken.jwtToken,
    },
  })

  return (await response.json()) as T
}

export async function post<T, K>(url: string, body: T): Promise<K> {
  const session = await Auth.currentSession()
  const fullUrl = process.env.REACT_APP_ECOGY_API_URL + url

  const response = await fetch(process.env.REACT_APP_ECOGY_API_URL + url, {
    method: "post",
    headers: {
      // @ts-ignore
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })

  if (!response.ok) {
    const msg = (await response.json()).message
    throw new Error(msg)
  }

  return (await response.json()) as K
}

export async function del<T>(url: string): Promise<T> {
  const session = await Auth.currentSession()
  const fullUrl = process.env.REACT_APP_ECOGY_API_URL + url

  const response = await fetch(process.env.REACT_APP_ECOGY_API_URL + url, {
    method: "delete",
    headers: {
      // @ts-ignore
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
  })

  return (await response.json()) as T
}

export async function put<T, K>(url: string, body: T): Promise<K> {
  const session = await Auth.currentSession()
  const fullUrl = process.env.REACT_APP_ECOGY_API_URL + url

  const response = await fetch(process.env.REACT_APP_ECOGY_API_URL + url, {
    method: "put",
    headers: {
      // @ts-ignore
      Authorization: session.idToken.jwtToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })

  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }

  return (await response.json()) as K
}
