import React from "react"
import { Alert, Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"

import EcosuiteForm, {
  EcosuiteArrayFieldTemplate,
  EcosuiteFieldTemplate,
  FormError,
  EcosuiteObjectFieldTemplate,
} from "@common/form/EcosuiteForm"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import EcosuiteNumberField from "@common/form/EcosuiteNumberField"
import Schemas from "@common/Schemas"
import Logger from "@common/Logger"

import AssetInfo from "../AssetInfo"
import ProjectAdminService from "../ProjectAdminService"
import ProjectUtils from "@common/utils/ProjectUtils"

import { irradianceWidget } from "../ProjectWidgets"
import validateFormData from "@rjsf/core/lib/validate"
import getUiSchema from "./SystemUISchema"
import ChangeNoteModal from "@common/form/ChangeNoteModal"
import ProjectExportDialog from "@common/project/ProjectExportDialog"
import EconodeReportTable from "@dashboard/data/project/systems/EconodeReportTable"
import i18n from "src/i18n"
import { uniqBy } from "lodash"
import TimedMessage from "@common/display/TimedMessage"
import { Trans } from "react-i18next"
import hideSchemaFields from "@common/form/HideSchemaFields"

const { t } = i18n

class SystemDetails extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.submitFormRef = React.createRef()

    this.createSystem = this.createSystem.bind(this)
    this.editSystem = this.editSystem.bind(this)
    this.deleteSystem = this.deleteSystem.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
  }

  confirmDelete(e) {
    this.setState({ showModal: !this.state.showModal })

    e.preventDefault()
  }

  componentDidMount() {
    super.componentDidMount()
    Schemas.getSystemSchema().then((schema) => {
      let formData = this.getFormData(this.props.system, schema)
      this.setStateIfMounted({
        formData: formData,
        systemSchema: schema,
        validationErrorSchema: undefined,
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.system !== prevProps.system) {
      let formData = this.getFormData(this.props.system, this.state.systemSchema)
      this.setStateIfMounted({
        formData: formData,
        error: undefined,
        success: undefined,
        validationErrorSchema: undefined,
      })
    }
  }

  getFormData(system, schema) {
    if (system && schema) {
      return system
    }
  }

  createSystem(form) {
    const system = form.formData
    const validationResults = validateFormData(system, this.state.systemSchema)

    if (validationResults.errors?.length) {
      this.setStateIfMounted({ validationErrorSchema: validationResults.errorSchema })
    } else {
      this.setStateIfMounted({
        error: undefined,
        loading: true,
        success: false,
        formData: form.formData,
        expandAll: false,
      })
      ProjectAdminService.createSystem(this.props.project.code, this.props.site.code, system)
        .then((data) => {
          this.setStateIfMounted({
            validationErrorSchema: undefined,
            loading: false,
            formData: {},
          })
          this.props.systemAdded(data.system)
        })
        .catch((err) => {
          Logger.error(err)
          this.setStateIfMounted({ error: err, success: false, loading: false, expandAll: true })
        })
    }
  }

  editSystem(form) {
    const system = form.formData
    const validationResults = validateFormData(system, this.state.systemSchema)

    if (validationResults.errors?.length) {
      this.setStateIfMounted({ validationErrorSchema: validationResults.errorSchema })
    } else if (!this.state.note) {
      this.setStateIfMounted({ error: t("alertsInfo.alert_audit") })
    } else {
      this.setStateIfMounted({
        validationErrorSchema: undefined,
        error: undefined,
        loading: true,
        success: false,
        formData: form.formData,
        expandAll: false,
      })
      ProjectAdminService.editSystem(
        this.props.project.code,
        this.props.site.code,
        this.props.system.code,
        system,
        this.state.note,
      )
        .then((data) => {
          this.setStateIfMounted({ loading: false, success: true, note: "" })
          this.props.systemChanged(data.system)
        })
        .catch((err) => {
          Logger.error(err)
          this.setStateIfMounted({ error: err, success: false, loading: false, note: "", expandAll: true })
        })
    }
  }

  deleteSystem(e) {
    if (!this.state.note) {
      this.setStateIfMounted({ error: t("alertsInfo.alert_audit") })
      return
    }

    this.setStateIfMounted({ error: undefined, loading: true })
    ProjectAdminService.deleteSystem(
      this.props.project.code,
      this.props.site.code,
      this.props.system.code,
      this.state.note,
    )
      .then(() => {
        this.setStateIfMounted({ loading: false, showModal: false, deleted: true })
        this.props.systemDeleted(this.props.system)
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({
          error: err,
          success: false,
          loading: false,
          showModal: false,
          note: "",
        })
      })
    e.preventDefault()
  }

  validate(formData, errors) {
    if (formData.type === "consumption") {
      // Instead of using `renderedWeights`, this can definitely be done better by updating the schema in some creative way
      // Current Schema defs
      // {
      //   weightMonths: Boolean,   // if monthlyWeights are displayed
      //   weightDays: Boolean,     // if dailyWeights are displayed
      //   weightHours: Boolean,    // if hourlyWeights are displayed
      //   monthlyWeights: { ... },
      //   dailyWeights: { ... },
      //   hourlyWeights: { ... }
      // }
      const renderedWeights = {
        monthlyWeights: formData.forecast.weightMonths,
        dailyWeights: formData.forecast.weightDays,
        hourlyWeights: formData.forecast.weightHours,
      }

      // Filter out weights from formData and validate
      Object.keys(formData.forecast)
        .filter((key) => renderedWeights[key])
        .map((weight) => {
          // Calculate total to 2 decimal places of precision and then convert back to a Number
          const totalWeight = +Object.values(formData.forecast[weight])
            .reduce((total, curr) => {
              if (curr) {
                return total + curr
              } else {
                return total
              }
            }, 0)
            .toFixed(2)

          if (totalWeight !== 100) {
            errors.forecast[weight].addError(`Weights should add to 100% rather than: ${totalWeight}%`)
          }
        })
    }

    return errors
  }

  /**
   * @param {Object} system - system object from project heirarchy
   * @returns {Object} - { dcSizeWarning: Boolean, acSizeWarning: Boolean }
   *  returns an object with two Booleans that determine if the warnings need to be displayed
   */
  getSizeWarning(system) {
    let systemDCSize = system.dcSize,
      systemACSize = system.acSize
    system.devices?.forEach((source) => {
      if (source.type === "GEN") {
        systemDCSize -= source.dcSize
        systemACSize -= source.acSize
      }
    })
    return {
      dcSizeWarning: Math.round(systemDCSize) != 0,
      acSizeWarning: Math.round(systemACSize) != 0,
    }
  }

  /**
   * @param {(() => void) | undefined} callback - what to do after the state updates
   */
  toggleChangeNoteModal(callback) {
    this.setStateIfMounted({ isChangeNoteOpen: !this.state.isChangeNoteOpen }, callback)
  }

  toggleProjectExportDialog() {
    this.setStateIfMounted({ showExportDialog: !this.state.showExportDialog })
  }

  renderContent() {
    if (this.state.loading) {
      return <Loading />
    }
    if (this.state.deleted) {
      return <div>{t("messages.deleted")}</div>
    }

    if (this.state.systemSchema) {
      return (
        <div className="ecogy-form ecogy-form-card">
          <Row>
            <Col sm={9}>
              <EcosuiteForm
                idPrefix={`ecogy_site_${this.props.site ? this.props.site.code : ""}_system_${
                  this.props.system ? this.props.system.code : ""
                }`}
                schema={
                  this.state.formData?.type !== "consumption"
                    ? hideSchemaFields(["meterNumber", "contractedDemand"], this.state.systemSchema)
                    : this.state.systemSchema
                }
                uiSchema={getUiSchema(this.props)}
                FieldTemplate={EcosuiteFieldTemplate}
                ArrayFieldTemplate={EcosuiteArrayFieldTemplate}
                ObjectFieldTemplate={EcosuiteObjectFieldTemplate}
                disabled={this.props.readonly}
                formData={this.state.formData}
                onSubmit={this.props.system ? this.editSystem : this.createSystem}
                onChange={this.formDataChanged}
                transformErrors={(errors) => uniqBy(errors, (r) => r.stack)}
                onError={(e) => {
                  if (e[0]?.property) {
                    const propertyId = `root${e[0]?.property
                      .replaceAll("].", "_")
                      .replaceAll("[", "_")
                      .replaceAll(".", "_")}`
                    try {
                      Logger.debug("Focus on error property: " + propertyId)
                      document.getElementById(propertyId).focus()
                    } catch (e) {
                      Logger.error("Can't focus on error property: " + propertyId)
                    }
                  }
                }}
                fields={{ NumberField: EcosuiteNumberField }}
                widgets={{
                  irradianceWidget: irradianceWidget,
                }}
                formContext={{
                  expandAll: !!this.state.expandAll,
                  readonly: this.props.readonly,
                  startDate: this.props.project ? ProjectUtils.getProjectStartDate([this.props.project]) : undefined,
                  systemInfo: this.props.system
                    ? {
                        systemCode: this.props.system.code,
                        ...this.getSizeWarning(this.props.system),
                      }
                    : {},
                }}
                validate={this.validate}
                extraErrors={this.state.validationErrorSchema}
                showErrorList={false}
                noHtml5Validate // force ajv validator to handle html5 errors instead of the browser
              >
                <Row className="ecogy-form-buttons">
                  <Col className="message-section" sm="10">
                    {this.renderMessages()}
                  </Col>

                  {this.props.site ? (
                    <>
                      <Col className="button-section">
                        <Button
                          color="primary"
                          onClick={() => this.toggleChangeNoteModal()}
                          disabled={this.props.readonly}
                        >
                          {t("buttons.submit")}
                        </Button>
                        <Button color="danger" onClick={this.confirmDelete} disabled={this.props.readonly}>
                          {t("buttons.delete")}
                        </Button>
                        <Button color="primary" onClick={() => this.toggleProjectExportDialog()}>
                          {t("buttons.export")}
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <Col className="button-section" sm="12">
                      <Button color="primary" type="submit" disabled={this.props.readonly}>
                        {t("buttons.create")}
                      </Button>
                    </Col>
                  )}
                </Row>

                {/* react-jsonschema-form doesn't expose submit() or requestSubmit() inside of its ref */}
                {/* So a reference to this button is needed to submit from inside a modal / nested component */}
                <button ref={(ref) => (this.submitFormRef = ref)} style={{ display: "none" }} />
              </EcosuiteForm>
            </Col>
            <Col sm={3} className="project-side-panel">
              <div className="project-side-panel-content">
                <AssetInfo asset={this.props.system} predictedGeneration={this.props.predictedGeneration} />
              </div>
              {this.props.project &&
                this.props.site &&
                this.props.system &&
                this.state.formData?.type === "consumption" && (
                  <div className={"side-panel"}>
                    <EconodeReportTable
                      project={this.props.project}
                      site={this.props.site}
                      system={this.props.system}
                    />
                  </div>
                )}
            </Col>
          </Row>

          {/* Modals */}
          {this.props.system ? this.renderModal() : null}
          <ChangeNoteModal
            isOpen={this.state.isChangeNoteOpen}
            toggle={() => this.toggleChangeNoteModal()}
            submit={() => this.toggleChangeNoteModal(() => this.submitFormRef.click())}
            canSubmit={!!this.state.note}
          >
            <Col className="audit-section">
              <Alert color="info">{t("alertsInfo.alert_audit")}</Alert>
              <Input type="textarea" value={this.state.note} onChange={this.saveNote} className="audit-note" />
            </Col>
          </ChangeNoteModal>
          <ProjectExportDialog
            project={this.props.project}
            showExportDialog={this.state.showExportDialog}
            setShowExportDialog={() => {
              this.toggleProjectExportDialog()
            }}
          />
        </div>
      )
    } else {
      return <Loading />
    }
  }

  renderMessages() {
    return (
      <React.Fragment>
        {this.state.success ? (
          <TimedMessage delay={8000}>
            <Alert color="info">{t("alertsInfo.system_updated")}</Alert>
          </TimedMessage>
        ) : null}
        <FormError
          error={this.state.error}
          toggle={() => {
            this.setStateIfMounted({ error: null })
          }}
        />
      </React.Fragment>
    )
  }

  renderModal() {
    return (
      <Modal isOpen={this.state.showModal} toggle={this.confirmDelete}>
        <ModalHeader toggle={this.confirmDelete}>{`Delete System: ${this.props.system.code}?`}</ModalHeader>
        <ModalBody>
          <p>{t("data.confirmation_msg.confirm_delete_system")}</p>

          <div>
            <Trans i18nKey="data.system_details.to_delete_system"></Trans>
            <Alert color="info">{t("alertsInfo.alert_audit")}</Alert>
            <Input type="textarea" value={this.state.note} onChange={this.saveNote} className="audit-note" />
            <br />
            <span>
              {t("notes.to_delete", { name: "System" })}{" "}
              <b style={{ color: "red" }}>
                {t("data.system_details.delete_system", { systemName: this.props.system.name })}
              </b>
            </span>
            <Input
              value={this.state.deleteConfirmation}
              onChange={(e) => this.setState({ deleteConfirmation: e.target.value })}
              className="audit-note"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={this.deleteSystem}
            disabled={!this.state.note || this.state.deleteConfirmation !== `Delete System ${this.props.system.name}`}
          >
            {t("buttons.delete_system")}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              this.setStateIfMounted({ showModal: false })
            }}
          >
            {t("buttons.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default SystemDetails
