import { Auth } from "aws-amplify"
import aws from "aws-sdk"

import API from "@common/API"
import Logger from "@common/Logger"
import DocumentService from "../DocumentService"
import { getUserOrganizationId } from "@common/OrganizationUtils"
import { or } from "mathjs"

interface RecordServiceResponse {
  settings: unknown
}

interface PresignedResponse {
  fields: Record<string, string>
  url: string
  key: string
}

const RecordService = {
  listRecords(): Promise<unknown> {
    return API.get("/records")
  },

  async s3GetPresignedUrl(key: string) {
    const credentials = await Auth.currentCredentials()
    const s3 = new aws.S3({
      apiVersion: "2013-04-01",
      credentials: Auth.essentialCredentials(credentials),
    })

    const params = {
      Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET,
      Key: key,
    }

    return await s3.getSignedUrlPromise("getObject", params)
  },

  async createFormData(url: string, fileName: string) {
    // Get the file.
    const response = await fetch(url, {
      headers: {
        Accept: "application/pdf",
      }
    })

    // Create a file from the blob.
    const blob = await response.blob()
    const file = new File([blob], fileName, {
      type: "application/pdf"
    })

    // Create the form data.
    const formData = new FormData()
    formData.append("file", file)

    return formData
  },

  async distill(formData: FormData): Promise<{
    job: {
      id: string
    }
  }> {
    const session = await Auth.currentSession()
    const resp = await fetch(`${process.env.REACT_APP_ECOCENTRAL_API_URL}/sec/records/distill`, {
      method: "POST",
      body: formData,
      headers: {
        // @ts-ignore
        Authorization: `Bearer ${session.getAccessToken().getJwtToken()}`,
        "User-Agent": "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:128.0) Gecko/20100101 Firefox/128.0",
        Accept: "application/json, application/problem+json",
      },
    })
    return resp.json()
  },

  async getJob(jobId: string): Promise<{
    job: {
      id: string
      result: string
    }
  }> {
    const session = await Auth.currentSession()
    const resp = await fetch(`${process.env.REACT_APP_ECOCENTRAL_API_URL}/sec/jobs/${jobId}`, {
      method: "GET",
      headers: {
        // @ts-ignore
        Authorization: `Bearer ${session.getAccessToken().getJwtToken()}`,
        "User-Agent": "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:128.0) Gecko/20100101 Firefox/128.0",
        Accept: "application/json, application/problem+json",
      },
    })
    return resp.json()
  },

  async listDistilleryJobs(): Promise<any> {
    const session = await Auth.currentSession()
    const resp = await fetch(`${process.env.REACT_APP_ECOCENTRAL_API_URL}/sec/jobs`, {
      method: "GET",
      headers: {
        // @ts-ignore
        Authorization: `Bearer ${session.getAccessToken().getJwtToken()}`,
        "User-Agent": "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:128.0) Gecko/20100101 Firefox/128.0",
        Accept: "application/json, application/problem+json",
      },
    })
    return resp.json()
  },

  getProjectRecords(projectId: string): Promise<unknown> {
    return API.get(`/projects/${projectId}/records`)
  },

  getPortfolioRecords(portfolioId: string): Promise<unknown> {
    return API.get(`/portfolios/${portfolioId}/records`)
  },

  getDistilleryDocuments(project: string): Promise<unknown> {
    return API.get(`/projects/${project}/distillery-documents`)
  },

  getProjectDocuments(projectId: string): Promise<unknown> {
    return API.get(`/projects/${projectId}/record-documents`)
  },

  getPortfolioDocuments(portfolioId: string): Promise<unknown> {
    return API.get(`/portfolios/${portfolioId}/record-documents`)
  },

  getRecordDocuments(recordId: string): Promise<unknown> {
    return API.get(`/records/${recordId}/record-documents`)
  },

  queryRecords(query: string): Promise<unknown> {
    return API.post("/records/ocr/search", { query: query })
  },

  createRecord(project: { code: string }, site: { code: string }, system: { code: string }, form: { formData: unknown }): Promise<unknown> {
    return API.post(`${constructPath(project, site, system)}/records`, form.formData)
  },

  updateRecord(project: { code: string }, site: { code: string }, system: { code: string }, id: string, form: { formData: unknown }, note: string): Promise<unknown> {
    Logger.debug("Updating record: " + id)
    return API.put(`${constructPath(project, site, system)}/records/${id}?note=${encodeURIComponent(note)}`, form.formData)
  },

  deleteRecord(id: string, note: string): Promise<unknown> {
    return API.delete(`/records/${id}?note=${encodeURIComponent(note)}`)
  },

  getCategories(projectId: string): unknown {
    return API.getFromCache(`/finance/projects/${projectId}/categories`)
  },

  getRecordPayments(recordId: string): Promise<unknown> {
    Logger.debug("Getting payments for record: " + recordId)
    return API.get(`/finance/record/${recordId}/payments`)
  },

  getProjectRecordSettings(projectId: string) {
    return API.get<RecordServiceResponse>(`/projects/${projectId}/records/settings`)
      .then((data) => {
        return data.settings
      })
      .catch((e: unknown) => {
        Logger.error(e)
        return Promise.reject({ message: `Unable to retrieve settings for project: ${projectId}` })
      })
  },

  updateProjectRecordSettings(projectId: string, settings: unknown): Promise<unknown> {
    if (settings) {
      return API.put(`/projects/${projectId}/records/settings`, settings)
    } else {
      // Nothing to save
      Logger.info("Skipping save as no settings provided")
      return Promise.resolve()
    }
  },

  async storeFile(recordId: string, folderName: string, file: File): Promise<string> {
    // 1. Get a signed post request.
    const presigned = await API.post<PresignedResponse, unknown>("/records/document", {
      recordId: recordId,
      documentName: file.name,
      folderName: folderName,
      contentType: file.type,
    })

    // 2. Store the file with the post request.
    const formData = new FormData()

    // Push our data into our FormData object
    for (const [name, value] of Object.entries(presigned.fields)) {
      formData.append(name, value as string)
    }

    // Append the expected file.
    formData.append("file", file)

    await fetch(presigned.url, {
      method: "POST",
      body: formData,
    })

    return `${presigned.url}${presigned.key}`
  },

  async distilleryUpload(project: string, files: File[]): Promise<void> {
    const presigned = await API.post<PresignedResponse, unknown>(`/projects/${project}/records/distillery-upload`, {})

    for (const file of files) {
      const formData = new FormData()
      for (const [name, value] of Object.entries(presigned.fields)) {
        formData.append(name, value as string)
      }
      formData.append("file", file)

      await fetch(presigned.url, {
        method: "POST",
        body: formData,
      })
    }
  },

  async moveFile(originalPath: string, newPath: string, groups: string[]) {
    if (groups.includes("data-write")) {
      const organizationId = await getUserOrganizationId()

      return Auth.currentCredentials().then(async (credentials) => {
        const s3 = new aws.S3({
          apiVersion: "2013-04-01",
          credentials: Auth.essentialCredentials(credentials),
        })

        const params = {
          Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET as string,
          CopySource: `${process.env.REACT_APP_DOCUMENTS_BUCKET}/${originalPath}`,
          Key: `${organizationId}/${newPath}`,
        }

        return s3
          .copyObject(params)
          .promise()
          .then(() => {
            return DocumentService.deleteFile(originalPath, groups)
          })
      })
    }
  },

  async archiveFile(path: string, groups: string[]) {
    if (groups.includes("data-write")) {
      return Auth.currentCredentials().then(async (credentials) => {
        const s3 = new aws.S3({
          apiVersion: "2013-04-01",
          credentials: Auth.essentialCredentials(credentials),
        })

        // To archive a document we move it to the "Archive" sub directory
        const pathParts = path.split("/")
        pathParts[pathParts.length] = pathParts[pathParts.length - 1]
        pathParts[pathParts.length - 2] = "Archive"
        const archivePath = pathParts.join("/")

        const params = {
          Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET as string,
          CopySource: `${process.env.REACT_APP_DOCUMENTS_BUCKET}/${path}`,
          Key: archivePath,
        }

        return s3
          .copyObject(params)
          .promise()
          .then(() => {
            return DocumentService.deleteFile(path, groups)
          })
      })
    }
  },

  async unarchiveFile(path: string, groups: string[]) {
    if (groups.includes("data-write")) {
      return Auth.currentCredentials().then(async (credentials) => {
        const s3 = new aws.S3({
          apiVersion: "2013-04-01",
          credentials: Auth.essentialCredentials(credentials),
        })

        // To unarchive a document, we move it from the "Archive" sub directory
        const pathParts = path.split("/")
        pathParts.splice(pathParts.length - 2, 1)

        const archivePath = pathParts.join("/")

        const params = {
          Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET as string,
          CopySource: `${process.env.REACT_APP_DOCUMENTS_BUCKET}/${path}`,
          Key: archivePath,
        }

        return s3
          .copyObject(params)
          .promise()
          .then(() => {
            return DocumentService.deleteFile(path, groups)
          })
      })
    }
  },
}

const constructPath = (project: { code: string }, site?: { code: string }, system?: { code: string }) => {
  let path = "/projects/" + project.code
  if (site) {
    path += "/sites/" + site.code
  }
  if (system) {
    path += "/systems/" + system.code
  }

  return path
}

export default RecordService
